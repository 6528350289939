import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';
import Header from '../modules/common/containers/cart-header/outlet';
import HeaderArticoli from '../modules/common/containers/cart-header/articoli';
import HeaderBoscoNew from '../modules/common/containers/cart-header/bosco-new';

const getHeader = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return HeaderArticoli;
    case constants.BOSCO_NEW_SITE_NAME:
      return HeaderBoscoNew;
    case constants.OUTLET_SITE_NAME:
      return Header;
    default:
      break;
  }
  return HeaderBoscoNew;
};

export default getHeader;
