/* eslint-disable react/prop-types */
import React from 'react';
import { createUseStyles } from 'react-jss';

import styles from './styles';

const useStyles = createUseStyles(styles);

const ItemsGroup = ({ shouldSellerNameBeShown, title, children }) => {
  const classes = useStyles();

  return (
    <div className={shouldSellerNameBeShown && classes.group}>
      {shouldSellerNameBeShown && <div className={classes.label}>Продавец: {title}</div>}
      {children}
    </div>
  );
};

export default ItemsGroup;
