import { BoscoNewTotal } from '@internet-boutique-engineering/site-ui';
import Total from '../modules/checkout/components/total';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getTotal = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewTotal;
    default:
      break;
  }

  return Total;
};

export default getTotal;
