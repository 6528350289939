import { combineReducers } from 'redux';
import confiruration from '../core/configuration';
import user from '../core/user';
import cart from '../modules/cart';
import errors from '../core/errors';
import location from '../modules/location';
import checkout from '../modules/checkout';
import discounts from '../modules/discounts';
import promocode from '../modules/promocodes';
import modals from '../core/modals';
import common from '../modules/common';
import favorites from '../modules/favorites';

export default combineReducers({
  ...confiruration.reducers,
  ...modals.reducers,
  ...errors.reducers,
  ...user.reducers,
  ...cart.reducers,
  ...location.reducers,
  ...checkout.reducers,
  ...discounts.reducers,
  ...promocode.reducers,
  ...common.reducers,
  ...favorites.reducers,
});
