import { BoscoNewPaySystem, PaySystem } from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getPaySystem = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewPaySystem;
    default:
      break;
  }

  return PaySystem;
};

export default getPaySystem;
