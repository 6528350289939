import actionTypes from '../constants/action-types';

const statuses = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SET_SERVICE_READY_STATUS:
      return {
        ...state,
        serviceReady: action.payload,
      };
    default:
      return state;
  }
};

export default statuses;
