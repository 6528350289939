import discounts from '../../discounts';
import userSelectors from '../../../core/user/selectors';
import actions from '../actions';
import operations from '../operations';

const update = (store) => (next) => (action) => {
  const { type } = action;
  next(action);

  switch (type) {
    case discounts.constants.actionTypes.SET_CARDS:
    case discounts.constants.actionTypes.CLEAR_CARDS: {
      const state = store.getState();
      const { bxSmSaleId } = userSelectors.getUser(state);

      operations
        .fetchCart(bxSmSaleId)
        .then((data) => {
          store.dispatch(actions.setCart(data));
        })
        .catch(console.error);

      break;
    }
    default:
      break;
  }
};

export default update;
