import get from './get';
import post from './post';
import patch from './patch';
import remove from './remove';

export default {
  get,
  post,
  patch,
  delete: remove,
};
