import actionTypes from '../constants/action-types';

const defaultState = null;
const modals = (state = defaultState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SHOW_MODAL: {
      const { payload } = action;
      return payload;
    }
    case actionTypes.HIDE_MODAL: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default modals;
