import actionTypes from '../constants/action-types';

const setFormError = (errorTo, message, anchor = null) => ({
  type: actionTypes.SET_FORM_ERROR,
  payload: {
    errorTo,
    message,
    anchor,
  },
});

export default setFormError;
