import jsonp from '../../../../core/jsonp';
import getCity from './get-city';
import getStreet from './get-street';

const token = 'B6Q5KD2dRefYsQihyfZidZftd5GZ3zNT';
const url = 'https://kladr-api.com/api.php';

export default {
  getCity: getCity(jsonp, url, token),
  getStreet: getStreet(jsonp, url, token),
};
