const getStreet = (http, url, token) => (streetQuery, cityId, limit = 10) => {
  const epmtyStreetError = new Error('`streetQuery` should be an non empty string');
  const epmtyCityId = new Error('`cityId` should be set');
  if (!streetQuery) return Promise.reject(epmtyStreetError);
  if (!cityId) return Promise.reject(epmtyCityId);

  return http.get(url, {
    token,
    cityId,
    contentType: 'street',
    limit,
    query: streetQuery,
  });
};

export default getStreet;
