import actionTypes from '../constants/action-types';

const defaultState = {};
const error = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ERROR:
      return payload;
    case actionTypes.CLEAR_ERROR:
      return defaultState;
    default:
      return state;
  }
};

export default error;
