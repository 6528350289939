const paymentIframeStyles = ({
  extraLightColor,
  fontFamily,
  primaryTextColor,
  dropDownBorder,
  borderRadius,
  mobileMediaQuery,
}) => ({
  cover: {
    backgroundColor: extraLightColor,
    position: 'fixed',
    zIndex: 998,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.9,
  },
  modal: {
    backgroundColor: extraLightColor,
    fontFamily,
    position: 'fixed',
    zIndex: 999,
    top: '50%',
    left: '50%',
    color: primaryTextColor,
    borderColor: dropDownBorder,
    borderWidth: 10,
    borderStyle: 'solid',
    borderRadius,
    transform: 'translate(-50%, -50%)',
    padding: 0,
    width: '50%',
    height: '90vh',
    boxSizing: 'border-box',
    [`${mobileMediaQuery}`]: {
      minWidth: '100%',
    },
  },
  iframe: {
    padding: 0,
    margin: 0,
    border: 0,
    width: '100%',
    height: '100%',
  },
});

export default paymentIframeStyles;
