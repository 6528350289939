const add = (product) => ({
  ecommerce: {
    currencyCode: 'RUB',
    add: {
      products: [product.gtm],
    },
  },
});

export default add;
