const getCity = (http, url, token) => (cityQuery, limit = 10) => {
  const error = new Error('`cityQuery` should be an non empty string');
  if (!cityQuery) return Promise.reject(error);

  return http.get(url, {
    token,
    contentType: 'city',
    limit,
    query: cityQuery,
  });
};

export default getCity;
