import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { useLocation, Link } from 'react-router-dom';
import { BurgerButton } from '@internet-boutique-engineering/site-ui';
import classnames from 'classnames';
import styles from './styles';
import MobileMenu from '../../../components/mobile-menu';
import Logo from '../../../components/logo';
import getCallCenterPhone from '../../../../../core/configuration/selectors/get-call-center-phone';
import segment, { eventTypes } from '../../../../analytic/segment';
import HelpMenu from '../../help-menu';
import experiments from '../../../../experiments';
import getHeaderMenu from '../../../../../themes/get-header-menu';
import getHeaderPhoneWithIcon from '../../../../../themes/get-header-phone-with-icon';
import locationIcon from './images/location-ico.svg';
import percentIcon from './images/percent-icon-o.svg';
import userIcon from './images/user.svg';
import favoriteIcon from './images/favorite.svg';
import getFavorites from '../../../../favorites/selectors/get-favorites';

const useStyles = createUseStyles(styles);

const CartHeader = () => {
  const classes = useStyles();
  const phone = useSelector(getCallCenterPhone);
  const favorites = useSelector(getFavorites);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const burgerClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    if (isMenuOpen) {
      segment.track(eventTypes.BURGER_MENU_CLOSE);
    } else {
      segment.track(eventTypes.BURGER_MENU_OPEN);
    }
  }, [isMenuOpen]);

  const HeaderMenu = getHeaderMenu();

  const mobileMenuClassNames = classnames(classes.mobileMenu, {
    [`${classes.mobileMenuOpen}`]: isMenuOpen,
  });
  const { pathname } = useLocation();

  const HeaderPhoneWithIcon = getHeaderPhoneWithIcon();
  return (
    <React.Fragment>
      <div className={mobileMenuClassNames}>
        <BurgerButton
          isOpen={isMenuOpen}
          onClick={burgerClick}
          className={classes.burgerButton}
        />
        <MobileMenu />
      </div>
      <Logo className={classes.logo} href="/" />
      <div className={classes.topLine}>
        <HeaderPhoneWithIcon phone={phone} />
        <span className={classes.separator}>{'//'}</span>
        <HelpMenu />
      </div>
      <div className={classes.subLine}>
        <a className={classes.shopAdresses} href="/shops/">
          <img src={locationIcon} />
          <span>Адреса магазинов</span>
        </a>
        <span className={classes.subLineSeparator}></span>
        <a className={classes.clientDays} href="/news/">
          <img src={percentIcon} />
          <span>Клиентские дни!</span>
        </a>
      </div>
      <div className={classes.headerMenuIcons}>
        <a className={classes.headerMenuIcon} href="/personal/profile/">
          <img src={userIcon} alt="Profile" />
        </a>
        <a className={classes.headerMenuIcon} href="/personal/favorites/">
          <img src={favoriteIcon} alt="Favorites" />
          <span>{favorites.length}</span>
        </a>
      </div>
      <HeaderMenu
        className={classes.menu}
        experiments={experiments}
        pathname={pathname}
        Link={Link}
      />
    </React.Fragment>
  );
};

export default CartHeader;
