const rowStyles = ({
  fontFamily,
  baseTextSize,
  lowEmphasisTextSize,
  mobileMenuTextColor,
  mobileMenuSeparatorColor,
  mobileMenuIconColor,
}) => ({
  row: {
    fontFamily,
    fontSize: ({ isTopLevel }) => (isTopLevel ? baseTextSize : lowEmphasisTextSize),
    color: mobileMenuTextColor,
    paddingTop: ({ isTopLevel }) => (isTopLevel ? '4.21%' : '3%'),
    paddingBottom: ({ isTopLevel }) => (isTopLevel ? '4.21%' : '3%'),
    paddingLeft: '6.2%',
    paddingRight: '6.2%',
    borderTopWidth: ({ isTopLevel }) => (isTopLevel ? 1 : 0),
    borderTopColor: mobileMenuSeparatorColor,
    borderTopStyle: 'solid',
    lineHeight: 1.1,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 18,
    height: 18,
    display: 'block',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 1px)',
      content: '""',
      width: 1,
      height: '100%',
      borderLeftWidth: 2,
      borderLeftColor: mobileMenuIconColor,
      borderLeftStyle: 'solid',
    },
    '&:after': {
      position: 'absolute',
      top: 'calc(50% - 1px)',
      left: 0,
      content: '""',
      width: '100%',
      height: 1,
      borderTopWidth: 2,
      borderTopColor: mobileMenuIconColor,
      borderTopStyle: 'solid',
    },
  },
  iconOpen: {
    '&:before': {
      content: 'none',
    },
  },
});

export default rowStyles;
