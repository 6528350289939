import sha256 from 'sha256';
import criteo from '../../../core/criteo';
import storeInstance from '../../../store/connected-store';
import getCriteoId from '../../../core/configuration/selectors/get-criteo-id';
import getDeviceType, { MOBILE, TABLET } from '../../../core/helpers/get-device-type';

const convertDeviceTypeToCriteo = (type) => {
  switch (type) {
    case MOBILE:
      return 'm';
    case TABLET:
      return 't';
    default:
      return 'd';
  }
};

export default {
  identify: (email) => {
    const store = storeInstance.get();
    const state = store.getState();
    const id = getCriteoId(state);
    const criteoQ = criteo.get();
    const deviceType = convertDeviceTypeToCriteo(getDeviceType());

    if (!id || !criteoQ) return;

    const data = [
      { event: 'setAccount', account: id },
      { event: 'setSiteType', type: deviceType },
    ];

    if (email) {
      data.push({ event: 'setEmail', email: sha256(email), hash_method: 'sha256' });
    }

    criteoQ.push(...data);
  },
  trackCart: (item) => {
    const criteoQ = criteo.get();

    if (!criteoQ) return;

    criteoQ.push({ event: 'viewBasket', item });
  },
};
