import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);
const Header = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerGrid}>
      <div className={classes.header}>
        <div className={classes.headerWrapper}>{children}</div>
      </div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Header;
