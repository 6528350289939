const CALLBACK_NAME = '__cladrCallbacl';

const get = (url, options = {}) => {
  const queryParameters = {
    ...options,
    callback: CALLBACK_NAME,
  };
  const queryKeys = Object.keys(queryParameters);
  const queryString = queryKeys.map((key) => `${key}=${queryParameters[key]}`).join('&');
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `${url}?${queryString}`;
    script.onerror = reject;
    window[CALLBACK_NAME] = (data) => {
      resolve({
        json: () => Promise.resolve(data),
        text: () => Promise.resolve(data),
        html: () => Promise.resolve(data),
      });
      script.remove();
      delete window[CALLBACK_NAME];
    };
    document.body.appendChild(script);
  });
};

export default get;
