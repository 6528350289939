import serviceClient from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';

const getPaysystems = (deliveryId) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.checkout.getPaysystems({ delivery: deliveryId }).then(
    (response) =>
      new Promise((resolve) => {
        response.json().then(({ data }) => resolve(structureToCamel(data)));
      })
  );
};

export default getPaysystems;
