import React from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from '../../components/progress-bar';
import selectors from '../../selectors';
import appStatuses from '../../constants/app-statuses';

const ProgressContainer = () => {
  const appStatus = useSelector(selectors.getAppStatus);
  if (appStatus === appStatuses.IDLE) return null;

  return <ProgressBar />;
};

export default ProgressContainer;
