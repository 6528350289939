import fetch from 'cross-fetch';
import retryMyFetch from 'retry-my-fetch';
import connectedStore from '../../store/connected-store';
import user from '../user';
import authClient from '../auth-client';
import AuthentifitationError from '../errors/authntification';
import NetworkError from '../errors/network';
import errorTypes from '../errors/constants/error-types';
import structureToCamel from '../helpers/structure-to-camel';

const get = (path, query = {}, headers = {}) => {
  if (!path) Promise.reject(new Error("'path' should not be NULL or undefined"));
  const queryKeys = Object.keys(query);
  let pathToFetch;
  if (!queryKeys.length) {
    pathToFetch = path;
  } else {
    pathToFetch = `${path}?${queryKeys.map((key) => `${key}=${query[key]}`).join('&')}`;
  }

  const fetchWithRetry = retryMyFetch(fetch, {
    useAbortController: true,
    beforeRefetch: (_, fetchOptions, statusCode, counter, isRejected) => {
      if (isRejected) {
        const store = connectedStore.get();
        const state = store.getState();
        const { headers: originalHeaders } = fetchOptions;
        const { accessToken } = user.selectors.getToken(state);
        return Promise.resolve({
          ...fetchOptions,
          headers: {
            ...originalHeaders,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
      if (statusCode !== 401 && statusCode !== 403) {
        return Promise.reject();
      }
      const store = connectedStore.get();
      const state = store.getState();
      const { refreshToken: token } = user.selectors.getToken(state);
      const authClientInstance = authClient.get();

      return new Promise((resolve, reject) => {
        authClientInstance
          .refreshToken(token)
          .then((data) => {
            const { status, url, statusText, headers: requestedHeaders } = data;
            switch (status) {
              case 401:
              case 403:
                throw new AuthentifitationError();
              case 200:
                return data.json();
              default:
                throw new NetworkError(status, url, statusText, requestedHeaders);
            }
          })
          .then((response) => {
            const { data } = response;
            const { accessToken, refreshToken, iat } = structureToCamel(data);
            store.dispatch(user.actions.setAuthToken(accessToken, refreshToken, iat));
            const { headers: originalHeaders } = fetchOptions;

            resolve({
              ...fetchOptions,
              headers: {
                ...originalHeaders,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          })
          .catch((error) => {
            if (error.name === errorTypes.AUTHENTIFICATION_ERROR) {
              // window.location.href = loginPageUrl;
              reject();
              return;
            }
            throw error;
          });
      });
    },
  });
  return fetchWithRetry(pathToFetch, {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    headers,
  });
};

export default get;
