import serviceClient from '../../../core/service-client';

const getCities = (country, name) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.location
    .getCities({
      country,
      name,
    })
    .then((response) => response.json().then(({ data }) => data));
};

export default getCities;
