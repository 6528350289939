import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import getUserInfo from '../../../themes/get-user-info';
import user from '../../../core/user';
import validateUser from '../helpers/validate-user';
import segment, { eventTypes } from '../../analytic/segment';

const UserContainer = () => {
  const userInfo = useSelector(user.selectors.getUser);
  const {
    id: userId,
    email: initialEmail,
    name: initialName,
    lastname: initialLastname,
    phone: initialPhone,
  } = userInfo;
  const dispatch = useDispatch();
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(initialPhone);
  const [name, setName] = useState(initialName);
  const [lastname, setLastname] = useState(initialLastname);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (userId) {
      setEmail(initialEmail);
      setPhone(initialPhone);
      setName(initialName);
      setLastname(initialLastname);
    }
  }, [userId]);

  const onNameChanged = useCallback((value) => {
    setName(value);
  });
  const onLastnameChanged = useCallback((value) => {
    setLastname(value);
  });
  const onEmailChanged = useCallback((value) => {
    setEmail(value);
  });
  const onPhoneChanged = useCallback((value) => {
    setPhone(value);
  });

  const onCheckboxUpdated = useCallback(({ checked }) => {
    setIsChecked(checked);
  });

  const onSubmit = useCallback(() => {
    segment.track(eventTypes.PERSONAL_DATA_SAVE_CLICK, {
      email,
      name,
      lastname,
      phone,
      isChecked,
    });

    const formErrors = validateUser(email, name, lastname, phone, isChecked);

    setErrors(formErrors);

    if (formErrors) {
      return;
    }
    dispatch(
      user.actions.initiateUpdate(userId, {
        email: email || initialEmail,
        name: name || initialName,
        lastname: lastname || initialLastname,
        phone: phone || initialPhone,
      })
    );
    history.push('/cart/checkout');
  }, [
    userId,
    email,
    phone,
    name,
    lastname,
    initialEmail,
    initialPhone,
    initialName,
    initialLastname,
    isChecked,
  ]);

  const UserInfo = getUserInfo();
  return (
    <UserInfo
      user={userInfo}
      onNameChanged={onNameChanged}
      onLastnameChanged={onLastnameChanged}
      onEmailChanged={onEmailChanged}
      onPhoneChanged={onPhoneChanged}
      onCheckboxUpdated={onCheckboxUpdated}
      onSubmit={onSubmit}
      isChecked={isChecked}
      errors={errors}
    />
  );
};

export default UserContainer;
