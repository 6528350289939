import fetch from 'cross-fetch';

const patch = (path, query = {}, headers = {}) => {
  if (!path) Promise.reject(new Error("'path' should not be NULL or undefined"));
  return fetch(path, {
    method: 'PATCH',
    mode: 'cors',
    headers,
    body: JSON.stringify(query),
  });
};

export default patch;
