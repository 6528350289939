import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  Button,
} from '@internet-boutique-engineering/site-ui';
import styles from './styles';
import Row from './row';
import getMenu from './get-menu';

const useStyles = createUseStyles(styles);

const MobileMenu = () => {
  const classes = useStyles();
  return (
    <div className={classes.menu}>
      <div className={classes.buttonPlace}>
        <Button className={classes.button} href="/personal/profile">
          Ваш кабинет
        </Button>
        <Button className={classes.buttonLogout} href="/catalog/woman/?logout=yes">
          Выход
        </Button>
      </div>
      {getMenu().map(({ label, subMenu, url }) => (
        <React.Fragment key={label}>
          {subMenu && (
            <Dropdown>
              <DropdownTrigger>
                <Row hasSubRow>{label}</Row>
              </DropdownTrigger>
              <DropdownContent>
                <div className={classes.sub}>
                  {subMenu.map(({ label: subLabel, url: subUrl }) => (
                    <Row key={subLabel} level={2}>
                      <a className={classes.link} href={subUrl}>
                        {subLabel}
                      </a>
                    </Row>
                  ))}
                </div>
              </DropdownContent>
            </Dropdown>
          )}
          {!subMenu && (
            <Row>
              <a className={classes.link} href={url}>
                {label}
              </a>
            </Row>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MobileMenu;
