export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

const getDeviceType = () => {
  if (/iPad/.test(navigator.userAgent)) {
    return TABLET;
  }

  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)) {
    return MOBILE;
  }

  return DESKTOP;
};

export default getDeviceType;
