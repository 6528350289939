import React from 'react';
import PropTypes from 'prop-types';
import getLogo from '../../../../themes/get-logo';

const Logo = ({ className }) => {
  const LogoComponent = getLogo();

  return <LogoComponent className={className} href="/" />;
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
