const cartHeader = ({
  fontFamily,
  primaryTextColor,
  lowEmphasisTextSize,
  activeTextColor,
  transitionDuration,
  transitionTimingFunction,
  boldWeight,
  tabletMediaQuery,
  extraLowEmphasisTextSize,
  bonusColor,
  mobileMediaQuery,
  cartHeaderHeightMobile,
  logoLeft,
  logoBottom,
  logoBottomTablet,
  logoBottomMobile,
  logoWidth,
  logoWidthTablet,
  logoWidthMobile,
  logoMaxWidthMobile,
  secondaryColor,
  cartNavPadding,
  cartNavPaddingTablet,
  cartNavPaddingMobile,
  topLinePadding,
  topLinePaddingTablet,
  topLinePaddingMobile,
  cartHeaderBorderMobile,
  cartHeaderBorderRadius,
}) => ({
  menu: {
    margin: cartNavPadding,
    borderRadius: [0, 0, cartHeaderBorderRadius, cartHeaderBorderRadius],
    [`${tabletMediaQuery}`]: {
      padding: cartNavPaddingTablet,
    },
    [`${mobileMediaQuery}`]: {
      display: 'none',
      padding: cartNavPaddingMobile,
    },
  },
  topLine: {
    display: 'flex',
    padding: topLinePadding,
    fontSize: lowEmphasisTextSize,
    [`${tabletMediaQuery}`]: {
      fontSize: extraLowEmphasisTextSize,
      padding: topLinePaddingTablet,
    },
    [`${mobileMediaQuery}`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: topLinePaddingMobile,
    },
  },
  logo: {
    position: 'absolute',
    bottom: logoBottom,
    left: logoLeft,
    width: logoWidth,
    '&[can-be-filled]': {
      backgroundColor: secondaryColor,
    },
    [`${tabletMediaQuery}`]: {
      width: logoWidthTablet,
      bottom: logoBottomTablet,
    },
    [`${mobileMediaQuery}`]: {
      width: logoWidthMobile,
      maxWidth: logoMaxWidthMobile,
      // left: '58%',
      left: '50%',
      bottom: logoBottomMobile,
      transform: 'translateX(-50%)',
    },
  },
  phone: {
    color: primaryTextColor,
    fontSize: lowEmphasisTextSize,
    textDecoration: 'none',
    transitionDuration,
    transitionTimingFunction,
    transitionProperty: 'color',
    fontWeight: boldWeight,
    '&:hover': {
      color: activeTextColor,
    },
    [`${tabletMediaQuery}`]: {
      fontSize: extraLowEmphasisTextSize,
    },
  },
  phoneText: {
    [`${mobileMediaQuery}`]: {
      display: 'none',
    },
  },
  phoneIcon: {
    display: 'none',
    '& svg': {
      stroke: bonusColor,
      fill: bonusColor,
    },
    [`${mobileMediaQuery}`]: {
      width: 19,
      display: 'block',
    },
  },
  help: {
    '& button': {
      fontFamily,
      backgroundColor: 'transparent',
      fontSize: lowEmphasisTextSize,
      padding: 0,
      margin: 0,
      color: primaryTextColor,
      borderWidth: 0,
      cursor: 'pointer',
      fontWeight: boldWeight,
      transitionDuration: 300,
      transitionTimingFunction: 'ease-out',
      transitionProperty: 'color',
      '&:hover': {
        color: activeTextColor,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    [`${mobileMediaQuery}`]: {
      display: 'none',
    },
  },
  separator: {
    padding: [0, 3],
    [`${mobileMediaQuery}`]: {
      display: 'none',
    },
  },
  burgerButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: `calc(${cartHeaderHeightMobile}px - ${cartHeaderBorderMobile}px)`,
    width: `calc(${cartHeaderHeightMobile}px - ${cartHeaderBorderMobile}px)`,
    pointerEvents: 'all',
  },
  mobileMenu: {
    display: 'none',
    width: '100%',
    height: '100%',
    position: 'fixed',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    zIndex: 10,
    transitionDuration,
    transitionTimingFunction,
    [`${mobileMediaQuery}`]: {
      display: 'block',
      transform: 'translateX(0)',
    },
  },
  mobileMenuOpen: {
    transform: `translateX(calc(100vw - ${
      cartHeaderHeightMobile - cartHeaderBorderMobile
    }px))`,
  },
});

export default cartHeader;
