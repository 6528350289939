import serviceClient from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';
import structureToSnake from '../../../core/helpers/structure-to-snake';

const calculate = (dataSet) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.checkout
    .calculate(structureToSnake(dataSet))
    .then((response) => response.json())
    .then(({ data }) => structureToCamel(data));
};

export default calculate;
