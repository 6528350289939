const getClientId = () => {
  try {
    const { ga } = window;
    const clients = ga.getAll();
    const [client] = clients;
    return client.get('clientId');
  } catch (error) {
    return null;
  }
};

export default getClientId;
