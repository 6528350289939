import clear from './clear';
import calculate from './calculate';
import checkout from './checkout';
import analytic from './analytic';

export default {
  clear,
  calculate,
  checkout,
  analytic,
};
