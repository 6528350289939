export default {
  SET_BATCH_LOCATION: '@CHECKOUT/SET_BATCH_LOCATION',
  SET_COUNTRY: '@CHECKOUT/SET_COUNTRY',
  SET_CITY: '@CHECKOUT/SET_CITY',
  SET_METRO: '@CHECKOUT/SET_METRO',
  SET_DISTRICT: '@CHECKOUT/SET_DISTRICT',
  SET_STREET: '@CHECKOUT/SET_STREET',
  SET_BUILDING: '@CHECKOUT/SET_BUILDING',
  SET_BLOCK: '@CHECKOUT/SET_BLOCK',
  SET_APARTMENT: '@CHECKOUT/SET_APARTMENT',
  SET_DELIVERY: '@CHECKOUT/SET_DELIVERY',
  SET_DELIVERY_COST: '@CHECKOUT/SET_DELIVERY_COST',
  SET_PAYSYSTEM: '@CHECKOUT/SET_PAYSYSTEM',
  CLEAR_PAYSYSTEM: '@CHECKOUT/CLEAR_PAYSYSTEM',
  SET_AMOUNT: '@CHECKOUT/SET_AMOUNT',
  CLEAR_AMOUNT: '@CHECKOUT/CLEAR_AMOUNT',
  SET_DELIVERY_DETAILS: '@CHECKOUT/SET_DELIVERY_DETAILS',
  SET_USE_BONUSES: '@CHECKOUT/SET_USE_BONUSES',
  SET_SUBSCRIBE: '@CHECKOUT/SET_SUBSCRIBE',
  SET_SAVED_LOCATIONS: '@CHECKOUT/SET_SAVED_LOCATIONS',
  SET_SELECTED_SAVED_LOCATION: '@CHECKOUT/SET_SELECTED_SAVED_LOCATION',
  CLEAR_DELIVERY_DETAILS: '@CHECKOUT/CLEAR_DELIVERY_DETAILS',
  SET_FORM_ERROR: '@CHECKOUT/SET_FORM_ERROR',
  CLEAR_FORM_ERRORS: '@CHECKOUT/CLEAR_FORM_ERRORS',
  DO_CHECKOUT: '@CHECKOUT/DO_CHECKOUT',
  CLEAR: '@CHECKOUT/CLEAR',
  UPDATE_PAYSYSTEM_PAYLOAD: '@CHECKOUT/UPDATE_PAYSYSTEM_PAYLOAD',
  SET_GIFT_CARDS: '@CHECKOUT/SET_GIFT_CARDS',
  SELLERS_INFO_SHOW: '@CHECKOUT/SELLERS_INFO_SHOW',
  SELLERS_INFO_HIDE: '@CHECKOUT/SELLERS_INFO_HIDE',
  SET_PAYSYSTEMS_LIST: '@CHECKOUT/SET_PAYSYSTEMS_LIST',
};
