import checkout from '../../checkout';

const fillLocation = (store) => (next) => (action) => {
  const { type } = action;

  switch (type) {
    case checkout.constants.actionTypes.SET_SELECTED_SAVED_LOCATION: {
      const {
        payload: { country, city, district, apartment, block, building, street, metro },
      } = action;

      store.dispatch(
        checkout.actions.setBatchLocation({
          country,
          city,
          district,
          metro,
          street,
          block,
          building,
          apartment,
        })
      );
      next(action);
      break;
    }
    default: {
      next(action);
      break;
    }
  }
};

export default fillLocation;
