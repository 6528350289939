import actionTypes from '../constants/action-types';
import selectors from '../selectors';
import discounts from '../../discounts';
import promocodes from '../../promocodes';
import common from '../../common';
import cart from '../../cart';
import helpers from '../helpers';
import operations from '../operations';
import actions from '../actions';
import errorTypes from '../../../core/errors/constants/error-types';
import errorsActions from '../../../core/errors/actions';
import formErrorTypes from '../constants/form-error-types';
import formErrorAnchors from '../constants/form-error-anchors';
import modals from '../../../core/modals';
import configuration from '../../../core/configuration';
import user from '../../../core/user';
import segment, { eventTypes } from '../../analytic/segment';
import CustomError from '../../../core/errors/custom-error';
import compileSuccessUrl from '../helpers/compile-success-url';
import ga from '../../analytic/ga';
import getDeviceType from '../../../core/browser/helpers/get-device-type';
import analytic from '../../analytic';

function cleanUp(store) {
  store.dispatch(actions.clear());
  store.dispatch(actions.setAmount({}));
  store.dispatch(cart.actions.clearCart());
  store.dispatch(promocodes.actions.clearPromocode());
}

const checkout = (store) => (next) => (action) => {
  const { type, payload } = action;
  const state = store.getState();
  switch (type) {
    case actionTypes.SET_FORM_ERROR: {
      if (!payload) break;
      const { anchor } = payload;
      if (!anchor) break;
      const node = document.querySelector(`[${anchor}]`);
      if (!node) break;

      node.scrollIntoView({ behavior: 'smooth' });
      break;
    }
    case actionTypes.DO_CHECKOUT: {
      const cartItems = cart.selectors.getCart(state);
      // const { finalCartPrice } = selectors.getAmount(state);
      const [myTargetItems, myTargetPrice] = analytic.handlers.myTargetCart(cartItems);
      store.dispatch(actions.clearFormErrors());
      const orderSuccessUrl = configuration.selectors.getOrderSuccessUrl(state);
      const paymentUrl = configuration.selectors.getPaymentUrl(state);

      const { code } = promocodes.selectors.getPromocode(state);
      const selectedDiscountCards = discounts.selectors.getDiscountCards(state);
      const [card] = selectedDiscountCards;
      const selectedCountry = selectors.getSelectedCountry(state);
      const selectedStreet = selectors.getSelectedStreet(state);
      const selectedBuilding = selectors.getSelectedBuilding(state);
      const selectedBlock = selectors.getSelectedBlock(state);
      const selectedApartment = selectors.getSelectedApartment(state);
      const doesUserWanthToSubscribe = selectors.getWantToSubscribeStatus(state);
      const bonuses = selectors.getUseBonuses(state);
      const paysystemsList = selectors.getPaySystemsList(state);

      const locationId = selectors.getSelectedLocationId(state);
      const { id: deliveryId } = selectors.getSelectedDelivery(state) || {};
      const delivery = selectors.getSelectedDelivery(state);
      const deliveryPayload = selectors.getSelectedDeliveryDetails(state) || {};
      const paySystemPayload = selectors.getPaySystemPayload(state) || {};
      const paySystem = selectors.getPaySystemId(state);
      const giftCards = selectors.getGiftCards(state);
      const promocode = code;
      const discountCard = card ? card.number : null;
      const { variationId } = deliveryPayload;

      const locationError = helpers.validateLocation(
        deliveryId,
        selectedCountry,
        locationId,
        selectedStreet,
        selectedBuilding,
        selectedApartment
      );
      if (locationError) {
        store.dispatch(
          actions.setFormError(
            formErrorTypes.LOCATION_ERROR,
            locationError,
            formErrorAnchors.location
          )
        );
        break;
      }

      const deliveryError = helpers.validateDelivery(delivery, deliveryPayload);
      if (deliveryError) {
        store.dispatch(
          actions.setFormError(
            formErrorTypes.DELIVERY_ERROR,
            deliveryError,
            formErrorAnchors.delivery
          )
        );
        break;
      }

      if (paysystemsList && paysystemsList.length > 0) {
        const paysystemError = helpers.validatePaySystem(paySystem);

        if (paysystemError) {
          store.dispatch(
            actions.setFormError(
              formErrorTypes.PAYSYSTEM_ERROR,
              paysystemError,
              formErrorAnchors.paysystem
            )
          );
          break;
        }
      }

      const sellers = cartItems.reduce((sellersArr = [], item) => {
        if (!sellersArr.includes(item.seller)) {
          sellersArr.push(item.seller);
        }
        return sellersArr;
      }, []);

      if (sellers.length > 1) {
        store.dispatch(actions.setSellersInfoShow());
        setTimeout(() => {
          store.dispatch(actions.setSellersInfoHide());
          store.dispatch(common.actions.setAppStatusBusy());
        }, 3000);
      } else {
        store.dispatch(common.actions.setAppStatusBusy());
      }
      const { utm } = user.selectors.getUser(state);
      const deviceType = getDeviceType();
      const gaClientId = ga.accessors.getClientId();

      const checkoutData = {
        country: selectedCountry,
        locationId,
        street: selectedStreet,
        building: selectedBuilding,
        block: selectedBlock,
        apartment: selectedApartment,
        delivery: variationId || deliveryId,
        deliveryPayload,
        paySystem,
        paySystemPayload,
        promocode,
        discountCard,
        subscribe: doesUserWanthToSubscribe,
        bonuses,
        utm,
        deviceType,
        gaId: gaClientId,
        giftCards,
      };
      segment.track(eventTypes.CHECKOUT_REQUEST, {
        ...checkoutData,
        promocode: 'xxx xxx',
        discountCard: 'xxx xxx',
      });

      operations
        .checkout(checkoutData)
        .then((response) => {
          const { orders } = response;
          const [order] = orders;
          if (!Array.isArray(orders) || !order) {
            throw new CustomError('Не удалось оформить заказ');
          }

          const { info, orderId, isPaymentAvailable } = order;
          const orderIds = orders.map(({ orderId: id }) => id);
          store.dispatch(common.actions.setAppStatusIdle());

          analytic.myTarget.trackOrder(myTargetItems, myTargetPrice);
          // analytic.facebookPixel.trackOrder(finalCartPrice);

          segment
            .track(
              eventTypes.CHECKOUT_SUCCESS,
              {
                orderId,
                isPaymentAvailable,
              },
              {
                send_immediately: true,
              }
            )
            .then(() => {
              if (isPaymentAvailable) {
                modals.operations.showModal(modals.constants.modalTypes.PAYMENT_IFRAME, {
                  url: paymentUrl.replace('#__ORDER_ID__#', orderId),
                });
                setTimeout(() => {
                  cleanUp(store);
                }, 3100);
                return;
              }

              if (info) {
                modals.operations.showModal(modals.constants.modalTypes.INFO_MODAL, {
                  title: info.title,
                  message: info.message,
                });
                if (orderSuccessUrl) {
                  setTimeout(() => {
                    window.location.href = compileSuccessUrl(orderSuccessUrl, orderIds);
                  }, 3100);
                }
                setTimeout(() => {
                  cleanUp(store);
                }, 3100);
              } else if (orderSuccessUrl) {
                setTimeout(() => {
                  cleanUp(store);
                  window.location.href = compileSuccessUrl(orderSuccessUrl, orderIds);
                }, 3100);
              }
            });
        })
        .catch((error) => {
          if (error.name !== errorTypes.VALIDATION_ERROR) throw error;

          const userErrorAction = {
            label: 'OK',
            handler: () => {
              store.dispatch(errorsActions.clearError());
            },
          };
          store.dispatch(
            errorsActions.setError({
              ...error,
              action: userErrorAction,
              message: error.message,
            })
          );
          segment.track(eventTypes.USER_GOT_ERROR, {
            error: error.toString(),
          });
        })
        .finally(() => {
          store.dispatch(common.actions.setAppStatusIdle());
        });
      break;
    }
    default:
      break;
  }
  next(action);
};

export default checkout;
