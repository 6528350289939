import * as Sentry from '@sentry/browser';

const identifyUser = (bxUserId, phpSessId, userId) => {
  const data = {
    BX_USER_ID: bxUserId,
    PHPSESSID: phpSessId,
  };

  if (userId) {
    Sentry.setUser({ id: userId });
    data.USER_ID = userId;
  }
  Sentry.setContext('SESSION', data);
};

export default identifyUser;
