const getConfig = () => {
  let ymId;
  const ymIdSelect = (siteId) => {
    switch (siteId) {
      case 'boscoNew':
        ymId = Number(process.env.YANDEX_METRICA_ID_BOSCO);
        break;
      case 'articoli':
        ymId = Number(process.env.YANDEX_METRICA_ID_ARTICOLI);
        break;
      case 'outlet':
        ymId = Number(process.env.YANDEX_METRICA_ID_OUTLET);
        break;

      default:
        ymId = Number(process.env.YANDEX_METRICA_ID_BOSCO);
    }
  };

  ymIdSelect(process.env.SITE_ID);
  return new Promise((resolve) => {
    resolve({
      serviceBaseUrl: process.env.SERVICE_BASE_URL,
      loginPageUrl: process.env.LOGIN_PAGE_URL,
      successOrderUrl: process.env.SUCCESS_ORDER_URL,
      paymentUrl: process.env.PAYMENT_URL,
      siteId: process.env.SITE_ID === 'bosco' ? 'boscoNew' : process.env.SITE_ID,
      callCenterPhone: process.env.CALL_CENTER_PHONE,
      boutiquePhone: process.env.BOUTIQUE_PHONE,
      mindboxEndpointId: process.env.MINDBOX_ENDPOINT_ID,
      popmechanicEmbed: process.env.POPMECHANIC_EMBED,
      popmechanicUrl: process.env.POPMECHANIC_URL,
      email: process.env.EMAIL,
      emptyCartRedirect: process.env.EMPTY_CART_REDIRECT,
      mainSeller: process.env.MAIN_SELLER,
      showCartItemsSeparately: Boolean(Number(process.env.SHOW_CART_ITEMS_SEPARATELY)),
      minFreeDeliveryPrice: process.env.MIN_FREE_DELIVERY_PRICE,
      allowBunuses: Boolean(Number(process.env.ALLOW_BONUSES)),
      myTargetId: process.env.MY_TARGET_ID,
      myTargetFeedId: process.env.MY_TARGET_FEED_ID,
      criteoId: process.env.CRITEO_ID,
      yandexMetricaId: ymId,
    });
  });
};

export default getConfig;
