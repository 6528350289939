import getSelectedCountry from './get-selected-country';
import getSelectedCity from './get-selected-city';
import getSelectedDistrict from './get-selected-district';
import getSelectedMetro from './get-selected-metro';
import getSelectedLocationId from './get-selected-location-id';
import getLocationName from './get-location-name';
import getSelectedDeliveryId from './get-selected-delivery-id';
import getSelectedDeliveryCost from './get-selected-delivery-cost';
import getSelectedDelivery from './get-selected-delivery';
import getPaySystemId from './get-pay-system-id';
import getSelectedDeliveryDetails from './get-selected-delivery-details';
import getSelectedStreet from './get-selected-street';
import getSelectedBuilding from './get-selected-building';
import getSelectedBlock from './get-selected-block';
import getSelectedApartment from './get-selected-apartnent';
import getUseBonuses from './get-use-bonuses';
import getAmount from './get-amount';
import getWantToSubscribeStatus from './get-want-to-subscribe-status';
import getSavedLocations from './get-saved-locations';
import getSelectedSavedLocation from './get-selected-saved-location';
import getFormErrors from './get-form-errors';
import getPaySystemPayload from './get-pay-system-payload';
import getPaySystemOnlineSelected from './get-pay-system-online-selected';
import getPaySystemsList from './get-pay-systems-list';
import getGiftCards from './get-gift-cards';
import getSellersInfoStatus from './get-sellers-info-status';

export default {
  getSelectedCountry,
  getSelectedCity,
  getSelectedDistrict,
  getSelectedMetro,
  getSelectedLocationId,
  getLocationName,
  getSelectedDelivery,
  getSelectedDeliveryId,
  getSelectedDeliveryCost,
  getPaySystemId,
  getPaySystemsList,
  getSelectedDeliveryDetails,
  getSelectedStreet,
  getSelectedBuilding,
  getSelectedBlock,
  getSelectedApartment,
  getUseBonuses,
  getAmount,
  getWantToSubscribeStatus,
  getSavedLocations,
  getSelectedSavedLocation,
  getFormErrors,
  getPaySystemPayload,
  getGiftCards,
  getPaySystemOnlineSelected,
  getSellersInfoStatus,
};
