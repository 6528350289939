import actionTypes from '../constants/action-types';

const updateItem = (id, data) => ({
  type: actionTypes.UPDATE_ITEM,
  payload: {
    id,
    data,
  },
});

export default updateItem;
