import actionTypes from '../constants/action-types';

const cart = (state = [], action) => {
  const { type } = action;
  const { payload } = action;

  switch (type) {
    case actionTypes.CLEAR_CART:
      return [];
    case actionTypes.SET_CART:
      return [...payload];
    case actionTypes.TOGGLE_REMOVE_PRODUCT_FROM_CART: {
      const productIndex = state.findIndex((item) => item.id === payload);
      const newProduct = {
        ...state[productIndex],
        isRemove: !state[productIndex].isRemove,
      };
      const newState = [...state];
      newState.splice(productIndex, 1, newProduct);

      return newState;
    }
    case actionTypes.TOGGLE_SIZE_PRODUCT: {
      const { id, sizeId } = payload;

      const productIndex = state.findIndex((it) => it.id === id);
      const sizeIndex = state[productIndex].sizes.findIndex(
        (it) => it.offerId === sizeId
      );

      const newProduct = { ...state[productIndex] };
      const newSize = newProduct.sizes;

      const selected = !newSize[sizeIndex].selected;
      newProduct.quantity += selected ? 1 : -1;
      newSize[sizeIndex].selected = selected;

      const newState = [...state];
      newState.splice(productIndex, 1, newProduct);

      return newState;
    }
    case actionTypes.UPDATE_ITEM: {
      const { id, data } = payload;
      return state.map((item) => {
        if (item.id !== id) return item;
        const { id: unsafeId, ...safeData } = data;
        return {
          ...item,
          ...safeData,
        };
      });
    }
    default:
      return state;
  }
};

export default cart;
