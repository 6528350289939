import serviceClient from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';

const getDiscountCards = () => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.discountCards.get().then(
    (response) =>
      new Promise((resolve) => {
        response.json().then(({ data }) => resolve(structureToCamel(data)));
      })
  );
};

export default getDiscountCards;
