import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from '../../components';
import Main from '../../components/main';
import Aside from '../../components/aside';
import Header from '../../components/header';
import Content from '../../components/content';
import Loader from '../../../modules/loader/containers';
import CartContainer from '../../../modules/cart/containers';
import RecommendationContainer from '../../../modules/recommendations/containers';
import ConfirmOrderContainer from '../../../modules/cart/containers/confirm-order';
import CheckoutContainer from '../../../modules/checkout/containers';

import MakeCheckoutContainer from '../../../modules/checkout/containers/make-checkout';
import ModalsContainer from '../../../core/modals/containers';
import ProgressContainer from '../../../modules/common/containers/progress-bar';
import UserContainer from '../../../modules/user/containers';
import TotalContainer from '../../../modules/checkout/containers/total';
import TotalContainerSellers from '../../../modules/checkout/containers/total-sellers';
import RegisterModals from '../../../core/modals/containers/register';
import userHooks from '../../../core/user/hooks';
import getHeader from '../../../themes/get-header';

const ThemeLayout = () => {
  const { isAuthentificated } = userHooks.useUser();
  const CartHeader = getHeader();

  return (
    <React.Fragment>
      <ProgressContainer />
      <ModalsContainer />
      <RegisterModals />
      <Router>
        <Layout>
          <Header>
            <CartHeader />
          </Header>
          <Switch>
            <Route path="/cart" exact>
              <Content>
                <React.Fragment>
                  <Loader />
                  <Main>
                    <CartContainer />
                    <RecommendationContainer />
                  </Main>
                  <Aside>
                    <TotalContainerSellers />
                    <ConfirmOrderContainer />
                  </Aside>
                </React.Fragment>
              </Content>
            </Route>
            <Route path="/cart/personal">
              {isAuthentificated && (
                <Content>
                  <React.Fragment>
                    <Loader />
                    <Main>
                      <UserContainer />
                    </Main>
                  </React.Fragment>
                </Content>
              )}
              {isAuthentificated === false && <Redirect to="/cart" />}
            </Route>
            <Route path="/cart/checkout">
              {isAuthentificated && (
                <React.Fragment>
                  <Content>
                    <React.Fragment>
                      <Loader />
                      <Main>
                        <CheckoutContainer />
                      </Main>
                      <Aside>
                        <TotalContainer />
                        <MakeCheckoutContainer />
                      </Aside>
                    </React.Fragment>
                  </Content>
                </React.Fragment>
              )}
              {isAuthentificated === false && <Redirect to="/cart" />}
            </Route>
          </Switch>
        </Layout>
      </Router>
    </React.Fragment>
  );
};

export default ThemeLayout;
