import React from 'react';
import { useSelector } from 'react-redux';
import { DiscountCardUnauthorized } from '@internet-boutique-engineering/site-ui';
import { createUseStyles } from 'react-jss';
import getSiteId from '../../../../core/configuration/selectors/get-site-id';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Unauthorized = () => {
  const classes = useStyles();
  const site = useSelector(getSiteId);

  return (
    <div className={classes.discountCard}>
      <DiscountCardUnauthorized
        href="/personal/order/make/"
        showTwoImage={site !== 'bosco'}
        description={
          site === 'bosco'
            ? 'Для использования семейной карты BOSCO необходимо'
            : 'Для использования семейной карты BOSCO или карты BOSCO OUTLET необходимо'
        }
      />
    </div>
  );
};

export default Unauthorized;
