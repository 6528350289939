import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getSavedLocation from '../../../themes/get-saved-location';
import checkout from '../../checkout';

const SavedLocationContainer = () => {
  const locations = useSelector(checkout.selectors.getSavedLocations);
  const selectedLocation = useSelector(checkout.selectors.getSelectedSavedLocation);
  let selectedLocationId;
  if (selectedLocation) selectedLocationId = selectedLocation.id;

  const dispatch = useDispatch();
  const onSelect = useCallback(
    ({ value }) => {
      const selected = locations.find(({ id }) => id === value);
      if (selected) {
        dispatch(checkout.actions.setSelectedSavedLocation(selected));
      }
    },
    [locations]
  );

  const SavedLocation = getSavedLocation();

  return (
    <SavedLocation
      onSelect={onSelect}
      selectedLocationId={selectedLocationId}
      locations={locations}
    />
  );
};

export default SavedLocationContainer;
