const userInfoStyles = ({
  lowEmphasisTextSize,
  extraLowEmphasisTextSize,
  secondaryTextColor,
  primaryTextColor,
  lightColor,
  mobileMediaQuery,
}) => ({
  emailRow: {
    '& div + div': {
      [`${mobileMediaQuery}`]: {
        display: 'none',
      },
    },
    '& > div': {
      [`${mobileMediaQuery}`]: {
        width: '100%',
        margin: 0,
      },
    },
  },
  phoneRow: {
    [`${mobileMediaQuery}`]: {
      flexDirection: 'column',
    },
    '& div + div': {
      [`${mobileMediaQuery}`]: {
        width: '100%',
        textAlign: 'center',
      },
    },
    '& > div': {
      [`${mobileMediaQuery}`]: {
        width: '100%',
        margin: 0,
      },
    },
  },
  separator: {
    marginTop: 23,
    marginBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: lightColor,
    borderBottomStyle: 'solid',
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 12,
  },
  subRow: {
    width: 231,
    marginLeft: 18,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  checkboxRow: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: lowEmphasisTextSize,
    color: secondaryTextColor,
    marginBottom: 9,
    display: 'block',
  },
  control: {},
  userAgreement: {
    '& div': {
      fontSize: extraLowEmphasisTextSize,
      lineHeight: 1.4,
    },
    '& a': {
      color: primaryTextColor,
    },
  },
  error: {
    position: 'relative',
    width: '100%',
    '& > div': {
      position: 'absolute',
      left: 0,
      top: 3,
      [`${mobileMediaQuery}`]: {
        position: 'static',
        paddingTop: 3,
      },
    },
  },
});

export default userInfoStyles;
