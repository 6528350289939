import { useEffect } from 'react';

import { register } from '../registry';
import modalTypes from '../constants/modal-types';
import getModalConfirm from '../../../themes/get-modal-confirm';
import getModalInfo from '../../../themes/get-modal-info';
import getModalIframe from '../../../themes/get-modal-iframe';
import withModal from '../hoc';

const RegisterModal = () => {
  useEffect(() => {
    const Confirm = getModalConfirm();
    const Info = getModalInfo();
    const PaymentIframe = getModalIframe();

    register(modalTypes.INFO_MODAL, Info);
    register(modalTypes.PAYMENT_IFRAME, PaymentIframe);
    register(modalTypes.CONFIRM, withModal(Confirm));
  }, []);

  return null;
};

export default RegisterModal;
