import authServiceJs from '@internet-boutique-engineering/auth-service-js';
import actionTypes from '../../configuration/constants/action-types';
import authClient from '..';
import http from '../../https';

const setupAuthClient = () => (next) => (action) => {
  if (action.type !== actionTypes.SET_SERVICE_CONFIGURATION) {
    next(action);
    return;
  }

  const {
    payload: { serviceBaseUrl },
  } = action;
  authClient.set(authServiceJs(http, serviceBaseUrl));
  next(action);
};

export default setupAuthClient;
