import { BoscoNewModalIframe } from '@internet-boutique-engineering/site-ui';
import PaymentIframe from '../core/components/payment-iframe';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getModalIframe = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewModalIframe;
    default:
      break;
  }

  return PaymentIframe;
};

export default getModalIframe;
