import actionTypes from '../constants/action-types';

const defaultState = {};

const formErrors = (state = defaultState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SET_FORM_ERROR: {
      const {
        payload: { errorTo, message },
      } = action;
      return {
        ...state,
        [`${errorTo}`]: message,
      };
    }
    case actionTypes.CLEAR_FORM_ERRORS: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default formErrors;
