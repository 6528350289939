import serviceClientAPI from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';

const fetchCart = (userId) => {
  const client = serviceClientAPI.get();
  return client.cart
    .get(userId)
    .then((data) => data.json())
    .then(({ data }) => structureToCamel(data));
};

export default fetchCart;
