import actionTypes from '../constants/action-types';
import appStatuses from '../constants/app-statuses';

const appStatus = (state = appStatuses.IDLE, action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.SET_APP_BUSY:
      return appStatuses.BUSY;
    case actionTypes.SET_APP_IDLE:
      return appStatuses.IDLE;
    default:
      return state;
  }
};

export default appStatus;
