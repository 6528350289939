import constants from '../../delivery/constants';

const isItAddressDelivery = (deliveryId) => {
  const isitSelfDelivery =
    String(deliveryId).indexOf(constants.SELF_DELIVERY_ID_PREFIX) !== -1;
  return !isitSelfDelivery;
};

const validateLocation = (
  deliveryId,
  selectedCountry,
  locationId,
  selectedStreet,
  selectedBuilding,
  selectedApartment
) => {
  const errors = {};

  if (!selectedCountry) errors.country = 'Заполните поле';
  if (!locationId) errors.city = 'Заполните поле';
  if (!selectedStreet && isItAddressDelivery(deliveryId))
    errors.street = 'Заполните поле';
  if (!selectedBuilding && isItAddressDelivery(deliveryId))
    errors.building = 'Заполните поле';
  if (!selectedApartment && isItAddressDelivery(deliveryId))
    errors.apartment = 'Заполните поле';

  const keys = Object.keys(errors);
  return keys.length ? errors : null;
};

export default validateLocation;
