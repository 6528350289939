let authClient;

const get = () => authClient;
const set = (client) => {
  authClient = client;
};

export default {
  get,
  set,
};
