import get from 'lodash.get';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TwoStateControl,
  SelectField,
  Error,
  // PickPoint,
} from '@internet-boutique-engineering/site-ui';
import { createUseStyles } from 'react-jss';
// import constants from '../constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const DeliveryRow = ({
  item,
  onDelivery,
  onDeliveryDetails,
  // locationName,
  deliveryId,
  deliveryCost,
  deliveryDetails,
  deliveryError,
}) => {
  const classes = useStyles();

  const { options } = item;
  const setCallBefore = useCallback(({ checked }) => {
    onDeliveryDetails({
      callBefore: checked,
    });
  });

  const deliverySlots = get(options, 'deliverySlots', null);
  const deliveryDescription = get(options, 'deliveryDescription', null);
  const deliveryDate = get(deliveryDetails, 'deliveryDate', null);
  const deliveryTime = get(deliveryDetails, 'deliveryTime', null);
  const deliverySlot = deliverySlots
    ? deliverySlots.find((slot) => slot.id === deliveryDate)
    : null;
  const deliveryTimes = get(deliverySlot, 'times', null);

  const setDeliverySlotDate = useCallback((data) => {
    if (!data) return;
    const { value } = data;
    onDeliveryDetails({
      deliveryDate: value,
      deliveryTime: data.times && data.times[0] ? data.times[0] : null,
    });
  });
  const setDeliverySlotTime = useCallback((data) => {
    if (!data) return;
    const { value } = data;
    onDeliveryDetails({
      deliveryDate,
      deliveryTime: value || data,
    });
  });
  const isChecked = item.id === deliveryId;

  return (
    <React.Fragment>
      <div className={classes.row}>
        <TwoStateControl
          checked={isChecked}
          onChange={onDelivery}
          name="delivery"
          value={item.id}
        >
          <React.Fragment>
            {item.title} – {isChecked ? deliveryCost || item.cost : item.cost} руб.{' '}
            {item.extraConditions}{' '}
            {item.link && (
              <a className={classes.link} href={item.link}>
                Подробнее
              </a>
            )}
          </React.Fragment>
        </TwoStateControl>
        {isChecked && options && options.deliverySlots && (
          <div className={classes.optionRow}>
            <SelectField
              onSelect={setDeliverySlotDate}
              placeholder="Дата доставки"
              selectedValue={deliverySlot}
              items={options.deliverySlots}
            />
          </div>
        )}
        {isChecked && deliveryTimes && (
          <div className={classes.optionRow}>
            <SelectField
              onSelect={setDeliverySlotTime}
              placeholder="Время доставки"
              selectedValue={deliveryTime}
              items={deliveryTimes}
            />
          </div>
        )}
        {isChecked && options && options.callBefore && (
          <div className={classes.optionRow}>
            <TwoStateControl
              onChange={setCallBefore}
              checked={deliveryDetails ? deliveryDetails.callBefore : false}
              type="checkbox"
              value={true}
            >
              Позвонить за час
            </TwoStateControl>
          </div>
        )}
        {/* {item.id === deliveryId && item.id === constants.PICK_POINT_DELIVERY_ID && (
          <PickPoint
            id={item.id}
            city={locationName}
            onClick={onDelivery}
            value={item.id}
            onSelected={onDeliveryDetails}
            postamatInfo={deliveryDetails}
          />
        )} */}
        {deliveryDescription && (
          <div className={classes.description}>{deliveryDescription}</div>
        )}
      </div>

      {deliveryError && deliveryError[item.id] && (
        <div className={classes.errorRow}>
          <div className={classes.errorWrapper}>
            <Error>{deliveryError[item.id]}</Error>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const slots = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

const delivery = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  extraConditions: PropTypes.string,
  cost: PropTypes.number.isRequired,
  options: PropTypes.shape({
    callBefore: PropTypes.bool,
    deliverySlots: PropTypes.arrayOf(slots),
  }),
  link: PropTypes.string,
});

DeliveryRow.propTypes = {
  item: PropTypes.shape(delivery).isRequired,
  onDelivery: PropTypes.func.isRequired,
  onDeliveryDetails: PropTypes.func.isRequired,
  // locationName: PropTypes.string.isRequired,
  deliveryId: PropTypes.string.isRequired,
  deliveryCost: PropTypes.number,
  deliveryDetails: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
    deliverySlot: slots,
    callBefore: PropTypes.bool,
  }),
  deliveryError: PropTypes.any,
};
DeliveryRow.defaultTypes = {
  deliveryDetails: null,
  deliveryCost: null,
};

export default DeliveryRow;
