import setAuthToken from './set-auth-token';
import setUser from './set-user';
import initiateUpdate from './initiate-update';
import updateUser from './update-user';

export default {
  setAuthToken,
  setUser,
  initiateUpdate,
  updateUser,
};
