import connectedStore from '../../../store/connected-store';
import actions from '../actions';
import segment, { eventTypes } from '../../../modules/analytic/segment';

function errorHandler(error, getState = null, lastAction = null) {
  if (!error) return;
  console.error(error);
  if (getState) console.debug('current state', getState());
  console.debug('last action was', lastAction);

  const store = connectedStore.get();
  if (error.isCritical) {
    store.dispatch(
      actions.setError({
        ...error,
        message: error.message,
      })
    );
  }
  try {
    segment.track(eventTypes.USER_GOT_ERROR, {
      error: error.toString(),
    });
  } catch (_) {
    //
  }
  // optionally dispatch an action due to the error using the dispatch parameter
}

export default errorHandler;
