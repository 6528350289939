const cartHeader = ({
  fontFamily,
  primaryTextColor,
  lowEmphasisTextSize,
  activeTextColor,
  transitionDuration,
  transitionTimingFunction,
  boldWeight,
  tabletMediaQuery,
  extraLowEmphasisTextSize,
  bonusColor,
  mobileMediaQuery,
  cartHeaderHeightMobile,
  logoLeft,
  logoBottom,
  logoBottomTablet,
  logoBottomMobile,
  logoWidth,
  logoWidthTablet,
  logoWidthMobile,
  logoMaxWidthMobile,
  secondaryColor,
  cartNavPadding,
  cartNavPaddingTablet,
  cartNavPaddingMobile,
  topLinePadding,
  topLinePaddingTablet,
  topLinePaddingMobile,
  cartHeaderBorderMobile,
  cartHeaderBorderRadius,
  primaryColor,
  extraLightColor,
}) => {
  const [, , , topLinePaddingLeft] = topLinePadding;
  const [, , , topLinePaddingTabletLeft] = topLinePaddingTablet;

  return {
    menu: {
      margin: cartNavPadding,
      borderRadius: [0, 0, cartHeaderBorderRadius, cartHeaderBorderRadius],
      [`${tabletMediaQuery}`]: {
        padding: cartNavPaddingTablet,
      },
      [`${mobileMediaQuery}`]: {
        display: 'none',
        padding: cartNavPaddingMobile,
      },
    },
    topLine: {
      display: 'flex',
      padding: topLinePadding,
      fontSize: lowEmphasisTextSize,
      [`${tabletMediaQuery}`]: {
        fontSize: extraLowEmphasisTextSize,
        padding: topLinePaddingTablet,
      },
      [`${mobileMediaQuery}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: topLinePaddingMobile,
      },
    },
    logo: {
      position: 'absolute',
      bottom: logoBottom,
      left: logoLeft,
      maxWidth: logoWidth,
      width: '23%',
      '&[can-be-filled]': {
        backgroundColor: secondaryColor,
      },
      [`${tabletMediaQuery}`]: {
        maxWidth: logoWidthTablet,
        bottom: logoBottomTablet,
      },
      [`${mobileMediaQuery}`]: {
        width: logoWidthMobile,
        maxWidth: logoMaxWidthMobile,
        // left: '58%',
        left: '50%',
        bottom: logoBottomMobile,
        transform: 'translateX(-50%)',
      },
    },
    phone: {
      color: primaryTextColor,
      fontSize: lowEmphasisTextSize,
      textDecoration: 'none',
      transitionDuration,
      transitionTimingFunction,
      transitionProperty: 'color',
      fontWeight: boldWeight,
      '&:hover': {
        color: activeTextColor,
      },
      [`${tabletMediaQuery}`]: {
        fontSize: extraLowEmphasisTextSize,
      },
    },
    phoneText: {
      [`${mobileMediaQuery}`]: {
        display: 'none',
      },
    },
    phoneIcon: {
      display: 'none',
      '& svg': {
        stroke: bonusColor,
        fill: bonusColor,
      },
      [`${mobileMediaQuery}`]: {
        width: 19,
        display: 'block',
      },
    },
    help: {
      '& button': {
        fontFamily,
        backgroundColor: 'transparent',
        fontSize: lowEmphasisTextSize,
        padding: 0,
        margin: 0,
        color: primaryTextColor,
        borderWidth: 0,
        cursor: 'pointer',
        fontWeight: boldWeight,
        transitionDuration: 300,
        transitionTimingFunction: 'ease-out',
        transitionProperty: 'color',
        '&:hover': {
          color: activeTextColor,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      [`${mobileMediaQuery}`]: {
        display: 'none',
      },
    },
    separator: {
      padding: [0, 3],
      [`${mobileMediaQuery}`]: {
        display: 'none',
      },
    },
    burgerButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: `calc(${cartHeaderHeightMobile}px - ${cartHeaderBorderMobile}px)`,
      width: `calc(${cartHeaderHeightMobile}px - ${cartHeaderBorderMobile}px)`,
      pointerEvents: 'all',
    },
    mobileMenu: {
      display: 'none',
      width: '100%',
      height: '100%',
      position: 'fixed',
      pointerEvents: 'none',
      top: 0,
      left: 0,
      zIndex: 10,
      transitionDuration,
      transitionTimingFunction,
      [`${mobileMediaQuery}`]: {
        display: 'block',
        transform: 'translateX(0)',
      },
    },
    mobileMenuOpen: {
      transform: `translateX(calc(100vw - ${
        cartHeaderHeightMobile - cartHeaderBorderMobile
      }px))`,
    },

    subLine: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: topLinePaddingLeft,
      paddingTop: 8,
      fontSize: lowEmphasisTextSize,
      color: primaryTextColor,
      fontWeight: 500,
      [`${tabletMediaQuery}`]: {
        fontSize: extraLowEmphasisTextSize,
        paddingLeft: topLinePaddingTabletLeft,
      },
      [`${mobileMediaQuery}`]: {
        display: 'none',
      },
    },
    subLineSeparator: {
      borderLeftWidth: 1,
      borderLeftColor: primaryColor,
      borderLeftStyle: 'solid',
      width: 1,
      height: 22,
      margin: [0, 10],
    },
    shopAdresses: {
      display: 'inline-flex',
      alignItems: 'center',
      color: primaryTextColor,
      textDecoration: 'none',
      '& img': {
        width: 19,
        marginRight: 4,
      },
    },
    clientDays: {
      display: 'inline-flex',
      alignItems: 'center',
      color: primaryTextColor,
      textDecoration: 'none',
      '& img': {
        width: 22,
        marginRight: 4,
      },
    },
    headerMenuIcons: {
      display: 'flex',
      position: 'absolute',
      right: 212,
      top: 40,
      [`${tabletMediaQuery}`]: {
        right: 50,
        top: 20,
      },
      [`${mobileMediaQuery}`]: {
        right: 32,
        top: 20,
      },
    },
    headerMenuIcon: {
      marginRight: 33,
      textDecoration: 'none',
      color: extraLightColor,
      position: 'relative',
      [`${mobileMediaQuery}`]: {
        marginRight: 15,
      },
      '& span': {
        display: 'block',
        position: 'absolute',
        fontSize: 14,
        color: extraLightColor,
        width: 26,
        height: 26,
        borderRadius: 26,
        background: 'rgba(211,92,163,.7)',
        lineHeight: '26px',
        textAlign: 'center',
        top: -16,
        right: -11,
      },
    },
  };
};

export default cartHeader;
