import serviceClient from '../../../core/service-client';

const fetchCcountries = () => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.location
    .getCountries()
    .then((response) => response.json().then(({ data }) => data));
};

export default fetchCcountries;
