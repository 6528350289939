import * as Sentry from '@sentry/browser';

const init = () => {
  const name = process.env.SENTRY_ENVIRONMENT;
  const dsn = process.env.SENTRY_DSN;
  Sentry.init({
    dsn,
    environment: name,
  });
};
export default init;
