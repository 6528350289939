import actionTypes from '../constants/action-types';

const initiateUpdate = (userId, data) => ({
  type: actionTypes.INITIATE_USER_UPDATE,
  payload: {
    userId,
    ...data,
  },
});

export default initiateUpdate;
