const mobileMenuStyles = ({
  cartHeaderHeightMobile,
  extraLowEmphasisTextSize,
  smallBorderRadius,
  mobileMenuBackgroundColor,
  mobileMenuTextColor,
  mobileSubMenuBackgroundColor,
  mobileSubPadding,
}) => ({
  menu: {
    position: 'fixed',
    top: 0,
    left: `calc(-100vw + ${cartHeaderHeightMobile - 16}px)`,
    zIndex: 10,
    backgroundColor: mobileMenuBackgroundColor,
    width: `calc(100vw - ${cartHeaderHeightMobile - 16}px)`,
    height: '100%',
    overflow: 'auto',
    pointerEvents: 'all',
  },
  sub: {
    backgroundColor: mobileSubMenuBackgroundColor,
    padding: mobileSubPadding,
  },
  link: {
    color: mobileMenuTextColor,
    textDecoration: 'none',
  },
  buttonPlace: {
    padding: '4% 6.2% 6.2%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    fontSize: extraLowEmphasisTextSize,
    textTransform: 'uppercase',
    borderRadius: smallBorderRadius,
    width: '45%',
    paddintTop: 15,
    paddintBottom: 15,
  },
  buttonLogout: {
    fontSize: extraLowEmphasisTextSize,
    textTransform: 'uppercase',
    borderRadius: smallBorderRadius,
    backgroundColor: 'transparent',
    width: '45%',
    borderColor: mobileMenuTextColor,
    borderWidth: 1,
    borderStyle: 'solid',
    paddintTop: 14,
    paddintBottom: 14,
    color: mobileMenuTextColor,
  },
});

export default mobileMenuStyles;
