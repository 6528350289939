import {
  BoscoNewSavedLocation,
  SavedLocation,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getSavedLocation = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewSavedLocation;
    default:
      break;
  }

  return SavedLocation;
};

export default getSavedLocation;
