import fetch from 'cross-fetch';

const post = (path, query = {}, headers = {}) => {
  if (!path) Promise.reject(new Error("'path' should not be NULL or undefined"));

  return fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(query),
  });
};

export default post;
