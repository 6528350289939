import actionTypes from '../constants/action-types';

const user = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_USER: {
      return payload;
    }
    case actionTypes.UPDATE_USER: {
      const { name, lastname, email, phone } = payload;
      return {
        ...state,
        name,
        lastname,
        email,
        phone,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
