import menuOutlet from './menu-outlet';
import menuBosco from './menu-bosco';
import connectedStore from '../../../../store/connected-store';
import configuration from '../../../../core/configuration';
import constants from '../../../../core/constants';

const getMenu = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  if (siteId === constants.BOSCO_SITE_NAME) return menuBosco;
  return menuOutlet;
};

export default getMenu;
