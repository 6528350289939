import actionTypes from '../constants/action-types';
import actions from '../actions';

const clear = (store) => (next) => (action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SET_COUNTRY: {
      store.dispatch(actions.clear());
      store.dispatch(actions.clearFormErrors());
      break;
    }
    case actionTypes.SET_CITY: {
      store.dispatch(actions.setDistrict(null));
      store.dispatch(actions.setMetro(null));
      store.dispatch(actions.setDelivery(null));
      store.dispatch(actions.clearFormErrors());
      break;
    }
    case actionTypes.SET_BATCH_LOCATION: {
      store.dispatch(actions.setDelivery(null));
      store.dispatch(actions.clearDeliveryDetails());
      store.dispatch(actions.clearPaySystem());
      store.dispatch(actions.clearFormErrors());
      break;
    }
    case actionTypes.SET_DELIVERY: {
      store.dispatch(actions.clearDeliveryDetails());
      store.dispatch(actions.clearPaySystem());
      store.dispatch(actions.clearFormErrors());
      break;
    }
    case actionTypes.SET_DELIVERY_DETAILS:
    case actionTypes.SET_PAYSYSTEM: {
      store.dispatch(actions.clearFormErrors());
      break;
    }
    default:
      break;
  }
  next(action);
};

export default clear;
