import {
  ProductCard,
  ArticoliProductCard,
  BoscoNewProductCard,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getProductCard = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return ArticoliProductCard;
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewProductCard;
    default:
      break;
  }
  return ProductCard;
};

export default getProductCard;
