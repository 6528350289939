import mytarget from '../../../core/mytarget';
import https from '../../../core/https';
import getMyTargetConfig from '../../../core/configuration/selectors/get-mytarget-config';
import storeInstance from '../../../store/connected-store';

export default {
  identify: (userId) => {
    const store = storeInstance.get();
    const state = store.getState();
    const { id } = getMyTargetConfig(state);

    if (userId && id) {
      https.get('https://top-fwz1.mail.ru/counter', {
        id,
        pid: userId,
      });
    }
  },
  trackCart: (products, totalPrice) => {
    const tmr = mytarget.get();
    const store = storeInstance.get();
    const state = store.getState();
    const { feedId: list } = getMyTargetConfig(state);

    if (!list || !tmr) return;

    tmr.push({
      type: 'itemView',
      productid: products,
      pagetype: 'cart',
      totalvalue: totalPrice,
      list,
    });
  },
  trackOrder: (products, totalPrice) => {
    const tmr = mytarget.get();
    const store = storeInstance.get();
    const state = store.getState();
    const { feedId: list } = getMyTargetConfig(state);

    if (!list || !tmr) return;

    tmr.push({
      type: 'itemView',
      productid: products,
      pagetype: 'purchase',
      totalvalue: totalPrice,
      list,
    });
  },
};
