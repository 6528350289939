import setCart from './set-cart';
import clearCart from './clear-cart';
import toggleRemoveProduct from './toggle-remove-product';
import toggleSizeProduct from './toggle-size-product';
import updateItem from './update-item';

export default {
  setCart,
  clearCart,
  toggleRemoveProduct,
  toggleSizeProduct,
  updateItem,
};
