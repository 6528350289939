const helpMenuStyles = ({
  fontFamily,
  primaryTextColor,
  lowEmphasisTextSize,
  activeTextColor,
  boldWeight,
  mobileMediaQuery,
}) => ({
  dropdown: {
    position: 'relative',
  },
  container: {
    zIndex: 3,
    display: 'flex',
    alignItems: 'stretch',
  },
  help: {
    '& button': {
      fontFamily,
      backgroundColor: 'transparent',
      fontSize: lowEmphasisTextSize,
      padding: 0,
      margin: 0,
      color: primaryTextColor,
      borderWidth: 0,
      cursor: 'pointer',
      fontWeight: boldWeight,
      transitionDuration: 300,
      transitionTimingFunction: 'ease-out',
      transitionProperty: 'color',
      '&:hover': {
        color: activeTextColor,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    [`${mobileMediaQuery}`]: {
      display: 'none',
    },
  },
  button: {
    fontSize: 16,
  },
});

export default helpMenuStyles;
