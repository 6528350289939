import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Button } from '@internet-boutique-engineering/site-ui';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Modal = ({ title, message, action }) => {
  const classes = useStyles();
  const { label, handler } = action;
  return (
    <React.Fragment>
      <div className={classes.cover}></div>
      <div className={classes.modal}>
        <h2>{title}</h2>
        <p>{message}</p>
        {handler && (
          <Button className={classes.button} onClick={handler}>
            {label}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func,
  }),
};

Modal.defaultProps = {
  title: 'Ошибка',
  action: {},
};

export default Modal;
