import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getPaySystem from '../../../themes/get-pay-system';
import LocationContainer from '../../location/containers';
import SubscribeContainer from './subscribe';
import SavedLocationContainer from '../../location/containers/saved-location';
import DeliveryContainer from '../../delivery/containers';
import withPaySystem from '../../pay-system/hoc';
import configuration from '../../../core/configuration';
import user from '../../../core/user';
import segment, { eventTypes } from '../../analytic/segment';

const CheckoutContainer = ({ hasSubscribe = true }) => {
  const { serviceReady } = useSelector(configuration.selectors.statuses);
  const { name, lastname, email, phone } = useSelector(user.selectors.getUser);

  if (!user.verifiers.isUserAuthentificated()) return null;

  if (!name || !lastname || !email || !phone) {
    segment.track(eventTypes.REDIRECT_TO_PERSONAL_DATA_PAGE);
    return (
      <Redirect
        to={{
          pathname: '/cart/personal',
          state: { referrer: '/cart/checkout' },
        }}
      />
    );
  }

  const PaySystemsContainer = withPaySystem(getPaySystem());
  return (
    <React.Fragment>
      {serviceReady && (
        <React.Fragment>
          <SavedLocationContainer />
          <LocationContainer />
          <DeliveryContainer />
          <PaySystemsContainer />
          {hasSubscribe && <SubscribeContainer />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CheckoutContainer.propTypes = {
  hasSubscribe: PropTypes.bool,
};

export default CheckoutContainer;
