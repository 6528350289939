import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import segment, { eventTypes } from '../../analytic/segment';
import getBuyCartBlock from '../../../themes/get-buy-cart-block';
import isBoscoCafeLimitSatisfied from '../helpers/is-bosco-cafe-limit-satisfied';
import isBoscoCafeTimeSutisfied from '../helpers/is-bosco-cafe-time-satisfied';
import getCart from '../selectors/get-cart';

import useConfirmOrder from '../hooks/use-confirm-order';

const ConfirmOrderContainer = () => {
  const { isAuthentificated, path } = useConfirmOrder();

  const trackClick = useCallback(() => {
    segment.track(eventTypes.CART_START_CHECKOUT);
  });
  const cart = useSelector(getCart);

  const isLimitSatisfied = isBoscoCafeLimitSatisfied(cart);
  const isTimeSatisfied = isBoscoCafeTimeSutisfied(cart);

  const isDisabled = !isLimitSatisfied || !isTimeSatisfied;
  const disabledText = isTimeSatisfied
    ? 'Стоимость товаров BOSCOCAFE должна быть выше 4999 руб'
    : 'Товары BOSCOCAFE можно заказать с 10 до 18 часов';

  const BuyCartBlock = getBuyCartBlock();

  if (!cart.length) return null;

  return isAuthentificated ? (
    <BuyCartBlock
      onClick={trackClick}
      label="Подтвердить заказ"
      limitationText={isDisabled && disabledText}
      disabled={isDisabled}
      href={path}
    />
  ) : (
    <BuyCartBlock
      onClick={trackClick}
      label="Подтвердить заказ"
      disabled={isDisabled}
      limitationText={isDisabled && disabledText}
      externalHref={path}
    />
  );
};

export default ConfirmOrderContainer;
