import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import middleware from './middleware';
import storeConnector from './store-connector';

const persistConfig = {
  key: 'chackout',
  storage,
  whitelist: [
    'locationData',
    'checkout',
    'discounts',
    'promocode',
    'amount',
    'savedLocations',
    'favorites',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeWithDevTools(storeConnector, applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default store;
