const discountCardBlockStyles = ({ sellerBorderColor, mobileMediaQuery }) => ({
  wrapper: {
    borderBottomWidth: 1,
    borderBottomColor: sellerBorderColor,
    borderBottomStyle: 'solid',
    paddingTop: 14,
    paddingBottom: 14,
    marginBottom: 20,
  },
  discountCard: {
    maxWidth: 360,
    padding: [30, 0],
    [`${mobileMediaQuery}`]: {
      maxWidth: '100%',
      padding: ['4%', '4%'],
    },
    '& button': {
      minWidth: 112,
    },
  },
});

export default discountCardBlockStyles;
