export default {
  USER_GOT_ERROR: '@CHECKOUT_APP/USER_GOT_ERROR',
  AUTH_USER: '@CHECKOUT_APP/AUTH_USER',
  CHECKOUT_USER_CLICK: '@CHECKOUT_APP/CHECKOUT_USER_CLICK',
  CHECKOUT_REQUEST: '@CHECKOUT_APP/CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS: '@CHECKOUT_APP/CHECKOUT_SUCCESS',
  SET_COUNTRY: '@CHECKOUT_APP/LOCATION/SET_COUNTRY',
  SET_CITY: '@CHECKOUT_APP/LOCATION/SET_CITY',
  SET_STREET: '@CHECKOUT_APP/LOCATION/SET_STREET',
  SET_BUILDING: '@CHECKOUT_APP/LOCATION/SET_BUILDING',
  SET_BLOCK: '@CHECKOUT_APP/LOCATION/SET_BLOCK',
  SET_APARTMENT: '@CHECKOUT_APP/LOCATION/SET_APARTMENT',
  SET_BATCH_LOCATION: '@CHECKOUT_APP/LOCATION/SET_BATCH_LOCATION',
  SET_DELIVERY: '@CHECKOUT_APP/DELIVERY/SET_DELIVERY',
  SET_DELIVERY_DETAILS: '@CHECKOUT_APP/DELIVERY/SET_DELIVERY_DETAILS',
  SET_PAYSYSTEM: '@CHECKOUT_APP/PAYSYSTEM/SET_PAYSYSTEM',
  SET_PAYSYSTEMS_LIST: '@CHECKOUT_APP/PAYSYSTEM/SET_PAYSYSTEMS_LIST',
  SET_CARDS: '@CHECKOUT_APP/DISCOUNT/SET_CARDS',
  SET_PROMOCODE: '@CHECKOUT_APP/PROMOCODES/SET_PROMOCODE',
  LIVE_CHAT_CLICK: '@CHECKOUT_APP/LIVE_CHAT/CLICK',
  LIVE_CHAT_OPEN: '@CHECKOUT_APP/LIVE_CHAT/OPEN',
  REDIRECT_TO_PERSONAL_DATA_PAGE: '@CHECKOUT_APP/REDIRECT_TO_PERSONAL_DATA_PAGE',
  PERSONAL_DATA_SAVE_CLICK: '@CHECKOUT_APP/PERSONAL_DATA_SAVE_CLICK',
  PERSONAL_DATA_SAVE_INITIATED: '@CHECKOUT_APP/PERSONAL_DATA_SAVE_INITIATED',
  PERSONAL_DATA_SAVE_SUCCESS: '@CHECKOUT_APP/PERSONAL_DATA_SAVE_SUCCESS',
  BURGER_MENU_OPEN: '@CHECKOUT_APP/BURGER_MENU_OPEN',
  BURGER_MENU_CLOSE: '@CHECKOUT_APP/BURGER_MENU_CLOSE',
  CART_START_CHECKOUT: '@CHECKOUT_APP/CART_START_CHECKOUT',
  CART_LIST_INITIATED: '@CHECKOUT_APP/CART_LIST_INITIATED',
  DELETE_ITEM_FROM_CART_INITIATED: '@CHECKOUT_APP/DELETE_ITEM_FROM_CART_INITIATED',
  SET_SIZE_FOR_ITEM_FROM_CART_INITIATED:
    '@CHECKOUT_APP/SET_SIZE_FOR_ITEM_FROM_CART_INITIATED',
  DECREASE_QUANTITY_FROM_CART_INITIATED:
    '@CHECKOUT_APP/DECREASE_QUANTITY_FROM_CART_INITIATED',
  INCREASE_QUANTITY_FROM_CART_INITIATED:
    '@CHECKOUT_APP/INCREASE_QUANTITY_FROM_CART_INITIATED',
  ADD_TO_FAVORITE_FROM_CART_INITIATED:
    '@CHECKOUT_APP/ADD_TO_FAVORITE_FROM_CART_INITIATED',
};
