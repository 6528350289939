const sellerName = 'Bosco cafe';
const workingHours = {
  from: 10,
  to: 18,
};

const isBoscoCafeLimitSatisfied = (cart) => {
  const cafeItems = cart.filter((item) => item.seller === sellerName);
  if (!cafeItems.length) return true;

  const currentDate = new Date();
  const currentHour = currentDate.getHours();

  const { from, to } = workingHours;
  return currentHour >= from && currentHour <= to;
};

export default isBoscoCafeLimitSatisfied;
