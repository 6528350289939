import actionTypes from '../constants/action-types';

const defaultState = [];
const discounts = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_CARDS:
      return payload;
    case actionTypes.CLEAR_CARDS:
      return defaultState;
    default:
      return state;
  }
};

export default discounts;
