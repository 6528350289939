import { BoscoNewSubscribe } from '@internet-boutique-engineering/site-ui';
import Subscribe from '../modules/checkout/components/subscribe';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getSubscribe = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewSubscribe;
    default:
      break;
  }

  return Subscribe;
};

export default getSubscribe;
