import { BoscoNewCartPersonal } from '@internet-boutique-engineering/site-ui';
import UserInfo from '../modules/user/components/user-info';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getUserInfo = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewCartPersonal;
    default:
      break;
  }

  return UserInfo;
};

export default getUserInfo;
