import React from 'react';
import DiscountCardBlock from '../components/discount-card';

import useDiscountCard from '../hooks/use-discount-card';

const DiscountsContainer = () => {
  const { error, discountCard, onSetDiscount } = useDiscountCard();

  return (
    <DiscountCardBlock
      error={error}
      discountCard={discountCard}
      onSetDiscount={onSetDiscount}
    />
  );
};

export default DiscountsContainer;
