const buyCartBlockStyles = ({
  secondaryTextColor,
  extraLowEmphasisTextSize,
  lowEmphasisTextSize,
  primaryColor,
  mobileMediaQuery,
}) => ({
  footer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    [`${mobileMediaQuery}`]: {
      padding: [0, 10, 0, 10],
      flexDirection: 'column-reverse',
      height: 'auto',
    },
  },
  buttonBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '80%',
    [`${mobileMediaQuery}`]: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  customButton: {
    minWidth: 300,
    textTransform: 'uppercase',
    display: 'block',
    position: 'relative',
    marginLeft: '30%',
    [`${mobileMediaQuery}`]: {
      left: 0,
      marginLeft: 0,
    },
  },
  ofertaBlock: {
    boxSizing: 'border-box',
    padding: [0, 0, 0, 33],
    color: secondaryTextColor,
    fontSize: extraLowEmphasisTextSize,
    '& a': {
      color: primaryColor,
      textDecoration: 'none',
    },
    [`${mobileMediaQuery}`]: {
      width: '100%',
      textAlign: 'center',
      padding: ['4vw', '21vw'],
    },
  },
  limitation: {
    maxWidth: 280,
    marginLeft: 20,
    textTransform: 'uppercase',
    fontSize: lowEmphasisTextSize,
    [`${mobileMediaQuery}`]: {
      margin: [20, 0],
      textAlign: 'center',
    },
  },
});

export default buyCartBlockStyles;
