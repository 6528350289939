import configuration from '../../configuration';
import authClient from '../../auth-client';
import setAuthToken from '../actions/set-auth-token';
import setUser from '../actions/set-user';
import fetchUser from '../operations/fetch-user';
import structureToCamel from '../../helpers/structure-to-camel';
import AuthentifitationError from '../../errors/authntification';
import NetworkError from '../../errors/network';
import getCookieByName from '../helpers/get-cookie-by-name';
import sentry from '../../sentry';
import segment, { eventTypes } from '../../../modules/analytic/segment';
import analytic from '../../../modules/analytic';
import setAppStatusBusy from '../../../modules/common/actions/set-app-status-busy';
import setAppStatusIdle from '../../../modules/common/actions/set-app-status-idle';

const authenticate = (store) => (next) => (action) => {
  const { type } = action;

  if (type !== configuration.constants.actionTypes.SET_SERVICE_CONFIGURATION) {
    next(action);
    return;
  }

  next(action);
  const authClientInstance = authClient.get();

  const BX_USER_ID = getCookieByName('BX_USER_ID');
  const BITRIX_SM_SALE_UID = getCookieByName('BITRIX_SM_SALE_UID');
  const PHPSESSID =
    getCookieByName('BX_REACT_ID') || getCookieByName('BITRIX_SM_cartsession');
  const errorAction = {
    label: 'Авторизоваться',
    handler: () => {
      window.location.href = '/';
    },
  };
  sentry.identifyUser(BX_USER_ID, PHPSESSID);

  if (!PHPSESSID || !BX_USER_ID) {
    throw new AuthentifitationError('Пожалуйста, авторизуйтесь', errorAction);
  }

  store.dispatch(setAppStatusBusy());
  authClientInstance
    .getTokenByCookies({
      BX_USER_ID,
      PHPSESSID,
    })
    .then((data) => {
      const { status, url, statusText, headers } = data;
      switch (status) {
        case 400:
        case 401:
        case 403:
          throw new AuthentifitationError('Вам нужно авторизоваться', errorAction);
        case 404:
          store.dispatch(setAuthToken(null, null, 0));
          segment.identify(null, {
            bxUserId: BX_USER_ID,
            sessioId: PHPSESSID,
          });
          sentry.identifyUser(BX_USER_ID, PHPSESSID, null);
          store.dispatch(
            setUser({
              bxUserId: BX_USER_ID,
              bxSmSaleId: BITRIX_SM_SALE_UID,
              utm: {
                utm_source: getCookieByName('utm_source'),
                utm_medium: getCookieByName('utm_medium'),
                utm_campaign: getCookieByName('utm_campaign'),
                utm_content: getCookieByName('utm_content'),
                utm_term: getCookieByName('utm_term'),
              },
            })
          );
          return null;
        case 200:
          return data.json();
        default:
          throw new NetworkError(status, url, statusText, headers);
      }
    })
    .then((data) => {
      if (!data) {
        store.dispatch(setAppStatusIdle());
        return;
      }

      const {
        data: { accessToken, refreshToken, iat },
      } = structureToCamel(data);
      store.dispatch(setAuthToken(accessToken, refreshToken, iat));
      fetchUser().then((response) => {
        const { data: userData } = response;
        const { id, email } = userData;
        segment.identify(id, {
          id,
          email,
          $email: email,
          bxUserId: BX_USER_ID,
          sessioId: PHPSESSID,
        });
        sentry.identifyUser(BX_USER_ID, PHPSESSID, id);
        analytic.criteo.identify(email);
        store.dispatch(
          setUser({
            ...userData,
            bxUserId: BX_USER_ID,
            bxSmSaleId: BITRIX_SM_SALE_UID,
            utm: {
              utm_source: getCookieByName('utm_source'),
              utm_medium: getCookieByName('utm_medium'),
              utm_campaign: getCookieByName('utm_campaign'),
              utm_content: getCookieByName('utm_content'),
              utm_term: getCookieByName('utm_term'),
            },
          })
        );
        store.dispatch(setAppStatusIdle());
      });
      segment.track(eventTypes.AUTH_USER, {
        bxUserId: BX_USER_ID,
        sessioId: PHPSESSID,
      });
      analytic.myTarget.identify(BX_USER_ID);
      // analytic.facebookPixel.identify();
      analytic.yandexMetrica.identify();
    })
    .catch((error) => {
      store.dispatch(setAppStatusIdle());
      throw error;
    });
};

export default authenticate;
