import setBatchLocation from './set-batch-location';
import setCountry from './set-country';
import setCity from './set-city';
import setMetro from './set-metro';
import setDistrict from './set-district';
import setStreet from './set-street';
import setBuilding from './set-building';
import setBlock from './set-block';
import setApartment from './set-apartment';
import setDelivery from './set-delivery';
import setDeliveryCost from './set-delivery-cost';
import setPaySystem from './set-paysystem';
import clearPaySystem from './clear-paysystem';
import setDeliveryDetails from './set-delivery-details';
import setUseBonuses from './set-use-bonuses';
import clearDeliveryDetails from './clear-delivery-details';
import setAmount from './set-amount';
import clearAmount from './clear-amount';
import clear from './clear';
import setSubscribe from './set-subscribe';
import setSavedLocations from './set-saved-locations';
import setSelectedSavedLocation from './set-selected-saved-location';
import doCheckout from './do-checkout';
import setFormError from './set-form-error';
import clearFormErrors from './clear-form-errors';
import updatePaySystemPayload from './update-pay-system-payload';
import setGiftCards from './set-gift-cards';
import setSellersInfoHide from './set-sellers-info-hide';
import setSellersInfoShow from './set-sellers-info-show';
import setPaySystemsList from './set-paysystems-list';

export default {
  setBatchLocation,
  setCountry,
  setCity,
  setMetro,
  setDistrict,
  setStreet,
  setBuilding,
  setBlock,
  setApartment,
  setDelivery,
  setDeliveryCost,
  setPaySystem,
  clearPaySystem,
  setDeliveryDetails,
  setUseBonuses,
  clearDeliveryDetails,
  setAmount,
  clearAmount,
  clear,
  setSubscribe,
  setSavedLocations,
  setSelectedSavedLocation,
  doCheckout,
  setFormError,
  clearFormErrors,
  updatePaySystemPayload,
  setGiftCards,
  setSellersInfoHide,
  setSellersInfoShow,
  setPaySystemsList,
};
