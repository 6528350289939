import {
  ArticoliPhoneWithIcon,
  PhoneWithIcon,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getHeaderPhoneWithIcon = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return ArticoliPhoneWithIcon;
    default:
      break;
  }
  return PhoneWithIcon;
};

export default getHeaderPhoneWithIcon;
