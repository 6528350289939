import getCities from './get-cities';
import fetchCountries from './fetch-countries';
import getMetro from './get-metro';
import getDistrics from './get-districts';
import getCityCladrId from './get-city-cladr-id';
import getStreetCladrSuggestions from './get-street-cladr-suggestions';

export default {
  getCities,
  fetchCountries,
  getMetro,
  getDistrics,
  getCityCladrId,
  getStreetCladrSuggestions,
};
