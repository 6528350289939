import actionTypes from '../constants/action-types';

const initialData = {
  countries: [],
  cities: [],
  metroStations: [],
  districts: [],
};

const locationData = (state = initialData, action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.SET_COUNTRIES: {
      const { payload } = action;
      return {
        ...state,
        countries: payload,
      };
    }
    case actionTypes.SET_CITIES: {
      const { payload } = action;
      return {
        ...state,
        cities: payload,
      };
    }
    case actionTypes.SET_DISTRICTS: {
      const { payload } = action;
      return {
        ...state,
        districts: payload,
      };
    }
    case actionTypes.SET_METRO_STATIONS: {
      const { payload } = action;
      return {
        ...state,
        metroStations: payload,
      };
    }
    default:
      return state;
  }
};

export default locationData;
