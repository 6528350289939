const cart = (items, step = 2) => {
  const products = items.map(({ gtm }) => gtm);
  return {
    ecommerce: {
      currencyCode: 'RUB',
      checkout: {
        actionField: { step },
        products,
      },
    },
    event: 'GAEvent',
    eventCategory: 'Ecommerce',
    eventAction: `CheckoutStep${step}`,
    nonInter: 'False',
  };
};

export default cart;
