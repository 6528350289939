import errorTypes from './constants/error-types';

class DiscountCard extends Error {
  constructor(message) {
    super(message);
    this.name = errorTypes.DISCOUNT_CARD;
    this.userFriendlyMessage =
      'Упс! Что-то пошло не так. Перегрузите страницу и попробуйте снова.';
    this.isCritical = true;
  }
}

export default DiscountCard;
