import serviceClient from '../../../core/service-client';

const getDistrics = (city, name = null) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.location
    .getDistricts(name ? { city, name } : { city })
    .then((response) => response.json().then(({ data }) => data));
};

export default getDistrics;
