import addProduct from './add-product';
import fetchCart from './fetch-cart';
import removeProduct from './remove-product';
import updateProduct from './update-product';
import decreaseQuantity from './decrease-quantity';
import increaseQuantity from './increase-quantity';

export default {
  addProduct,
  fetchCart,
  removeProduct,
  updateProduct,
  decreaseQuantity,
  increaseQuantity,
};
