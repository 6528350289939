let instance;

const get = () => instance;
const set = (chat) => {
  instance = chat;
};

export default {
  get,
  set,
};
