import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { DropdownContext } from '@internet-boutique-engineering/site-ui';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Row = ({ level, children, hasSubRow }) => {
  const { isOpen } = useContext(DropdownContext);
  const isTopLevel = level === 1;
  const classes = useStyles({
    isOpen,
    isTopLevel,
  });
  const iconClassName = classnames(classes.icon, {
    [`${classes.iconOpen}`]: isOpen,
  });

  return (
    <div className={classes.row}>
      <div>{children}</div>
      {isTopLevel && hasSubRow && <span className={iconClassName}>&nbsp;</span>}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.element.isRequired,
  level: PropTypes.number,
  hasSubRow: PropTypes.bool,
};

Row.defaultProps = {
  level: 1,
  hasSubRow: false,
};

export default Row;
