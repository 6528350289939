import getCurrency from '../../themes/get-currency';

const price = (value = 0) => {
  const parts = Number(value).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const formatedValue = parts.join('.');
  return `${formatedValue} ${getCurrency()}`;
};

export default price;
