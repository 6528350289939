import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import user from '../../../core/user';

const useConfirmOrder = () => {
  const history = useHistory();
  const { isAuthentificated } = user.hooks.useUser();
  const path = isAuthentificated ? '/cart/checkout/' : '/personal/order/make/';

  const onConfirmOrder = useCallback(() => {
    if (isAuthentificated) {
      history.push(path);
    } else {
      window.location.href = path;
    }
  }, [isAuthentificated, history]);

  return {
    path,
    onConfirmOrder,
    isAuthentificated,
  };
};

export default useConfirmOrder;
