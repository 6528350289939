import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DiscountCard,
  CollapsedBlock,
  Error,
} from '@internet-boutique-engineering/site-ui';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const DiscountCardBlock = ({ onSetDiscount, discountCard, error }) => {
  const classes = useStyles();
  const [isDiscountBlockVisible, setDiscoutBlockVisibility] = useState(true);
  const switchDiscountBlock = useCallback(
    () => setDiscoutBlockVisibility(!isDiscountBlockVisible),
    [isDiscountBlockVisible]
  );
  return (
    <div className={classes.wrapper}>
      <CollapsedBlock
        label="Семейная карта"
        appearance={isDiscountBlockVisible ? 'open' : 'closed'}
        // eslint-disable-next-line no-alert
        onClick={switchDiscountBlock}
      >
        <div className={classes.discountCard}>
          <DiscountCard
            onSet={onSetDiscount}
            card={discountCard || {}}
            hasError={error}
            captions={{
              placeholder: 'Номер карты',
              buttonLabel: 'Активировать',
              descriptions: process.env.DISCOUNT_CARD_INVITE_TEXT,
            }}
          />
        </div>
        {error && <Error>{error}</Error>}
      </CollapsedBlock>
    </div>
  );
};

DiscountCardBlock.propTypes = {
  onSetDiscount: PropTypes.func.isRequired,
  discountCard: PropTypes.shape({
    number: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
  }),
  error: PropTypes.string,
};
DiscountCardBlock.defaultProps = {
  discountCard: null,
  error: null,
};

export default DiscountCardBlock;
