import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PaymentIframe = ({ url }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.cover}></div>
      <div className={classes.modal}>
        <iframe className={classes.iframe} src={url} />
      </div>
    </React.Fragment>
  );
};

PaymentIframe.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PaymentIframe;
