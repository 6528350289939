import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TwoStateControl } from '@internet-boutique-engineering/site-ui';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Subscribe = ({ isSubscribed, onSibscribe }) => {
  const classes = useStyles();
  return (
    <div className={classes.subscribe}>
      <TwoStateControl
        type="checkbox"
        checked={isSubscribed}
        onChange={onSibscribe}
        name="subscribe"
        value={true}
      >
        Подписаться на рассылку
      </TwoStateControl>
    </div>
  );
};

Subscribe.propTypes = {
  isSubscribed: PropTypes.bool,
  onSibscribe: PropTypes.func,
};
Subscribe.defaultProps = {
  isSubscribed: false,
  onSibscribe: Function.prototype,
};

export default Subscribe;
