const groupBeSellers = (items) => {
  const sellers = new Map();
  items.forEach((item) => {
    const { seller } = item;
    if (!seller) return;
    let sellerData = sellers.get(seller);
    if (!sellerData) {
      sellerData = {
        seller,
        items: [],
      };
    }
    sellerData.items.push(item);
    sellers.set(seller, sellerData);
  });

  return [...sellers.values()];
};

export default groupBeSellers;
