import React from 'react';
import operations from '../operations';

// eslint-disable-next-line react/display-name
const withModal = (Component) => (props) => {
  const onClickClose = () => {
    operations.hideModal();
  };

  return <Component onClose={onClickClose} {...props} />;
};

export default withModal;
