const subscribeStyles = ({ separatorColor, fontFamily, mobileMediaQuery }) => ({
  subscribe: {
    fontFamily,
    padding: [20, 0, 0, 73],
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: separatorColor,
    [`${mobileMediaQuery}`]: {
      padding: [20, 0, 0, 10],
    },
  },
});

export default subscribeStyles;
