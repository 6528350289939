import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import getPopmechanicEmbed from '../../../core/configuration/selectors/get-popmechanic-embed';
import getPopmechanicUrl from '../../../core/configuration/selectors/get-popmechanic-url';
import userSelectors from '../../../core/user/selectors';
import styles from './styles';
import setAppStatusBusy from '../../common/actions/set-app-status-busy';
import fetchCart from '../../cart/operations/fetch-cart';
import cartActions from '../../cart/actions';
import setAppStatusIdle from '../../common/actions/set-app-status-idle';

const useStyles = createUseStyles(styles);

const RecommendationContainer = () => {
  const classes = useStyles();
  const popmechanicEmbed = useSelector(getPopmechanicEmbed);
  const popmechanicUrl = useSelector(getPopmechanicUrl);
  const { bxSmSaleId } = useSelector(userSelectors.getUser);

  const initPopmechanic = useCallback(() => {
    const script = document.createElement('script');
    script.src = popmechanicUrl;

    document.body.appendChild(script);
  }, [popmechanicUrl]);

  useEffect(() => {
    initPopmechanic();
  }, []);

  const dispatch = useDispatch();

  const addRef = useRef(null);

  const globalBlock = addRef.current;
  if (globalBlock) {
    const addToCartBlock = globalBlock.querySelectorAll('.product-list-add-to-cart-js');
    if (addToCartBlock && addToCartBlock.length) {
      addToCartBlock.forEach((el) => {
        el.addEventListener('click', () => {
          const formData = new FormData();

          const dataSetArr = Object.keys(el.dataset);

          dataSetArr.forEach((item) => {
            formData.append(item, el.dataset[item]);
          });

          try {
            dispatch(setAppStatusBusy());
            fetch('/personal/cart/add-parent-to-cart.php', {
              method: 'POST',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
              body: formData,
            })
              .then((res) => res.json())
              .then((response) => {
                dispatch(setAppStatusIdle());
                if (response.success) {
                  dispatch(setAppStatusBusy());
                  fetchCart(bxSmSaleId).then((data) => {
                    dispatch(cartActions.setCart(data));
                    dispatch(setAppStatusIdle());
                  });
                }
              });
          } catch (error) {
            dispatch(setAppStatusIdle());
            console.log(error);
          }
        });
      });
    }
  }
  return (
    <div
      ref={addRef}
      className={classes.widget}
      data-popmechanic-embed={popmechanicEmbed}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'left' }}>
        <div
          className="popmechanic-item-card-wrapper tns-item tns-slide-active"
          style={{ position: 'relative', overflow: 'hidden' }}
          data-click-track="0"
          id="tns1-item0"
        >
          <div className="popmechanic-item-card">
            <div className="popmechanic-item-card__image">
              <a
                className="popmechanic-item-card__info"
                href="https://articoli.ru/product/parfyumernaya-voda-eyes-closed-50-ml-668237/?s=UNI"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://reco-imgproxy.mindbox.ru/ks-NSY0UAtEvX8Kcl1VfaGxXr38YYDCESZ_P0FXA2js/fit/600/600/ce/0/aHR0cHM6Ly9hcnRpY29saS5ydS91cGxvYWQvaWJsb2NrLzNhZC8zYWQ0YjQ4MTAyYmQyYTJkZWIyYmNjMmZkZGE1ODc1Zl82NzZfOTAwLmpwZw"
                  alt=""
                />
              </a>
            </div>
            <div className="popmechanic-item-card__name-block">
              <p className="popmechanic-item-card__brand">Byredo</p>
              <a className="popmechanic-item-card__info popmechanic-item-card__title">
                Парфюмерная вода Eyes Closed, 50 мл
              </a>
            </div>
            <div className="popmechanic-item-card__block-price">
              <div className="popmechanic-item-card__price">
                <span className="popmechanic-item-card__price-value">24&nbsp;750</span>
                <span className="popmechanic-item-card__price-currency"></span>
              </div>
            </div>
            <a
              rel="nofollow"
              href="/catalog/quick-view.php?ELEMENT_ID=7886857"
              className="quick-view js-fancy-ajax analitics-click-js js-gtm-quick-view-link"
              data-type="quick_view_target"
            >
              <span>Быстрый просмотр</span>
            </a>
            <div
              className="list-add-to-cart-block js-add-to-cart-quantity-block"
              style={{ width: '100%', height: '40px', background: 'red' }}
            >
              <a
                type="button"
                data-href="/personal/cart/add-parent-to-cart.php"
                rel="nofollow"
                className="product-list-add-to-cart-js
            add-to-cart-icon analitics-click-js js-gtm-to-cart-link js-gtm-simple"
                data-type="dobavit_korzinu"
                data-parent="7886857"
                data-offer="68382049"
                data-quantity="1"
                data-gtm-action="add"
                data-gtm-category="Conversions"
                data-gtm-categoryid="7886857"
                data-gtm-label="cart"
                style={{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  background: 'green',
                }}
              ></a>
            </div>
          </div>
        </div>
        <div
          className="popmechanic-item-card-wrapper tns-item"
          style={{ position: 'relative', overflow: 'hidden' }}
          data-click-track="14"
          aria-hidden="true"
          tabIndex="-1"
        >
          <div className="popmechanic-item-card">
            <div className="popmechanic-item-card__image">
              <a
                className="popmechanic-item-card__info"
                href="https://articoli.ru/product/parfyumernaya-voda-allegra-fiori-d-amore-50-ml-615215/?s=UNI"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://reco-imgproxy.mindbox.ru/3Z38KiOgr5TtRM4t2UxG-1RVAXilLckutGw2qts-b-Q/fit/600/600/ce/0/aHR0cHM6Ly9hcnRpY29saS5ydS91cGxvYWQvaWJsb2NrLzU2YS81NmExY2VlZGM3Zjc1NzY0Y2FhMWU2ZTBlNjFkOGJmOV82NzZfOTAwLmpwZw"
                  alt=""
                />
              </a>
            </div>
            <div className="popmechanic-item-card__name-block">
              <p className="popmechanic-item-card__brand">BVLGARI</p>
              <a className="popmechanic-item-card__info popmechanic-item-card__title">
                {"Парфюмерная вода Allegra Fiori d'Amore, 50 мл"}
              </a>
            </div>
            <div className="popmechanic-item-card__block-price">
              <div className="popmechanic-item-card__price">
                <span className="popmechanic-item-card__price-value">16&nbsp;400</span>
                <span className="popmechanic-item-card__price-currency"></span>
              </div>
            </div>
            <div
              className="list-add-to-cart-block js-add-to-cart-quantity-block"
              style={{ width: '100%', height: '40px', background: 'red' }}
            >
              <a
                type="button"
                data-href="/personal/cart/add-parent-to-cart.php"
                rel="nofollow"
                className="product-list-add-to-cart-js add-to-cart-icon
                analitics-click-js js-gtm-to-cart-link js-gtm-simple"
                data-type="dobavit_korzinu"
                data-parent="6518419"
                data-offer="770691"
                data-quantity="1"
                data-gtm-action="add"
                data-gtm-category="Conversions"
                data-gtm-categoryid="6518419"
                data-gtm-label="cart"
                style={{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  background: 'green',
                }}
              ></a>
            </div>
            <a
              rel="nofollow"
              href="/catalog/quick-view.php?ELEMENT_ID=6518419"
              className="quick-view js-fancy-ajax analitics-click-js js-gtm-quick-view-link"
              data-type="quick_view_target"
            >
              <span>Быстрый просмотр</span>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RecommendationContainer;
