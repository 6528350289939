import actionTypes from '../constants/action-types';

const token = (state = {}, action) => {
  const { type } = action;
  if (type !== actionTypes.SET_AUTH_TOKEN) return state;

  const { payload } = action;
  return payload;
};

export default token;
