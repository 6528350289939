const modalStyles = ({
  extraLightColor,
  fontFamily,
  primaryTextColor,
  dropDownBorder,
  borderRadius,
  mobileMediaQuery,
}) => ({
  cover: {
    backgroundColor: extraLightColor,
    position: 'fixed',
    zIndex: 4,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.9,
  },
  modal: {
    backgroundColor: extraLightColor,
    fontFamily,
    position: 'fixed',
    zIndex: 999,
    top: '50%',
    left: '50%',
    color: primaryTextColor,
    borderColor: dropDownBorder,
    borderWidth: 10,
    borderStyle: 'solid',
    borderRadius,
    transform: 'translate(-50%, -50%)',
    padding: [15, 30, 15, 30],
    minWidth: 400,
    boxSizing: 'border-box',
    [`${mobileMediaQuery}`]: {
      minWidth: '100%',
    },
  },
  button: {
    margin: [0, 'auto'],
    display: 'block',
  },
});

export default modalStyles;
