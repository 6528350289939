import selectors from '../selectors';
import actions from '../actions';
import actionTypes from '../constants/action-types';
import operations from '../operations';
import segment, { eventTypes } from '../../../modules/analytic/segment';

const update = (store) => (next) => (action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.INITIATE_USER_UPDATE: {
      segment.track(eventTypes.PERSONAL_DATA_SAVE_INITIATED);
      const state = store.getState();
      const userInfo = selectors.getUser(state);
      const { name, lastname, email, phone } = payload;
      const {
        id,
        name: oldName,
        lastname: oldLastname,
        email: oldEmail,
        phone: oldPhone,
      } = userInfo;
      operations
        .updateUser(id, {
          name,
          lastname,
          email,
          phone,
        })
        .catch((error) => {
          store.dispatch(actions.updateUser(oldName, oldLastname, oldEmail, oldPhone));
          throw error;
        });
      segment.track(eventTypes.PERSONAL_DATA_SAVE_SUCCESS);
      next(action);
      store.dispatch(actions.updateUser(name, lastname, email, phone));
      break;
    }
    default:
      next(action);
  }
};

export default update;
