import reducers from './reducers';
import constants from './constants';
import actions from './actions';
import selectors from './selectors';
import middlewares from './middelwares';
import operations from './operations';

export default {
  reducers,
  constants,
  actions,
  selectors,
  middlewares,
  operations,
};
