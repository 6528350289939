import {
  ProductCardHeader,
  ArticoliProductCardHeader,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getProductCardHeader = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return ArticoliProductCardHeader;
    default:
      break;
  }
  return ProductCardHeader;
};

export default getProductCardHeader;
