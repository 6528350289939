import snakecase from 'lodash.snakecase';

const structureToSnake = (obj) => {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.map((item) => structureToSnake(item));
  }
  if (typeof obj === 'object') {
    const keys = Object.keys(obj);
    return keys.reduce(
      (newObject, key) => ({
        ...newObject,
        [`${snakecase(key)}`]: structureToSnake(obj[key]),
      }),
      {}
    );
  }

  return obj;
};

export default structureToSnake;
