let serviceClient;

const get = () => serviceClient;
const set = (client) => {
  serviceClient = client;
};

export default {
  get,
  set,
};
