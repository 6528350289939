const myTargetCart = (data) => {
  const { items, price } = data.reduce(
    (acc, { parentId, price: itemPrice }) => {
      acc.price += itemPrice;
      acc.items.push(parentId);
      return acc;
    },
    {
      price: 0,
      items: [],
    }
  );

  return [items, price];
};

export default myTargetCart;
