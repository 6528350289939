import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import errorHandler from '../handlers/error-handler';
import getModalInfo from '../../../themes/get-modal-info';
import actions from '../actions';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error) {
    errorHandler(error);
  }

  render() {
    const { message, userFriendlyMessage, statusText, action = {} } = this.props.error;
    let errorMessage;
    if (message) {
      errorMessage = message;
    } else if (userFriendlyMessage) {
      errorMessage = userFriendlyMessage;
    } else if (statusText) {
      errorMessage = statusText;
    }

    const Modal = getModalInfo();

    return (
      <React.Fragment>
        {errorMessage && <Modal message={errorMessage} action={action} />}
        {this.props.children}
      </React.Fragment>
    );
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
    statusText: PropTypes.string,
    critical: PropTypes.bool,
    userFriendlyMessage: PropTypes.string,
    action: PropTypes.shape({
      label: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  }),
  clearError: PropTypes.func,
};
ErrorBoundary.defaultProps = {
  error: {},
  clearError: Function.prototype,
};

const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(actions.clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
