const itemsCardStyles = ({
  cartListSeparatorColor,
  cartListPadding,
  mobileMediaQuery,
}) => ({
  list: {
    margin: 0,
    padding: cartListPadding,
  },
  item: {
    margin: [22, 0, 0, 0],
    padding: [22, 0, 0, 0],
    listStyle: 'none',
    borderTop: '1px solid',
    borderTopColor: cartListSeparatorColor,
    '&:first-child': {
      margin: 0,
    },
  },
  listHeader: {
    [`${mobileMediaQuery}`]: {
      display: 'none',
    },
  },
  emptyCartBlock: {
    textAlign: 'center',
    maxWidth: '90%',
    margin: 'auto',
  },
  emptyCartText: {
    padding: '60px 0',
    fontSize: '36px',
  },
});

export default itemsCardStyles;
