import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { PromocodeBlock, TwoStateControl } from '@internet-boutique-engineering/site-ui';
import styles from './styles';

import DiscountCardBlock from '../../../discounts/components/discount-card';
import UnauthorizedContainer from '../../../discounts/containers/unauthorized';

const useStyles = createUseStyles(styles);

const Total = ({
  isAuthentificated,
  errorDiscountCard,
  discountCard,
  onSetDiscountCard,
  promocode,
  discountPromoCodePercent,
  errorPromocode,
  onSetPromocode,
  hiddenDelivery,
  totalPrice,
  delivery,
  discount,
  discountCardPercent,
  discountCardPrice,
  discountPromoCode,
  finalTotalPrice,
  bonuses,
  bonusToAdd,
  isBonusesAvailable,
  isAllowBonuses,
  isUseBonuses,
  onCheckedBonuses,
  maxBonusesToUse,
  maxBonusesToUsePrice,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.globalWrapper}>
      <React.Fragment>
        {isAuthentificated ? (
          <React.Fragment>
            <DiscountCardBlock
              error={errorDiscountCard}
              discountCard={discountCard}
              onSetDiscount={onSetDiscountCard}
            />
            <PromocodeBlock
              defaultValue={promocode}
              promocodeOnly={promocode && discountPromoCodePercent >= 10}
              error={errorPromocode}
              onSetDiscount={onSetPromocode}
            />
          </React.Fragment>
        ) : (
          <UnauthorizedContainer />
        )}
      </React.Fragment>

      <div className={classes.wrapper}>
        <div className={classes.cardTotal}>
          <span className={classes.label}>Стоимость товаров:</span>
          <span className={classes.value}>{totalPrice}</span>
        </div>

        {hiddenDelivery || (
          <div className={classes.row}>
            <span className={classes.label}>Доставка:</span>
            <span className={classes.value}>{delivery}</span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.label}>Скидка:</span>
          <span className={classes.value}>{discount}</span>
        </div>
        {discountCardPercent > 0 && discountCardPrice && (
          <div className={classes.row}>
            <span className={classes.label}>
              Скидка по карте - {discountCardPercent}%:
            </span>
            <span className={classes.value}>-{discountCardPrice}</span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.label}>Скидка по промокоду:</span>
          <span className={classes.label}>{discountPromoCode}</span>
        </div>

        <div className={classes.checkoutTotalGroup}>
          <span className={classes.label}>Всего к оплате:</span>
          <span className={classes.label}>{finalTotalPrice}</span>
        </div>

        {isAllowBonuses && (
          <>
            <div className={classes.bonuse}>
              <div className={classes.row}>
                <span className={classes.label}>Бонусы на счету:</span>
                <span className={classes.value}>{bonuses}</span>
              </div>
              <span className={classes.legend}>
                Оплата бонусами возможна при получении заказа
              </span>
            </div>
            {bonusToAdd && (
              <div className={classes.bonuse}>
                <div className={classes.row}>
                  <span className={classes.label}>Бонусы после покупки:</span>
                  <span className={classes.value}>{bonusToAdd}</span>
                </div>
              </div>
            )}
            {isBonusesAvailable && (
              <div className={classes.useBonuses}>
                <TwoStateControl
                  type="checkbox"
                  checked={isUseBonuses}
                  onChange={onCheckedBonuses}
                  name="useBonuses"
                  value={maxBonusesToUse}
                >
                  <React.Fragment>
                    Использовать {maxBonusesToUsePrice} из {bonuses} бонусных баллов для
                    оплаты до 50% от суммы заказа
                  </React.Fragment>
                </TwoStateControl>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Total.propTypes = {
  isAuthentificated: PropTypes.bool.isRequired,
  hiddenDelivery: PropTypes.bool,
  totalPrice: PropTypes.string.isRequired,
  delivery: PropTypes.string,
  discount: PropTypes.string,
  discountCardPercent: PropTypes.number,
  discountCardPrice: PropTypes.string,
  discountPromoCode: PropTypes.string,
  finalTotalPrice: PropTypes.string.isRequired,
  bonuses: PropTypes.string.isRequired,
  bonusToAdd: PropTypes.string,
  isBonusesAvailable: PropTypes.bool,
  isAllowBonuses: PropTypes.bool,
  isUseBonuses: PropTypes.bool,
  onCheckedBonuses: PropTypes.func,
  maxBonusesToUse: PropTypes.number,
  maxBonusesToUsePrice: PropTypes.string,
  errorDiscountCard: PropTypes.string,
  discountCard: PropTypes.shape({
    number: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
  }),
  onSetDiscountCard: PropTypes.func,
  promocode: PropTypes.string,
  discountPromoCodePercent: PropTypes.number,
  errorPromocode: PropTypes.string,
  onSetPromocode: PropTypes.func,
};

Total.defaultProps = {
  hiddenDelivery: false,
  delivery: null,
  discount: null,
  discountCardPercent: null,
  discountCardPrice: null,
  discountPromoCode: null,
  bonusToAdd: null,
  isBonusesAvailable: null,
  isUseBonuses: null,
  isAllowBonuses: null,
  onCheckedBonuses: Function.prototype,
  maxBonusesToUse: null,
  maxBonusesToUsePrice: null,
  errorDiscountCard: null,
  discountCard: null,
  onSetDiscountCard: Function.prototype,
  promocode: null,
  discountPromoCodePercent: null,
  errorPromocode: null,
  onSetPromocode: Function.prototype,
};

export default Total;
