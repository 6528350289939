import actionTypes from '../constants/action-types';
import sellersInfoStatus from '../constants/sellers-info-status';

const sellersInfo = (state = sellersInfoStatus.HIDE, action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.SELLERS_INFO_SHOW:
      return sellersInfoStatus.SHOW;
    case actionTypes.SELLERS_INFO_HIDE:
      return sellersInfoStatus.HIDE;
    default:
      return state;
  }
};

export default sellersInfo;
