const helpMenuStyles = ({ desktopMediaQuery }) => ({
  dropdown: {
    [desktopMediaQuery]: {
      display: 'none',
    },
  },
  button: {
    marginBottom: 15,
  },
  lastButton: {
    marginBottom: 30,
  },
});

export default helpMenuStyles;
