import serviceClient from '../../../core/service-client';

const getMetro = (city) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.location
    .getMetro({ city })
    .then((response) => response.json().then(({ data }) => data));
};

export default getMetro;
