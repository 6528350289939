// import yandexMetrica from '../../../core/yandex-metrica';
import getYandexMetricaId from '../../../core/configuration/selectors/get-yandex-metrica-id';
import storeInstance from '../../../store/connected-store';

export default {
  identify: () => {
    const store = storeInstance.get();
    const state = store.getState();
    const id = getYandexMetricaId(state);

    // if (id) {
    //   yandexMetrica.get()(id, 'init', {
    //     clickmap: true,
    //     trackLinks: true,
    //     accurateTrackBounce: true,
    //     webvisor: true,
    //     ecommerce: 'dataLayer',
    //   });
    // }
  },
};
