import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

import getProgressBar from '../../../../themes/get-progress-bar';

const useStyles = createUseStyles(styles);

const ProgressBar = () => {
  const classes = useStyles();

  const Progress = getProgressBar();

  return <Progress className={classes.bar} />;
};

export default ProgressBar;
