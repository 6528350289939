const menu = [
  {
    label: 'Женское',
    subMenu: [
      {
        label: 'Аксессуары',
        url: 'https://boscooutlet.ru/catalog/woman/aksessuary/',
      },
      {
        label: 'Обувь',
        url: 'https://boscooutlet.ru/catalog/woman/obuv/',
      },
      {
        label: 'Одежда',
        url: 'https://boscooutlet.ru/catalog/woman/odezhda/',
      },
      {
        label: 'Спортивные товары',
        url: 'https://boscooutlet.ru/catalog/woman/sport-zhenshchiny/',
      },
      {
        label: 'Сумки',
        url: 'https://boscooutlet.ru/catalog/woman/sumki/',
      },
      {
        label: 'Бренды',
        url: 'https://boscooutlet.ru/brands/woman/',
      },
    ],
  },
  {
    label: 'Мужское',
    subMenu: [
      {
        label: 'Аксессуары',
        url: 'https://boscooutlet.ru/catalog/men/aksessuary/',
      },
      {
        label: 'Обувь',
        url: 'https://boscooutlet.ru/catalog/men/obuv_1/',
      },
      {
        label: 'Спортивные товары',
        url: 'https://boscooutlet.ru/catalog/men/sport-muzhchiny/',
      },
      {
        label: 'Сумки',
        url: 'https://boscooutlet.ru/catalog/men/sumki_5/',
      },
      {
        label: 'Бренды',
        url: 'https://boscooutlet.ru/brands/men/',
      },
    ],
  },
  {
    label: 'Детское',
    subMenu: [
      {
        label: 'Для девочек',
        url: 'https://boscooutlet.ru/catalog/detskoe/devochki/',
      },
      {
        label: 'Для мальчиков',
        url: 'https://boscooutlet.ru/catalog/detskoe/malchiki/',
      },
      {
        label: 'Игрушки',
        url: 'https://boscooutlet.ru/catalog/detskoe/igrushki/',
      },
      {
        label: 'Новорожденные',
        url: 'https://boscooutlet.ru/catalog/detskoe/grudnichki/',
      },
      {
        label: 'Бренды',
        url: 'https://boscooutlet.ru/brands/detskoe/',
      },
    ],
  },
  {
    label: 'Спорт',
    subMenu: [
      {
        label: 'Детское',
        url: 'https://boscooutlet.ru/catalog/detskoe/devochki/bosco-sport.html',
      },
      {
        label: 'Женское',
        url: 'https://boscooutlet.ru/catalog/woman/sport-zhenshchiny/',
      },
      {
        label: 'Мужское',
        url: 'https://boscooutlet.ru/catalog/men/sport-muzhchiny/',
      },
    ],
  },
  {
    label: 'Подарки',
    subMenu: [
      {
        label: 'Для женщин',
        url: 'https://boscooutlet.ru/catalog/woman/aksessuary/?ONLY_GIFTS=Y',
      },
      {
        label: 'Женское',
        url: 'https://boscooutlet.ru/catalog/men/aksessuary/?ONLY_GIFTS=Y',
      },
    ],
  },
  {
    label: 'Персональный стилист',
    url: 'https://boscooutlet.ru/anketa/',
  },
  {
    label: 'Адреса магазинов',
    url: 'https://boscooutlet.ru/shops-landing/',
  },
  {
    label: 'Мероприятия',
    url: 'https://boscooutlet.ru/events/',
  },
  {
    label: 'Способы оплаты',
    url: 'https://boscooutlet.ru/payment.html',
  },
  {
    label: 'Доставка',
    url: 'https://boscooutlet.ru/delivery.html',
  },
  {
    label: 'Возврат товара',
    url: 'https://boscooutlet.ru/vozvrat.html',
  },
];

export default menu;
