import actionTypes from '../constants/action-types';

const setAuthToken = (accessToken, refreshToken, iat) => ({
  type: actionTypes.SET_AUTH_TOKEN,
  payload: {
    accessToken,
    refreshToken,
    iat,
  },
});

export default setAuthToken;
