import checkout from './checkout';
import amount from './amount';
import savedLocations from './saved-location';
import formErrors from './form-errors';
import giftCards from './gift-cards';
import sellersInfo from './sellers-info-status';

export default {
  checkout,
  amount,
  savedLocations,
  formErrors,
  giftCards,
  sellersInfo,
};
