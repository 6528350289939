const remove = (product) => ({
  ecommerce: {
    currencyCode: 'RUB',
    remove: {
      products: [product.gtm],
    },
  },
});

export default remove;
