import { BoscoNewProductCardGroup } from '@internet-boutique-engineering/site-ui';
import ProductCardGroup from '../modules/cart/containers/items-group';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getProductCardGroup = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewProductCardGroup;
    default:
      break;
  }

  return ProductCardGroup;
};

export default getProductCardGroup;
