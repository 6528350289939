import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import checkout from '../../checkout';
import actions from '../actions';
import selectors from '../selectors';
import operations from '../operations';

const usePromoCode = () => {
  const promocode = useSelector(selectors.getPromocode);
  const amount = useSelector(checkout.selectors.getAmount);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const onSetPromocode = useCallback(
    (code) => {
      setIsFetching(true);
      operations
        .setPromocode(code)
        .then((data) => {
          dispatch(actions.setPromocode(data));
        })
        .catch(({ message }) => {
          setError(message);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [dispatch]
  );

  const { code } = promocode;
  const { discountPromoCodePercent } = amount;

  return {
    code,
    error,
    isFetching,
    discountPromoCodePercent,
    onSetPromocode,
  };
};

export default usePromoCode;
