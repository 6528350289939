import store from '../../../store/connected-store';
import serviceClient from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';
import structureToSnake from '../../../core/helpers/structure-to-snake';
import CustomError from '../../../core/errors/custom-error';
import ValidationError from '../../../core/errors/validation-error';
import errorsActions from '../../../core/errors/actions';

const checkout = (dataSet) => {
  const serviceClientAPI = serviceClient.get();
  const userErrorAction = {
    label: 'OK',
    handler: () => {
      store.get().dispatch(errorsActions.clearError());
    },
  };
  return serviceClientAPI.checkout
    .doCheckout(structureToSnake(dataSet))
    .then((response) => {
      if (response.ok !== true) {
        return response.json().then((errorData) => {
          const { errors } = errorData;
          if (!errors) throw new CustomError(response.statusText, userErrorAction);

          const [error] = errors;
          if (!error) throw new CustomError(response.statusText, userErrorAction);

          const { title } = error;
          if (title && response.status === 400) {
            throw new ValidationError(error.title, userErrorAction);
          }

          throw new CustomError(error.title || response.statusText, userErrorAction);
        });
      }
      return new Promise((resolve) => {
        response.json().then(({ data }) => resolve(structureToCamel(data)));
      });
    });
};

export default checkout;
