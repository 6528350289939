import actionTypes from '../constants/action-types';

const serviceConfiguration = (state = null, action) => {
  const { type } = action;
  if (type !== actionTypes.SET_SERVICE_CONFIGURATION) return state;

  return action.payload;
};

export default serviceConfiguration;
