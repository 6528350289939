import ga from './ga';
import dataLayer from './data-layer';
import handlers from './handlers';
import segment from './segment';
import myTarget from './mytarget';
// import facebookPixel from './facebook-pixel';
import criteo from './criteo';
import yandexMetrica from './yandex-metrica';

export default {
  ga,
  dataLayer,
  handlers,
  segment,
  myTarget,
  // facebookPixel,
  criteo,
  yandexMetrica,
};
