import React from 'react';
import { createUseStyles } from 'react-jss';

import { BoscoNewDropdownHelp, Button } from '@internet-boutique-engineering/site-ui';
import useHelpMenu from '../../../hooks/use-help-menu';

import styles from './styles';

const useStyles = createUseStyles(styles);

const HelpMenu = () => {
  const classes = useStyles();
  const { mail, openChat, openCall } = useHelpMenu();

  const callButton = (
    <Button
      className={classes.button}
      customWidth="100%"
      inverted={true}
      onClick={openCall}
      href="https://zingaya.com/widget/63b367d3c5d745cf9656a37bf3e462bf"
    >
      Звонок с сайта
    </Button>
  );

  const consultationButton = (
    <Button
      className={classes.button}
      customWidth="100%"
      inverted={true}
      onClick={openChat}
    >
      Online консультант
    </Button>
  );

  const emailSendButton = (
    <Button
      customWidth="100%"
      inverted={true}
      href={`mailto:${mail}`}
      className={classes.lastButton}
    >
      Написать письмо
    </Button>
  );

  const menu = [
    {
      label: 'item 1',
      element: callButton,
    },
    {
      label: 'item 2',
      element: consultationButton,
    },
    {
      label: 'item 3',
      element: emailSendButton,
    },
    {
      label: 'Способы оплаты',
      href: '/payment.html',
    },
    {
      label: 'Доставка',
      href: '/delivery.html',
    },
    {
      label: 'Возврат товара',
      href: '/vozvrat.html',
    },
    {
      label: 'Система привилегий',
      href: '/magic-cards.html',
    },
  ];

  return <BoscoNewDropdownHelp className={classes.dropdown} menu={menu} />;
};

export default HelpMenu;
