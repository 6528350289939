import actionTypes from '../constants/action-types';

const updateUser = (name, lastname, email, phone) => ({
  type: actionTypes.UPDATE_USER,
  payload: {
    name,
    lastname,
    email,
    phone,
  },
});

export default updateUser;
