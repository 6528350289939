import React from 'react';
import { useSelector } from 'react-redux';
import common from '../../common';
import getBuyCartBlock from '../../../themes/get-buy-cart-block';
import getCart from '../../cart/selectors/get-cart';
import isBoscoCafeLimitSatisfied from '../../cart/helpers/is-bosco-cafe-limit-satisfied';
import isBoscoCafeTimeSutisfied from '../../cart/helpers/is-bosco-cafe-time-satisfied';
import useCheckout from '../hooks/use-checkout';

const MakeCheckoutContainer = () => {
  const { appStatus, onCheckout } = useCheckout();

  const cart = useSelector(getCart);

  const isLimitSatisfied = isBoscoCafeLimitSatisfied(cart);
  const isTimeSatisfied = isBoscoCafeTimeSutisfied(cart);

  const isDisabled = !isLimitSatisfied || !isTimeSatisfied;
  const disabledText = isTimeSatisfied
    ? 'Стоимость товаров BOSCOCAFE должна быть выше 4999 руб'
    : 'Товары BOSCOCAFE можно заказать с 10 до 18 часов';

  const BuyCartBlock = getBuyCartBlock();

  if (!cart.length) return null;

  return (
    <BuyCartBlock
      label="Оформить заказ"
      onCheckout={onCheckout}
      disabled={appStatus === common.constants.appStatuses.BUSY || isDisabled}
      limitationText={isDisabled && disabledText}
    />
  );
};

export default MakeCheckoutContainer;
