import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectors from '../../selectors';
import actions from '../../actions';
import getSubscribe from '../../../../themes/get-subscribe';

const SubscribeContainer = () => {
  const isSubscribed = useSelector(selectors.getWantToSubscribeStatus);
  const despatch = useDispatch();

  const onSubscribe = useCallback(({ checked }) => {
    despatch(actions.setSubscribe(checked));
  });

  const Subscribe = getSubscribe();
  return <Subscribe isSubscribed={isSubscribed} onSibscribe={onSubscribe} />;
};

export default SubscribeContainer;
