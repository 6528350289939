import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import liveChat from '../../livechat';
import getEmail from '../../../core/configuration/selectors/get-email';

const useHelpMenu = () => {
  const mail = useSelector(getEmail);

  const openChat = useCallback(() => {
    const chat = liveChat.instance.get();
    chat.openChat();
  }, []);

  const openCall = useCallback((e) => {
    e.preventDefault();
    const url = `${e.target.href}?referrer=${escape(window.location.href)}`;
    window.open(
      url,
      '_blank',
      'width=236,height=220,resizable=no,toolbar=no,menubar=no,location=no,status=no'
    );
  }, []);

  return {
    mail,
    openChat,
    openCall,
  };
};

export default useHelpMenu;
