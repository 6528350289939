import actions from './actions';
import constants from './constants';
import reducers from './reducers';
import selectors from './selectors';
import operations from './operations';
import middlewares from './middlewares';

export default {
  actions,
  constants,
  reducers,
  selectors,
  operations,
  middlewares,
};
