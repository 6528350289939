const totalStyles = ({
  extraEmphasisTextSize,
  extraLowEmphasisTextSize,
  lowEmphasisTextSize,
  baseTextSize,
  asidePattern,
  lineHeight,
  secondaryTextColor,
  activeTextColor,
  mobileMediaQuery,
  sellerBorderColor,
}) => ({
  wrapper: {
    [`${mobileMediaQuery}`]: {
      padding: '3.5%',
    },
  },
  cardTotal: {
    display: 'flex',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    color: secondaryTextColor,
    lineHeight: 1.8,
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: [0, 0, 1, 0],
    padding: [12, 0, 12, 0],
    margin: [0, 0, 12, 0],
  },
  cardTotalSeller: {
    display: 'flex',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    color: secondaryTextColor,
    lineHeight: 1.8,
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: 0,
    padding: [12, 0, 12, 0],
    margin: [0, 0, 12, 0],
  },
  checkoutTotal: {
    display: 'flex',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    color: activeTextColor,
    fontWeight: 500,
    lineHeight: 1.8,
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: [1, 1, 1, 1],
    padding: [12, '4%', 12, '4%'],
    margin: [12, 0, 0, 0],
  },
  checkoutTotalGroup: {
    display: 'flex',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    color: activeTextColor,
    fontWeight: 500,
    lineHeight: 1.8,
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: [1, 0, 1, 0],
    padding: [12, 0, 12, 0],
    margin: [12, 0, 0, 0],
  },
  checkoutTotalGroupSeller: {
    display: 'flex',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    color: activeTextColor,
    fontWeight: 500,
    lineHeight: 1.8,
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: [1, 0, 0, 0],
    padding: [12, 0, 12, 0],
    margin: [12, 0, 0, 0],
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: extraEmphasisTextSize,
    justifyContent: 'space-between',
    lineHeight: 1.8,
    color: secondaryTextColor,
  },
  bonuse: {
    marginTop: 15,
    '& $row': {
      fontSize: baseTextSize,
      lineHeight,
    },
  },
  useBonuses: {
    margin: [15, 0, 0, 0],
    color: secondaryTextColor,
    '& div': {
      color: secondaryTextColor,
    },
  },
  legend: {
    fontSize: extraLowEmphasisTextSize,
    lineHeight,
  },
  sellerWrapper: {
    position: 'relative',
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: [12, '3%', 12, '3%'],
    margin: [33, 0, 30, 0],
    borderRadius: 5,
    '& h3': {},
  },
  nonSellerWrapper: {
    position: 'relative',
    borderColor: sellerBorderColor,
    borderStyle: 'solid',
    borderWidth: 0,
    padding: [0, '3%', 12, '3%'],
    margin: [0, 0, 30, 0],
    borderRadius: 5,
    '& h3': {},
  },
  sellerTitle: {
    backgroundImage: asidePattern,
    fontSize: lowEmphasisTextSize,
    fontWeight: 'normal',
    position: 'absolute',
    top: 0,
    left: 17,
    margin: 0,
    padding: [0, 10, 0, 10],
    transform: 'translate(0, -50%)',
  },
});

export default totalStyles;
