import selectors from '../selectors';
import discounts from '../../discounts';
import promocodes from '../../promocodes';
import actionTypes from '../constants/action-types';
import cartActionTypes from '../../cart/constants/action-types';
import operations from '../operations';
import actions from '../actions';
import setAppStatusBusy from '../../common/actions/set-app-status-busy';
import setAppStatusIdle from '../../common/actions/set-app-status-idle';

const calculate = (store) => (next) => (action) => {
  const { type } = action;
  next(action);
  switch (type) {
    case promocodes.constants.actionTypes.SET_PROMOCODE:
    case promocodes.constants.actionTypes.CLEAR_PROMOCODE:
    case discounts.constants.actionTypes.SET_CARDS:
    case discounts.constants.actionTypes.CLEAR_CARDS:
    case actionTypes.SET_BATCH_LOCATION:
    case actionTypes.SET_DELIVERY_DETAILS:
    case actionTypes.SET_PAYSYSTEM:
    case actionTypes.SET_DELIVERY:
    case cartActionTypes.SET_CART: {
      const state = store.getState();
      const { code } = promocodes.selectors.getPromocode(state);
      const selectedDiscountCards = discounts.selectors.getDiscountCards(state);
      const [card] = selectedDiscountCards;

      const locationId = selectors.getSelectedLocationId(state);
      const delivery = selectors.getSelectedDeliveryId(state);
      const deliveryPayload = selectors.getSelectedDeliveryDetails(state) || {};
      const paySystem = selectors.getPaySystemId(state);
      const paySystemPayload = selectors.getPaySystemPayload(state) || {};
      const promocode = code;
      const discountCard = card ? card.number : null;

      const { variationId } = deliveryPayload;
      const dataSet = {
        locationId,
        delivery: variationId || delivery,
        deliveryPayload,
        paySystem,
        paySystemPayload,
        promocode,
        discountCard,
      };
      setAppStatusBusy();
      operations
        .calculate(dataSet)
        .then((data) => {
          setAppStatusIdle();
          if (!data) return;
          const { discountPromoCode, cartPrice, delivery: deliveryCost } = data;
          const discountPromoCodePercent =
            discountPromoCode && cartPrice
              ? Math.round((discountPromoCode / cartPrice) * 100)
              : 0;
          store.dispatch(
            actions.setAmount({
              ...data,
              discountPromoCodePercent,
            })
          );
          store.dispatch(actions.setDeliveryCost(deliveryCost));
        })
        .catch(() => {
          setAppStatusIdle();
        });
      break;
    }
    default:
      break;
  }
};

export default calculate;
