import {
  boscoOutletFonts,
  articoliFonts,
  boscoNewFonts,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getFonts = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_SITE_NAME:
      return boscoNewFonts;
    case constants.ARTICOLI_SITE_NAME:
      return articoliFonts;
    case constants.BOSCO_NEW_SITE_NAME:
      return boscoNewFonts;
    case constants.OUTLET_SITE_NAME:
      return boscoOutletFonts;
    default:
      break;
  }

  return boscoNewFonts;
};

export default getFonts;
