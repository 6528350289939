import connectedStore from '../../../store/connected-store';
import selectors from '../selectors';
import updateProduct from './update-product';
import actions from '../actions';
import CustomError from '../../../core/errors/custom-error';
import errorAction from '../../../core/errors/actions';

const increaseQuantity = async (userId, itemId) => {
  const store = connectedStore.get();
  const state = store.getState();
  const items = selectors.getCart(state);

  const item = items.find(({ id }) => id === itemId);
  if (!item) return {};

  const { quantity } = item;
  const newQuantity = quantity + 1;

  store.dispatch(
    actions.updateItem(itemId, {
      quantity: newQuantity,
    })
  );

  return updateProduct(userId, itemId, {
    quantity: newQuantity,
  }).catch(() => {
    store.dispatch(
      actions.updateItem(itemId, {
        ...item,
      })
    );
    throw new CustomError('Уппс! Ошибка обновлении товара', {
      label: 'Закрыть',
      handler: () => {
        store.dispatch(errorAction.clearError());
      },
    });
  });
};

export default increaseQuantity;
