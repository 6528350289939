import actionTypes from '../constants/action-types';

const savedLocations = (state = [], action) => {
  const { type } = action;
  if (type !== actionTypes.SET_SAVED_LOCATIONS) return state;

  return action.payload;
};

export default savedLocations;
