import getCountries from './get-countries';
import getCities from './get-cities';
import getDistricts from './get-districts';
import getMetroStations from './get-metro-stations';

export default {
  getCountries,
  getCities,
  getDistricts,
  getMetroStations,
};
