import errorTypes from './constants/error-types';

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = errorTypes.VALIDATION_ERROR;
  }
}

export default ValidationError;
