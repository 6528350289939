import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from '@internet-boutique-engineering/site-ui';
import stylesButton from '@internet-boutique-engineering/site-ui/src/components/button/styles';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';

const useStyles = createUseStyles((theme) => ({
  ...styles(theme),
  ...stylesButton(theme),
}));

const BuyCartBlock = ({ label, onCheckout, href, externalHref, disabled, onClick }) => {
  const classes = useStyles({ customWidth: null });

  const doCheckout = useCallback(() => {
    onClick();
    onCheckout();
  }, [onCheckout, onClick]);
  return (
    <div className={classes.footer}>
      <div className={classes.ofertaBlock}>
        Нажимая на кнопку «{label}»,
        <br />
        вы принимаете условия{' '}
        <a href="/dogovor-publichnoy-ofertyi.html">публичной оферты</a>
      </div>
      <div className={classes.buttonBlock}>
        {href && (
          <Link
            to={href}
            onClick={onClick}
            className={classNames(classes.customButton, classes.button)}
          >
            {label}
          </Link>
        )}
        {externalHref && (
          <a
            onClick={onClick}
            href={externalHref}
            className={classNames(classes.customButton, classes.button)}
          >
            {label}
          </a>
        )}
        {onCheckout !== Function.prototype && (
          <Button
            disabled={disabled}
            className={classes.customButton}
            onClickCapture={doCheckout}
          >
            {label}
          </Button>
        )}
      </div>
    </div>
  );
};

BuyCartBlock.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  externalHref: PropTypes.string,
  onCheckout: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

BuyCartBlock.defaultProps = {
  href: null,
  externalHref: null,
  onCheckout: Function.prototype,
  onClick: Function.prototype,
  disabled: false,
};

export default BuyCartBlock;
