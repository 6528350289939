import price from './price';
import structureToCamel from './structure-to-camel';
import structureToSnake from './structure-to-snake';
import getDeviceType from './get-device-type';

export default {
  price,
  structureToCamel,
  structureToSnake,
  getDeviceType,
};
