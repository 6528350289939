import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { JssProvider } from 'react-jss';
import store, { persistor } from './store';
import App from './app';
import errorHandler from './core/errors/handlers/error-handler';
// import liveChat from './modules/livechat'; // 2071513
import sentry from './core/sentry';
import mindbox from './core/mindbox';
import mytarget from './core/mytarget';
import criteo from './core/criteo';

// document.cookie = 'cookie_redesign=""; path=/; expires=-1';

// const chat = liveChat.init();
// liveChat.instance.set(chat);

mytarget.init();
sentry.init();
mindbox.init();
criteo.init();

window.addEventListener('unhandledrejection', (event) => errorHandler(event.reason));
window.addEventListener('error', (event) => errorHandler(event.error));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <JssProvider>
        <App />
      </JssProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
