import actionTypes from '../constants/action-types';
import discountCard from '../../discounts';
import promocodes from '../../promocodes';
import segment, { eventTypes } from '../../analytic/segment';

const analytic = () => (next) => (action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_COUNTRY: {
      segment.track(eventTypes.SET_COUNTRY, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_CITY: {
      segment.track(eventTypes.SET_CITY, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_STREET: {
      segment.track(eventTypes.SET_STREET, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_BUILDING: {
      segment.track(eventTypes.SET_BUILDING, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_BLOCK: {
      segment.track(eventTypes.SET_BLOCK, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_APARTMENT: {
      segment.track(eventTypes.SET_APARTMENT, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_BATCH_LOCATION: {
      segment.track(eventTypes.SET_BATCH_LOCATION, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_DELIVERY: {
      segment.track(eventTypes.SET_DELIVERY, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_DELIVERY_DETAILS: {
      segment.track(eventTypes.SET_DELIVERY_DETAILS, {
        data: payload,
      });
      break;
    }
    case actionTypes.SET_PAYSYSTEM: {
      segment.track(eventTypes.SET_PAYSYSTEM, {
        data: payload,
      });
      break;
    }
    case discountCard.constants.actionTypes.SET_CARDS: {
      segment.track(eventTypes.SET_CARDS);
      break;
    }
    case promocodes.constants.actionTypes.SET_PROMOCODE: {
      segment.track(eventTypes.SET_PROMOCODE);
      break;
    }
    default:
      break;
  }
  next(action);
};

export default analytic;
