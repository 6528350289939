import Modal from '@internet-boutique-engineering/site-ui/src/themes/bosco-new/components/modal';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import sellersInfoStatus from '../../constants/sellers-info-status';
import selectors from '../../selectors';
import styles from './styles';

const SellersInfo = ({ count }) => {
  const useStyles = createUseStyles(styles);
  const classes = useStyles();
  const status = useSelector(selectors.getSellersInfoStatus);
  if (status === sellersInfoStatus.HIDE) return null;

  return (
    <Modal className={classes.sellersText}>
      На каждого продавца создан отдельный заказ. Всего&nbsp;заказов:&nbsp;{count}
    </Modal>
  );
};

SellersInfo.propTypes = {
  count: PropTypes.number.isRequired,
};

export default SellersInfo;
