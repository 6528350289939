import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Icon } from '@internet-boutique-engineering/site-ui';

import styles from './styles';
import getMainButton from '../../../themes/get-main-button';

const useStyles = createUseStyles(styles);

const Confirm = ({ onClose, message, actionSuccess, actionCancel }) => {
  const classes = useStyles();

  const MainButton = getMainButton();
  return (
    <React.Fragment>
      <div className={classes.cover}></div>
      <div className={classes.modal}>
        <p className={classes.message}>{message}</p>
        <div className={classes.separator} />
        <div className={classes.wrapperButtons}>
          <MainButton
            onClick={actionSuccess.handler}
            className={classes.button}
            size="large"
            inverted={true}
          >
            {actionSuccess.label}
          </MainButton>
          <MainButton
            className={classes.buttonSmall}
            hasBackground={false}
            onClick={actionCancel.handler}
          >
            {actionCancel.label}
          </MainButton>
        </div>
        <button className={classes.buttonClose} onClick={onClose}>
          <Icon type="cross" />
        </button>
      </div>
    </React.Fragment>
  );
};

Confirm.propTypes = {
  message: PropTypes.string.isRequired,
  actionCancel: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func,
  }).isRequired,
  actionSuccess: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  message: '',
  actionCancel: {},
  actionSuccess: {},
};

export default Confirm;
