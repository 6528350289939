import actionTypes from '../constants/action-types';

const favorites = (state = [], action) => {
  const { type } = action;
  const { payload } = action;

  switch (type) {
    case actionTypes.CLEAR_FAVORITES:
      return [];
    case actionTypes.SET_FAVORITES:
      return [...payload];
    default:
      return state;
  }
};

export default favorites;
