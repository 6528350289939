import {
  LogoOutlet,
  LogoArticoli,
  LogoBoscoNew,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getLogo = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_SITE_NAME:
      return LogoBoscoNew;
    case constants.BOSCO_NEW_SITE_NAME:
      return LogoBoscoNew;
    case constants.ARTICOLI_SITE_NAME:
      return LogoArticoli;
    case constants.OUTLET_SITE_NAME:
      return LogoOutlet;
    default:
      break;
  }
  return LogoOutlet;
};

export default getLogo;
