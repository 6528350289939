import setCoutries from './set-coutries';
import setCities from './set-cities';
import setDistricts from './set-districts';
import setMetroStations from './set-metro-stations';

export default {
  setCoutries,
  setCities,
  setDistricts,
  setMetroStations,
};
