import calculate from './calclate';
import checkout from './checkout';
import getSavedLocations from './get-saved-locations';
import getGiftCards from './get-gift-cards';

export default {
  calculate,
  checkout,
  getSavedLocations,
  getGiftCards,
};
