import errorTypes from './constants/error-types';

class CustomError extends Error {
  constructor(message, action = {}) {
    super(message);
    this.name = errorTypes.CUSTOM_ERROR;
    this.action = action;
    this.isCritical = true;
  }
}

export default CustomError;
