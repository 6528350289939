import actionTypes from '../constants/action-types';

const defaultState = {
  subscribe: true,
};

const checkout = (state = defaultState, action) => {
  const { type } = action;

  switch (type) {
    case actionTypes.SET_BATCH_LOCATION: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.SET_COUNTRY: {
      const { payload } = action;
      return {
        ...state,
        country: payload,
      };
    }
    case actionTypes.SET_CITY: {
      const { payload } = action;
      return {
        ...state,
        city: payload,
      };
    }
    case actionTypes.SET_METRO: {
      const { payload } = action;
      return {
        ...state,
        metro: payload,
      };
    }
    case actionTypes.SET_DISTRICT: {
      const { payload } = action;
      return {
        ...state,
        district: payload,
      };
    }
    case actionTypes.SET_STREET: {
      const { payload } = action;
      return {
        ...state,
        street: payload,
      };
    }
    case actionTypes.SET_BUILDING: {
      const { payload } = action;
      return {
        ...state,
        building: payload,
      };
    }
    case actionTypes.SET_BLOCK: {
      const { payload } = action;
      return {
        ...state,
        block: payload,
      };
    }
    case actionTypes.SET_APARTMENT: {
      const { payload } = action;
      return {
        ...state,
        apartment: payload,
      };
    }
    case actionTypes.SET_DELIVERY: {
      const { payload } = action;
      return {
        ...state,
        delivery: payload,
      };
    }
    case actionTypes.SET_PAYSYSTEMS_LIST: {
      const { payload } = action;
      return {
        ...state,
        paysystemsList: payload,
      };
    }
    case actionTypes.SET_DELIVERY_COST: {
      const { payload } = action;
      const { delivery } = state;
      return {
        ...state,
        delivery: {
          ...delivery,
          cost: payload,
        },
      };
    }
    case actionTypes.SET_PAYSYSTEM: {
      const { payload } = action;
      const details = payload.onlinePayment ? { saveCard: true } : {};
      return {
        ...state,
        paysystem: payload,
        paysystemPayload: details,
      };
    }
    case actionTypes.UPDATE_PAYSYSTEM_PAYLOAD: {
      const { payload } = action;
      return {
        ...state,
        paysystemPayload: {
          ...state.paysystemPayload,
          ...payload,
        },
      };
    }
    case actionTypes.CLEAR_PAYSYSTEM: {
      return {
        ...state,
        paysystem: null,
        paysystemPayload: {},
      };
    }
    case actionTypes.SET_DELIVERY_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        deliveryDetails: {
          ...state.deliveryDetails,
          ...payload,
        },
      };
    }
    case actionTypes.SET_USE_BONUSES: {
      const { payload } = action;
      return {
        ...state,
        useBonuses: payload,
      };
    }
    case actionTypes.SET_SUBSCRIBE: {
      const { payload } = action;
      return {
        ...state,
        subscribe: payload,
      };
    }
    case actionTypes.SET_SELECTED_SAVED_LOCATION: {
      const { payload } = action;
      return {
        ...state,
        selectedSavedLocation: payload,
      };
    }
    case actionTypes.CLEAR_DELIVERY_DETAILS: {
      return {
        ...state,
        deliveryDetails: null,
      };
    }
    case actionTypes.CLEAR: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default checkout;
