export default {
  init: () => {
    const endpointId = process.env.MINDBOX_ENDPOINT_ID;
    const script = document.createElement('script');
    script.id = 'script-mindbox';
    script.src = 'https://api.mindbox.ru/scripts/v1/tracker.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      window.mindbox('create', {
        endpointId,
      });
    });
  },
};
