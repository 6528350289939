import isEmailValid from './is-email-valid';
import isPhoneValid from './is-phone-valid';

const validateUser = (email, name, lastname, phone, isCHecked) => {
  const errors = {};
  if (!isEmailValid(email)) {
    errors.email = 'Укажите валидный email';
  }

  if (!name) {
    errors.name = 'Укажите свое имя';
  }

  if (!lastname) {
    errors.lastname = 'Укажите свою фамилию';
  }

  if (!isPhoneValid(phone)) {
    errors.phone = 'Укажите валидный номер телефона';
  }

  if (!isCHecked) {
    errors.conditionsError = 'Вы должны дать согласие на обработку персональных данных';
  }

  const keys = Object.keys(errors);
  return keys.length ? errors : null;
};

export default validateUser;
