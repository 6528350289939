const layout = ({
  mainSectionWidth,
  sideWidth,
  asidePattern,
  cartHeaderHeight,
  bonusColor,
  fontFamily,
  tabletMediaQuery,
  cartHeaderHeightMobile,
  cartFooterHeight,
  cartHeaderBorder,
  cartHeaderBorderRadius,
  cartHeaderBorderTablet,
  cartHeaderBorderMobile,
  mobileMediaQuery,
  fieldBackgroundColor,
  lowEmphasisTextSize,
  primaryColor,
  extraLightColor,
  smallBorderRadius,
  secondaryTextColor,
  footerBackgroundImage,
  footerBackgroundImageMobile,
  footerBackgroundColorMobile,
  footerBackgroundColor,
  cartLayoutBorder,
  contentMinHeight,
  cartHeaderMaxWidth,
  cartHeaderBackground,
  cartHeaderTop,
  cartHeaderBackgroundMobile,
  contentTopOffset,
  contentShadow,
  asideMargin,
  contentAlignItems,
  asideBorderRadius,
  containerWidth,
  containerPadding,
  mainSectionPadding,
  asidePadding,
  mainSectionMobilePadding,
}) => ({
  layout: {
    fontFamily,
    color: secondaryTextColor,
    borderLeft: `${cartLayoutBorder}px solid ${bonusColor}`,
    borderRight: `${cartLayoutBorder}px solid ${bonusColor}`,
    boxSizing: 'border-box',
    '-webkit-font-smoothing': 'antialiased',
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'fixed',
      width: '100%',
      left: 0,
      bottom: 0,
      borderTop: `${cartLayoutBorder}px solid ${bonusColor}`,
    },
    [`${mobileMediaQuery}`]: {
      minHeight: '100vh',
    },
  },
  main: {
    width: mainSectionWidth,
    padding: mainSectionPadding,
    boxSizing: 'border-box',
    [`${mobileMediaQuery}`]: {
      width: '100%',
      padding: mainSectionMobilePadding,
    },
  },
  aside: {
    width: sideWidth,
    padding: asidePadding,
    margin: asideMargin,
    boxSizing: 'border-box',
    backgroundImage: asidePattern,
    borderRadius: asideBorderRadius,
    [`${mobileMediaQuery}`]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
  },
  headerGrid: {
    left: 0,
    top: 0,
    position: 'fixed',
    width: '100%',
    borderTopWidth: cartHeaderTop,
    borderTopColor: '#fff',
    borderTopStyle: 'solid',
    zIndex: 2,
    [`${mobileMediaQuery}`]: {
      borderTopWidth: 0,
    },
  },
  header: {
    borderTop: `${cartLayoutBorder}px solid ${bonusColor}`,
    borderRight: `${cartLayoutBorder}px solid ${bonusColor}`,
    borderLeft: `${cartLayoutBorder}px solid ${bonusColor}`,
    borderBottom: `${cartHeaderBorder}px solid ${bonusColor}`,
    borderRadius: cartHeaderBorderRadius,
    boxSizing: 'border-box',
    maxWidth: cartHeaderMaxWidth,
    height: cartHeaderHeight,
    background: `${cartHeaderBackground}`,
    margin: [0, 'auto'],
    [`${tabletMediaQuery}`]: {
      height: cartHeaderHeight * 0.8,
      borderBottomWidth: cartHeaderBorderTablet,
    },
    [`${mobileMediaQuery}`]: {
      height: cartHeaderHeightMobile,
      borderBottomWidth: cartHeaderBorderMobile,
      background: `${cartHeaderBackgroundMobile}`,
      borderRadius: 0,
    },
  },
  headerWrapper: {
    maxWidth: 1440,
    margin: [0, 'auto'],
    position: 'relative',
    [`${mobileMediaQuery}`]: {
      position: 'static',
    },
  },
  content: {
    display: 'flex',
    alignItems: contentAlignItems,
    minHeight: contentMinHeight,
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: containerPadding,
    maxWidth: containerWidth,
    margin: [0, 'auto'],
    boxShadow: contentShadow,
    [`${tabletMediaQuery}`]: {
      paddingTop: (cartHeaderHeight + contentTopOffset) * 0.8,
    },
    [`${mobileMediaQuery}`]: {
      paddingTop: cartHeaderHeightMobile,
      paddingBottom: 0,
      flexFlow: 'column',
      minHeight: 'auto',
    },
  },
  contentDisabled: {
    pointerEvents: 'none',
    display: 'flex',
    alignItems: contentAlignItems,
    minHeight: contentMinHeight,
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: containerPadding,
    maxWidth: containerWidth,
    margin: [0, 'auto'],
    boxShadow: contentShadow,
    [`${tabletMediaQuery}`]: {
      paddingTop: (cartHeaderHeight + contentTopOffset) * 0.8,
    },
    [`${mobileMediaQuery}`]: {
      paddingTop: cartHeaderHeightMobile,
      paddingBottom: 0,
      flexFlow: 'column',
      minHeight: 'auto',
    },
    opacity: 0.4,
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 3,
    right: 3,
    boxShadow: '0 -1px 4px rgba(0,0,0,.35)',
    padding: [20, 0, 18, 0],
    height: cartFooterHeight,
    boxSizing: 'border-box',
    backgroundColor: footerBackgroundColor,
    backgroundImage: footerBackgroundImage,
    maxWidth: 1440,
    margin: [0, 'auto'],
    [`${mobileMediaQuery}`]: {
      backgroundImage: footerBackgroundImageMobile,
      backgroundColor: footerBackgroundColorMobile,
      boxShadow: 'none',
      position: 'static',
      height: 'auto',
    },
  },
  liveChat: {
    border: 0,
    backgroundColor: fieldBackgroundColor,
    position: 'fixed',
    bottom: 3,
    zIndex: 4,
    right: 30,
    fontSize: lowEmphasisTextSize,
    color: secondaryTextColor,
    textTransform: 'uppercase',
    transition: 'all .3s ease',
    padding: [10, 20],
    outline: 'none',
    borderRadius: [smallBorderRadius, smallBorderRadius, 0, 0],
    [`${mobileMediaQuery}`]: {
      position: 'static',
      padding: 10,
      textTransform: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      margin: [0, 'auto'],
      paddingBottom: 20,
      fontSize: 20,
    },
    '& span': {
      [`${mobileMediaQuery}`]: {
        height: 24,
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& svg': {
      display: 'none',
      [`${mobileMediaQuery}`]: {
        fill: primaryColor,
        width: 20,
        height: 20,
        border: `1px solid ${primaryColor}`,
        padding: 3,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: 5,
      },
    },
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'relative',
      transition: 'all .3s ease',
      border: 'solid #754120',
      borderWidth: '0 1px 1px 0',
      padding: 4,
      transform: 'rotate(225deg)',
      top: '6%',
      marginRight: 10,
      [`${mobileMediaQuery}`]: {
        content: 'none',
      },
    },
    '&:hover': {
      backgroundColor: primaryColor,
      color: extraLightColor,
      [`${mobileMediaQuery}`]: {
        color: 'inherit',
        backgroundColor: 'transparent',
      },
    },
    '&:hover:before': {
      borderColor: extraLightColor,
    },
  },
});

export default layout;
