import {
  boscoOutletTheme,
  articoliTheme,
  boscoNewTheme,
} from '@internet-boutique-engineering/site-ui';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getTheme = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_SITE_NAME:
      return boscoNewTheme;
    case constants.ARTICOLI_SITE_NAME:
      return articoliTheme;
    case constants.BOSCO_NEW_SITE_NAME:
      return boscoNewTheme;
    case constants.OUTLET_SITE_NAME:
      return boscoOutletTheme;
    default:
      break;
  }

  return boscoNewTheme;
};

export default getTheme;
