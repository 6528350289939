import serviceClientAPI from '@internet-boutique-engineering/service-client-js';
import actionTypes from '../../user/constants/action-types';
import serviceClient from '..';
import configuration from '../../configuration';
import setServiceReadyStatus from '../../configuration/actions/set-service-ready-status';
import http from '../../https';

const setupServiceClient = (store) => (next) => (action) => {
  if (action.type !== actionTypes.SET_AUTH_TOKEN) {
    next(action);
    return;
  }

  const {
    payload: { accessToken },
  } = action;

  const serviceBaseUrl = configuration.accessors.getServiceBaseUrl();
  serviceClient.set(serviceClientAPI(http, serviceBaseUrl, accessToken));
  store.dispatch(setServiceReadyStatus(true));
  next(action);
};

export default setupServiceClient;
