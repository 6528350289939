const parseExperimentKeys = (keyString) => {
  const tokens = keyString.split(';');
  // eslint-disable-next-line no-return-assign
  const polyfill = function fromEntries(arr) {
    return arr.reduce((acc, curr) => {
      // eslint-disable-next-line prefer-destructuring
      acc[curr[0]] = curr[1];
      return acc;
    }, {});
  };
  const fromEntries = Object.fromEntries || polyfill;
  return fromEntries(
    tokens
      .map((token) => token.split(':').map((pair) => pair.trim()))
      .filter((parsedToken) => parsedToken.length === 2)
  );
};

export default parseExperimentKeys;
