import serviceClient from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';
import CustomError from '../../../core/errors/custom-error';

const setPromocode = (code) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.checkout.setPromocode(code).then((response) => {
    if (response.ok !== true) {
      return response.json().then((errorData) => {
        const { errors } = errorData;
        if (!errors) throw new CustomError(response.statusText);

        const [error] = errors;
        if (!error) throw new CustomError(response.statusText);

        throw new CustomError(error.title || response.statusText);
      });
    }
    return new Promise((resolve) => {
      response.json().then(({ data }) => resolve(structureToCamel(data)));
    });
  });
};

export default setPromocode;
