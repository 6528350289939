import actionTypes from '../constants/action-types';
import actions from '../actions';

const locationData = (store) => (next) => (action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SET_CITY:
    case actionTypes.SET_COUNTRY: {
      store.dispatch(actions.setDistricts([]));
      store.dispatch(actions.setMetroStations([]));
      next(action);
      break;
    }
    default:
      next(action);
      break;
  }
};

export default locationData;
