import actionTypes from '../constants/action-types';

const show = (modalId, data) => ({
  type: actionTypes.SHOW_MODAL,
  payload: {
    modalId,
    data,
  },
});

export default show;
