import operations from './operations';
import reducers from './reducers';
import actions from './actions';
import selectors from './selectors';
import constants from './constants';

export default {
  operations,
  reducers,
  actions,
  selectors,
  constants,
};
