import React from 'react';
import { useSelector } from 'react-redux';
import selectors from '../selectors';
import { get } from '../registry';

const ModalsContainer = () => {
  const modal = useSelector(selectors.getModal);
  if (!modal) return null;

  const { modalId, data } = modal;
  const ModalComponent = get(modalId);

  return <ModalComponent {...data} />;
};

export default ModalsContainer;
