import cart from '../../cart';
import configuration from '../../../core/configuration';

const redirects = (store) => (next) => (action) => {
  const { type } = action;
  next(action);

  const state = store.getState();
  switch (type) {
    case cart.constants.actionTypes.TOGGLE_REMOVE_PRODUCT_FROM_CART: {
      const emptyCartRedirectUrl = configuration.selectors.emptyCartRedirect(state);
      const itemList = cart.selectors.getCart(state);

      if (!itemList.length) {
        window.location.href = emptyCartRedirectUrl;
      }
      break;
    }

    default:
      break;
  }
};

export default redirects;
