import fetch from 'cross-fetch';

const remove = (path, query = {}, headers = {}) => {
  if (!path) Promise.reject(new Error("'delete' should not be NULL or undefined"));

  return fetch(path, {
    method: 'DELETE',
    mode: 'cors',
    headers,
    body: JSON.stringify(query),
  });
};

export default remove;
