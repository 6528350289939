import React from 'react';
import { PromocodeBlock } from '@internet-boutique-engineering/site-ui';
import usePromocode from '../hooks/use-promocode';

const PromocodeContainer = () => {
  const { code, error, discountPromoCodePercent, onSetPromocode } = usePromocode();

  return (
    <PromocodeBlock
      defaultValue={code}
      promocodeOnly={code && discountPromoCodePercent >= 10}
      error={error}
      onSetDiscount={onSetPromocode}
    />
  );
};

export default PromocodeContainer;
