import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import common from '../../common';
import actions from '../actions';
import segment, { eventTypes } from '../../analytic/segment';

const useCheckout = () => {
  const dispatch = useDispatch();
  const appStatus = useSelector(common.selectors.getAppStatus);

  const onCheckout = useCallback(() => {
    segment.track(eventTypes.CHECKOUT_USER_CLICK);
    dispatch(actions.doCheckout());
  }, [dispatch]);

  return {
    appStatus,
    onCheckout,
  };
};

export default useCheckout;
