import { useSelector } from 'react-redux';
import selectors from '../selectors';
import verifiers from '../verifiers';

const useUser = () => {
  const user = useSelector(selectors.getUser);
  return {
    ...user,
    isAuthentificated: verifiers.isUserAuthentificated(),
  };
};

export default useUser;
