const getCookies = (context) => {
  const { cookie } = context;
  return cookie
    .split(';')
    .map((item) => item.trim())
    .reduce((acc, item) => {
      const [key, value] = item.split('=');

      return {
        ...acc,
        [key]: value,
      };
    }, {});
};

export default getCookies;
