import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BoscoNewHeader } from '@internet-boutique-engineering/site-ui';
import getCallCenterPhone from '../../../../../core/configuration/selectors/get-call-center-phone';
import getBoutiquePhone from '../../../../../core/configuration/selectors/get-boutique-phone';

import HelpMenu from '../../help-menu/bosco-new';

const CartHeader = () => {
  const callCenterPhone = useSelector(getCallCenterPhone);
  const boutiquePhone = useSelector(getBoutiquePhone);

  const phones = useMemo(
    () => [
      { label: 'Инфоцентр', tel: callCenterPhone },
      { label: 'Интернет-бутик', tel: boutiquePhone },
    ],
    [callCenterPhone, boutiquePhone]
  );

  return <BoscoNewHeader tels={phones} leftSide={<HelpMenu />} />;
};

export default CartHeader;
