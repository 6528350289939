const itemsCardStyles = ({
  secondaryTextColor,
  lowEmphasisTextSize,
  cartListSeparatorColor,
  mobileMediaQuery,
}) => ({
  group: {
    borderColor: cartListSeparatorColor,
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: 30,
    marginRight: 20,
    borderRadius: 5,
    [`${mobileMediaQuery}`]: {
      marginRight: 0,
    },
  },
  label: {
    display: 'inline-block',
    color: secondaryTextColor,
    fontSize: lowEmphasisTextSize,
    backgroundColor: '#fff',
    transform: 'translate(20px, -50%)',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default itemsCardStyles;
