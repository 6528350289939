import actionTypes from './action-types';
import formErrorTypes from './form-error-types';
import formErrorAnchors from './form-error-anchors';
import sellersInfoStatus from './sellers-info-status';

export default {
  actionTypes,
  formErrorTypes,
  formErrorAnchors,
  sellersInfoStatus,
};
