const modalStyles = ({
  extraLightColor,
  fontFamily,
  primaryTextColor,
  dropDownBorder,
  borderRadius,
  transitionDuration,
  transitionTimingFunction,
  extraLowEmphasisTextSize,
  mobileMediaQuery,
}) => ({
  cover: {
    backgroundColor: extraLightColor,
    position: 'fixed',
    zIndex: 4,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.9,
  },
  modal: {
    backgroundColor: extraLightColor,
    fontFamily,
    position: 'fixed',
    zIndex: 999,
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: primaryTextColor,
    borderColor: dropDownBorder,
    borderWidth: 10,
    borderStyle: 'solid',
    borderRadius,
    transform: 'translate(-50%, -50%)',
    padding: [37, 30, 23],
    width: 400,
    boxSizing: 'border-box',
    [`${mobileMediaQuery}`]: {
      minWidth: '100%',
    },
  },
  message: {
    margin: 0,
    textAlign: 'center',
  },
  separator: {
    width: '100%',
    margin: [27, 0, 18, 0],
    borderTop: '1px solid #c8c8c8',
  },
  wrapperButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: 20,
  },
  buttonSmall: {
    fontSize: extraLowEmphasisTextSize,
  },
  buttonClose: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 10,

    width: 16,
    height: 16,
    padding: 0,

    border: 'none',
    backgroundColor: 'transparent',
    transitionDuration,
    transitionTimingFunction,

    opacity: 0.5,
    cursor: 'pointer',

    '& span': {
      width: 16,
      height: 16,
    },

    '& svg': {
      width: '100%',
      height: '100%',

      stroke: '#967b44',
      strokeWidth: 3,
    },

    '&:hover': {
      opacity: 1,
    },
  },
});

export default modalStyles;
