import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../actions';
import selectors from '../selectors';
import operations from '../operations';
import DiscountCard from '../../../core/errors/discount-card';

const useDiscountCard = () => {
  const discountCards = useSelector(selectors.getDiscountCards);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [discountCard] = discountCards;
  const onSetDiscount = useCallback(
    (cardNumber) => {
      setIsFetching(true);
      setError(null);
      operations
        .setDiscountCards(cardNumber)
        .then((cards) => {
          if (!Array.isArray(cards))
            throw new DiscountCard('Не удалось активировать карту');

          dispatch(actions.setCards(cards));
        })
        .catch(({ message }) => {
          dispatch(actions.clearCards());
          setError(message);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [dispatch, setError]
  );

  return {
    error,
    isFetching,
    discountCard,
    onSetDiscount,
  };
};

export default useDiscountCard;
