const buyCartBlockStyles = ({
  secondaryTextColor,
  extraLowEmphasisTextSize,
  primaryColor,
  mobileMediaQuery,
}) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: 'initial',
    alignItems: 'center',
    padding: 23,
    [`${mobileMediaQuery}`]: {
      padding: [0, 10, 0, 10],
      height: 'auto',
    },
  },
  buttonBlock: {
    display: 'flex',
    width: '100%',
    [`${mobileMediaQuery}`]: {
      width: '100%',
      paddingTop: 20,
    },
  },
  customButton: {
    width: '100%',
    // minWidth: 200,
    textTransform: 'uppercase',
    display: 'block',
    margin: [0, 'auto'],
    position: 'relative',
    // [`${tabletMediaQuery}`]: {
    //   minWidth: '100%',
    // },
    [`${mobileMediaQuery}`]: {
      left: 0,
    },
  },
  ofertaBlock: {
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: [23, 0, 23, 0],
    color: secondaryTextColor,
    fontSize: extraLowEmphasisTextSize,
    '& a': {
      color: primaryColor,
      textDecoration: 'none',
    },
    [`${mobileMediaQuery}`]: {
      width: '100%',
      textAlign: 'center',
      padding: ['4vw', '21vw'],
    },
  },
});

export default buyCartBlockStyles;
