import actions from './actions';
import reducers from './reducers';
import constants from './constants';
import accessors from './accessors';
import selectors from './selectors';

export default {
  actions,
  reducers,
  constants,
  accessors,
  selectors,
};
