import actions from './actions';
import reducers from './reducers';
import selectors from './selectors';
import operations from './operations';
import verifiers from './verifiers';
import hooks from './hooks';

export default {
  actions,
  reducers,
  selectors,
  operations,
  verifiers,
  hooks,
};
