import React, { useRef } from 'react';
import Modal from '@internet-boutique-engineering/site-ui/src/themes/bosco-new/components/modal';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import getSelectedCity from '../checkout/selectors/get-selected-city';
import checkout from '../checkout';

const useStyles = createUseStyles(styles);

function CDEKWidget({ setShowWidget }) {
  const dispatch = useDispatch();

  const selectedCity = useSelector(getSelectedCity);

  const widgetRef = useRef(null);
  const classes = useStyles();

  const selectedCityRules = (name) => {
    if (
      name.toLowerCase() === 'москва в пределах мкад' ||
      name.toLowerCase() === 'москва за пределами мкад'
    ) {
      return 'Москва';
    }
    return name || 'Москва';
  };

  // eslint-disable-next-line no-undef
  const widget = new ISDEKWidjet({
    defaultCity: selectedCityRules(selectedCity.name),
    cityFrom: 'Москва',
    link: 'forpvz',
    path: 'https://widget.cdek.ru/widget/scripts/',
    servicepath: 'https://widget.cdek.ru/widget/scripts/service.php',
    hidedelt: true,
    detailAddress: true,
  });

  function choosePVZ(address) {
    const regexWithoutBuilding = /(.*?)\s([0-9/]+)/;

    const matchWithoutBuilding = address.PVZ.Address.match(regexWithoutBuilding);
    const street = matchWithoutBuilding[1];
    const houseNumber = matchWithoutBuilding[2];

    console.log(`Улица: ${street.slice(0, -1)}`);
    dispatch(checkout.actions.setStreet(street.slice(0, -1)));
    console.log(`Номер дома: ${houseNumber}`);
    dispatch(checkout.actions.setBuilding(houseNumber));
    setTimeout(() => {
      setShowWidget(false);
    }, 500);
  }

  widget.binders.add(choosePVZ, 'onChoose');

  return (
    // <div style={{
    //   height: '600px', width: '600px', position: 'absolute', top: '50%', zIndex: '999',
    // }}>
    <Modal className={classes.modal} close={() => setShowWidget((prev) => !prev)}>
      <div
        id="forpvz"
        className="CDEK-widget__popup-mask"
        style={{ maxHeight: 'calc(100% - 57px)', maxWidth: '100%', marginTop: '57px' }}
        ref={widgetRef}
      ></div>
    </Modal>
    // </div>
  );
}

CDEKWidget.propTypes = {
  // showWidget: PropTypes.bool,
  setShowWidget: PropTypes.func,
};

export default CDEKWidget;
