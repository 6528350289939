const getGroup = (items, baseItem, groupName) => {
  const item = items.find(({ groupedBy }) => groupedBy === groupName);
  if (item) return item;

  const { options } = baseItem;

  items.push({
    ...baseItem,
    cost: null,
    title: 'Самовывоз',
    id: `${groupName}:`,
    groupedBy: groupName,
    options: {
      ...options,
      deliveryVeriation: [],
      deliverySlots: [],
    },
  });
  return items[items.length - 1];
};

const normalize = (items) =>
  items.reduce((acc, item) => {
    const { group, options, id, title, cost, extraConditions } = item;
    if (!group) {
      acc.push(item);
      return acc;
    }

    const groupedItem = getGroup(acc, item, group);
    const { options: groupedOptions } = groupedItem;
    groupedOptions.deliveryVeriation.push({
      id,
      name: title,
      value: id,
      cost,
      extraConditions,
    });
    if (options && options.deliverySlots) {
      groupedOptions.deliverySlots.push(
        ...options.deliverySlots.map((slot) => ({
          ...slot,
          deliverVeriationId: id,
        }))
      );
    }

    return acc;
  }, []);

export default normalize;
