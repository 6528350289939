import errorTypes from './constants/error-types';

class NetworkError extends Error {
  constructor(httpCode, url, message, headers = null, body = null) {
    super(message);
    this.name = errorTypes.NETWORK_ERROR;
    this.userFriendlyMessage =
      'Упс! Что-то пошло не так. Перегрузите страницу и попробуйте снова.';
    this.isCritical = true;
    this.url = url;
    this.httpCode = httpCode;
    this.headers = headers;
    this.body = body;
  }
}

export default NetworkError;
