import cladr from '../api/kladr';

const getCityCladrId = async (cityName) => {
  if (!cityName) return null;
  const response = await cladr.getCity(cityName).then((data) => data.json());
  const { result } = response;
  if (!Array.isArray(result) || !result.length) {
    return null;
  }
  const [first] = result;
  return first.id;
};

export default getCityCladrId;
