const sellerName = 'Bosco cafe';
const limit = 5000;

const isBoscoCafeLimitSatisfied = (cart) => {
  const cafeItems = cart.filter((item) => item.seller === sellerName);
  if (!cafeItems.length) return true;

  const cafeItemsPrice = cafeItems.reduce(
    (sum, { price, quantity }) => sum + price * quantity,
    0
  );
  return cafeItemsPrice >= limit;
};

export default isBoscoCafeLimitSatisfied;
