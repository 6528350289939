import serviceClientAPI from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';
import connectedStore from '../../../store/connected-store';
import setAppStatusBusy from '../../common/actions/set-app-status-busy';
import setAppStatusIdle from '../../common/actions/set-app-status-idle';

const removeProduct = (userId, itemId) => {
  const store = connectedStore.get();
  store.dispatch(setAppStatusBusy());

  const client = serviceClientAPI.get();
  return client.cart
    .remove(userId, itemId)
    .then((data) => data.json())
    .then(({ data }) => {
      store.dispatch(setAppStatusIdle());
      return structureToCamel(data);
    });
};

export default removeProduct;
