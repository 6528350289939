import React, { useEffect } from 'react';
import ItemsCard from './items-card';
import segment, { eventTypes } from '../../analytic/segment';

const CartContainer = () => {
  useEffect(() => {
    segment.track(eventTypes.CART_LIST_INITIATED);
  }, []);
  return <ItemsCard />;
};

export default CartContainer;
