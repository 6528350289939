import constants from '../core/constants';

const getCurrency = () => {
  const siteId = process.env.SITE_ID;

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return '₽';
    default:
      return 'руб.';
  }
};

export default getCurrency;
