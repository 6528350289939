import reduxCatch from 'redux-catch';
import authClientMiddlewares from '../core/auth-client/middlewares';
import serviceClientMiddlewares from '../core/service-client/middlewares';
import userMiddlewares from '../core/user/middlewares';
import errorHandler from '../core/errors/handlers/error-handler';
import checkout from '../modules/checkout';
import location from '../modules/location';
import cart from '../modules/cart';
import common from '../modules/common';

export default [
  reduxCatch(errorHandler),
  checkout.middlewares.clear,
  checkout.middlewares.calculate,
  checkout.middlewares.checkout,
  checkout.middlewares.analytic,
  authClientMiddlewares.setupAuthClient,
  userMiddlewares.authenticate,
  userMiddlewares.update,
  serviceClientMiddlewares.setupServiceClient,
  location.middlewares.fillLocation,
  location.middlewares.locationData,
  cart.middelwares.update,
  common.middlewares.redirects,
];
