const headerMenu = () => ({
  bar: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 3,
  },
});

export default headerMenu;
