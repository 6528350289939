import ga from '../analytic/ga';
import storage from './storage';
import parseExperimentKeys from './utils/parse-experiment-keys';

const resolveExperiments = () => {
  const {
    utils: { gtag },
  } = ga;
  const experiments = parseExperimentKeys(process.env.EXPERIMENTS);
  Object.keys(experiments).forEach((key) => {
    const experimentId = experiments[key];
    gtag('event', 'optimize.callback', {
      name: experimentId,
      callback: (value) => {
        storage.set(key, Boolean(value));
      },
    });
  });
};

export default resolveExperiments;
