import get from 'lodash.get';

const getMyTargetConfig = (store) => {
  const id = get(store, 'serviceConfiguration.myTargetId', null);
  const feedId = get(store, 'serviceConfiguration.myTargetFeedId', null);

  return {
    id,
    feedId,
  };
};

export default getMyTargetConfig;
