import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';
import CommonLayout from '../layout/themes/common';
import BoscoNewLayout from '../layout/themes/bosco-new';
import ArticoliLayout from '../layout/themes/articoli';

const getLayout = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return ArticoliLayout;
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewLayout;
    case constants.OUTLET_SITE_NAME:
      return CommonLayout;
    default:
      break;
  }
  return BoscoNewLayout;
};

export default getLayout;
