export default {
  init: () => {
    const id = process.env.CRITEO_ID;

    if (!id) return;

    const script = document.createElement('script');
    script.src = `https://dynamic.criteo.com/js/ld/ld.js?a=${id}`;
    document.body.appendChild(script);
  },
  get: () => {
    window.criteo_q = window.criteo_q || [];

    return window.criteo_q;
  },
};
