import connectedStore from '../../../store/connected-store';
import configuration from '../../../core/configuration';
import constants from '../../../core/constants';

const isFreeDeliveryAvailable = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  if (siteId === constants.BOSCO_SITE_NAME) return true;
  return false;
};

export default isFreeDeliveryAvailable;
