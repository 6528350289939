import cladr from '../api/kladr';

const getStreetCladrSuggestions = (queryStreet, cityId) =>
  new Promise((resolve, reject) => {
    cladr
      .getStreet(queryStreet, cityId)
      .then((data) => data.json())
      .then((data) => {
        const { result } = data;
        if (Array.isArray(result)) {
          resolve(result);
        } else {
          resolve([]);
        }
      })
      .catch(reject);
  });

export default getStreetCladrSuggestions;
