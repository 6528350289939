let localStore;

const set = (store) => {
  localStore = store;
};

const get = () => localStore;

export default {
  set,
  get,
};
