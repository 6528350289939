import reducers from './reducers';
import actions from './actions';
import operations from './operations';
import constants from './constants';

export default {
  reducers,
  operations,
  actions,
  constants,
};
