import serviceBaseUrl from './service-base-url';
import loginPageUrl from './login-page-url';
import statuses from './statuses';
import getOrderSuccessUrl from './get-order-success-url';
import getPaymentUrl from './get-payment-url';
import getSiteId from './get-site-id';
import getCallCenterPhone from './get-call-center-phone';
import getBoutiquePhone from './get-boutique-phone';
import getMindboxEndpointId from './get-mindbox-endpointId';
import getPopmechanicEmbed from './get-popmechanic-embed';
import getPopmechanicUrl from './get-popmechanic-url';
import emptyCartRedirect from './empty-cart-redirect';
import getMainSeller from './get-main-seller';
import getCartItemsShowConfig from './get-cart-items-show-config';
import minFreeDeliveryPrice from './min-free-delivery-price';
import getAllowBonusees from './get-allow-bonuses';
// import getFacebookPixelId from './get-facebook-pixel-id';
import getCriteoId from './get-criteo-id';

export default {
  serviceBaseUrl,
  statuses,
  loginPageUrl,
  getOrderSuccessUrl,
  getPaymentUrl,
  getSiteId,
  getCallCenterPhone,
  getBoutiquePhone,
  getMindboxEndpointId,
  getPopmechanicEmbed,
  getPopmechanicUrl,
  emptyCartRedirect,
  getMainSeller,
  getCartItemsShowConfig,
  minFreeDeliveryPrice,
  getAllowBonusees,
  // getFacebookPixelId,
  getCriteoId,
};
