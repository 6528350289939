import serviceClient from '../../../core/service-client';

const getGiftCards = (userId) => {
  const serviceClientAPI = serviceClient.get();
  return serviceClientAPI.checkout
    .getGiftCards(userId)
    .then((response) => response.json().then(({ data }) => data));
};

export default getGiftCards;
