import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import getConfig from './config/get-config';
import store from './store';
import configuration from './core/configuration';
import ErrorBoundary from './core/errors/components/error-boundary';
import getTheme from './themes/get-theme';
import getFonts from './themes/get-fonts';
import getLayout from './themes/get-layout';

const App = () => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    (async () => {
      const config = await getConfig();
      if (!config.siteId || config.siteId === 'bosco') config.siteId = 'boscoNew';
      store.dispatch(configuration.actions.configServiceClient(config));
      setTheme(getTheme());
      const fonts = getFonts();
      fonts.attach();
      if (config.serviceBaseUrl === 'http://localhost:8081') {
        document.cookie = 'BX_REACT_ID=muskhab';
        document.cookie = 'BX_USER_ID=muskhab';
        document.cookie = 'BITRIX_SM_SALE_UID=muskhab';
      }
    })();
  }, []);

  const ThemeLayout = getLayout();

  if (!theme) return null;

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ThemeLayout />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
