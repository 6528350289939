import selectors from '../selectors';
import connectedStore from '../../../store/connected-store';

const isUserAuthentificated = () => {
  const state = connectedStore.get().getState();
  const { id, bxUserId } = selectors.getUser(state);
  if (!bxUserId) return undefined;

  return !!id;
};

export default isUserAuthentificated;
