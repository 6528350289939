const deliveryStyles = ({
  mobileMediaQuery,
  fontFamily,
  lowEmphasisTextSize,
  primaryTextColor,
  extraLowEmphasisTextSize,
  separatorColor,
  primaryColor,
}) => ({
  deliveryGroup: {
    fontFamily,
    margin: [33, 33, 33, 0],
    padding: [20, 0, 0, 0],
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: separatorColor,
    [`${mobileMediaQuery}`]: {
      margin: 10,
    },
  },
  deliveryWrapper: {
    display: 'flex',
    [`${mobileMediaQuery}`]: {
      flexDirection: 'column',
    },
  },
  label: {
    margin: [0, 10, 0, 0],
    minWidth: 63,
    fontSize: lowEmphasisTextSize,
    [`${mobileMediaQuery}`]: {
      margin: [0, 0, 10, 0],
    },
  },
  link: {
    color: primaryTextColor,
    fontSize: extraLowEmphasisTextSize,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  rowGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, 0, 8, 0],
    justifyContent: 'space-between',
    alignItems: 'center',
    [`${mobileMediaQuery}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  optionRow: {
    width: '100%',
    margin: [15, 0, 0, 25],
    '& > *': {
      maxWidth: 340,
    },
    [`${mobileMediaQuery}`]: {
      margin: [15, 0, 0, 0],
    },
  },
  errorWrapper: {
    marginLeft: 72,
    [`${mobileMediaQuery}`]: {
      marginLeft: 0,
    },
  },
  errorRow: {
    '& > div': {
      marginLeft: 25,
      marginBottom: 15,
    },
  },
  freeDeliveryNotice: {
    marginLeft: 95,
    marginTop: 20,
    color: primaryColor,
    [`${mobileMediaQuery}`]: {
      marginLeft: 0,
      marginTop: 5,
    },
  },
  description: {
    padding: [10, 0, 0, 25],
    [`${mobileMediaQuery}`]: {
      paddingLeft: 0,
    },
  },
});

export default deliveryStyles;
