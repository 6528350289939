import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownContainer,
} from '@internet-boutique-engineering/site-ui';
import styles from './styles';
import getMainButton from '../../../../themes/get-main-button';
import useHelpMenu from '../../hooks/use-help-menu';

const useStyles = createUseStyles(styles);

const HelpMenu = () => {
  const classes = useStyles();
  const { mail, openChat, openCall } = useHelpMenu();

  const MainButton = getMainButton();

  const callButton = (
    <MainButton
      className={classes.button}
      onClick={openCall}
      href="https://zingaya.com/widget/63b367d3c5d745cf9656a37bf3e462bf"
    >
      Звонок с сайта
    </MainButton>
  );

  const consultationButton = (
    <MainButton className={classes.button} onClick={openChat}>
      Online консультант
    </MainButton>
  );

  const emailSendButton = (
    <MainButton className={classes.button} href={`mailto:${mail}`}>
      Написать письмо
    </MainButton>
  );

  const menu = [
    {
      label: 'item 1',
      element: callButton,
    },
    {
      label: 'item 2',
      element: consultationButton,
    },
    {
      label: 'item 3',
      element: emailSendButton,
    },
    {
      label: 'Способы оплаты',
      href: '/payment.html',
    },
    {
      label: 'Доставка',
      href: '/delivery.html',
    },
    {
      label: 'Возврат товара',
      href: '/vozvrat.html',
    },
    {
      label: 'Семейные карты',
      href: '/magic-cards.html',
    },
  ];

  return (
    <Dropdown className={classes.dropdown}>
      <DropdownTrigger className={classes.help}>
        <button>Готовы помочь</button>
      </DropdownTrigger>
      <DropdownContent>
        <DropdownContainer className={classes.container} items={menu} />
      </DropdownContent>
    </Dropdown>
  );
};

export default HelpMenu;
