import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import segment, { eventTypes } from '../../../analytic/segment';
import getTotal from '../../../../themes/get-total';
import cartSelectors from '../../../cart/selectors';
import selectors from '../../selectors';
import actions from '../../actions';
import core from '../../../../core';

import userHooks from '../../../../core/user/hooks';
import useDiscountCard from '../../../discounts/hooks/use-discount-card';
import usePromocode from '../../../promocodes/hooks/use-promocode';
import useConfirmOrder from '../../../cart/hooks/use-confirm-order';
import useCheckout from '../../hooks/use-checkout';
import isBoscoCafeLimitSatisfied from '../../../cart/helpers/is-bosco-cafe-limit-satisfied';
import isBoscoCafeTimeSutisfied from '../../../cart/helpers/is-bosco-cafe-time-satisfied';
import SellersInfo from '../../components/sellers-info';
import user from '../../../../core/user';
import connectedStore from '../../../../store/connected-store';
import checkout from '../..';

const {
  helpers: { price },
  configuration,
} = core;
const TotalContainer = ({ isCheckout }) => {
  const { isAuthentificated } = userHooks.useUser();
  const dispatch = useDispatch();
  const {
    error: errorDiscountCard,
    isFetching: isFetchingDiscountCard,
    discountCard: discountCardInfo,
    onSetDiscount: onSetDiscountCard,
  } = useDiscountCard();
  const {
    code: promocode,
    error: errorPromocode,
    isFetching: isFetchingPromocode,
    discountPromoCodePercent,
    onSetPromocode,
  } = usePromocode();
  const { onConfirmOrder } = useConfirmOrder();
  const { onCheckout } = useCheckout();

  const [totalPrice, setTotalPrice] = useState(0);
  const [finalTotalPrice, setFinalTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [certificates, setCertificates] = useState([]);

  const amount = useSelector(selectors.getAmount);
  const isUseBonuses = useSelector(selectors.getUseBonuses);
  const cart = useSelector(cartSelectors.getCart);
  const deliveryId = useSelector(selectors.getSelectedDeliveryId);
  const hiddenDelivery = !isAuthentificated || !deliveryId;

  const {
    cartPrice,
    discount,
    discountCard,
    discountPromoCode,
    discountSeasonal,
    delivery,
    bonuses,
    maxBonusesToUse,
    finalCartPrice,
    bonusToAdd,
    bonusToAddAfterDiscount,
  } = amount;

  const [changedBonusesValue, setChangedBonusesValue] = useState(0);

  const isBonusesAvailable = isAuthentificated && !!maxBonusesToUse && !!bonuses;
  const isAllowBonuses = useSelector(configuration.selectors.getAllowBonusees);

  const onCheckedBonuses = useCallback(
    ({ checked }) => {
      dispatch(actions.setUseBonuses(checked));
    },
    [maxBonusesToUse]
  );
  const { id } = useSelector(user.selectors.getUser);

  useEffect(() => {
    if (cart.length < 1) {
      setTotalPrice(0);
      setTotalDiscount(0);
      setFinalTotalPrice(0);
    }
    const total = cart.reduce(
      (sum, item) => sum + (item.price + (item.discount || 0)) * item.quantity,
      0
    );
    const productDiscount = cart.reduce(
      (sum, item) => sum + (item.discount || 0) * item.quantity,
      0
    );
    const finalBonuses = isUseBonuses ? maxBonusesToUse : 0;
    const calcCartPrice = total - productDiscount - (discountPromoCode || 0);
    const calcDelivery = hiddenDelivery ? 0 : delivery || 0;
    const finalTotal = calcCartPrice + calcDelivery - finalBonuses;

    setTotalPrice(total);
    setTotalDiscount(productDiscount - (discountCard ? discountCard.value : 0));
    setFinalTotalPrice(finalTotal);
  }, [cart, amount, hiddenDelivery]);

  useEffect(() => {
    if (id) {
      checkout.operations.getGiftCards(id.toString()).then((res) => setCertificates(res));
    }
  }, [id]);

  useEffect(() => {
    if (isUseBonuses) {
      setChangedBonusesValue(bonusToAddAfterDiscount);
    } else {
      setChangedBonusesValue(bonusToAdd);
    }
  }, [isUseBonuses]);

  useEffect(() => {
    if (isUseBonuses) {
      setChangedBonusesValue(bonusToAddAfterDiscount);
    } else {
      setChangedBonusesValue(bonusToAdd);
    }
  }, []);

  const Total = getTotal();

  const getDiscount = useCallback(() => {
    const before = discount > 0 || totalDiscount > 0 ? '-' : '';
    const cost = price(totalDiscount || discount || 0);

    return `${before}${cost}`;
  }, [discount, totalDiscount]);

  const getDiscountCardPrice = useCallback(() => {
    if (!discountCard || !discountCard.value) {
      return price(0);
    }
    const before = discountCard.value > 0 ? '-' : '';

    return `${before}${price(discountCard.value)}`;
  }, [discountCard]);

  const getSeasonalPrice = useCallback(() => {
    if (!discountSeasonal || !discountSeasonal.value) {
      return price(0);
    }
    const before = discountSeasonal.value > 0 ? '-' : '';

    return `${before}${price(discountSeasonal.value)}`;
  }, [discountCard]);

  const onConfirm = useCallback(() => {
    segment.track(eventTypes.CART_START_CHECKOUT);

    onConfirmOrder();
  }, [onConfirmOrder]);

  const isLimitSatisfied = isBoscoCafeLimitSatisfied(cart);
  const isTimeSatisfied = isBoscoCafeTimeSutisfied(cart);

  const isDisabled = !isLimitSatisfied || !isTimeSatisfied || cart.length === 0;

  let disabledText = '';
  if (!isTimeSatisfied) {
    disabledText = 'Товары BOSCOCAFE можно заказать с 10 до 18 часов';
  } else if (!isLimitSatisfied) {
    disabledText = 'Стоимость товаров BOSCOCAFE должна быть выше 4999 руб';
  }

  const store = connectedStore.get();
  const state = store.getState();

  const selectedOnlinePayment = selectors.getPaySystemOnlineSelected(state);
  const sellers = cart.reduce((sellersArr = [], item) => {
    if (!sellersArr.includes(item.seller)) {
      sellersArr.push(item.seller);
    }
    return sellersArr;
  }, []);

  return (
    <>
      <Total
        isAuthentificated={isAuthentificated}
        discountCard={discountCardInfo}
        errorDiscountCard={errorDiscountCard}
        onSetDiscountCard={onSetDiscountCard}
        isFetchingDiscountCard={isFetchingDiscountCard}
        promocode={promocode}
        discountPromoCodePercent={discountPromoCodePercent}
        isFetchingPromocode={isFetchingPromocode}
        errorPromocode={errorPromocode}
        onSetPromocode={onSetPromocode}
        hiddenDelivery={hiddenDelivery}
        totalPrice={price(totalPrice || cartPrice)}
        delivery={price(delivery || 0)}
        discount={getDiscount()}
        discountSeasonalPercent={discountSeasonal ? discountSeasonal.percent : 0}
        discountSeasonalPrice={getSeasonalPrice()}
        discountCardPercent={isAuthentificated && discountCard ? discountCard.percent : 0}
        discountCardPrice={getDiscountCardPrice()}
        discountPromoCode={`${discountPromoCode > 0 ? '-' : ''}${price(
          discountPromoCode || 0
        )}`}
        finalTotalPrice={price(finalTotalPrice || finalCartPrice)}
        totalNoBonuses={price(finalTotalPrice || finalCartPrice)}
        bonuses={price(bonuses)}
        isBonusesAvailable={isBonusesAvailable}
        isAllowBonuses={isAllowBonuses}
        bonusToAdd={price(changedBonusesValue)}
        isUseBonuses={isUseBonuses}
        onCheckedBonuses={onCheckedBonuses}
        maxBonusesToUse={maxBonusesToUse}
        maxBonusesToUsePrice={price(maxBonusesToUse)}
        onConfirmOrder={isCheckout ? onCheckout : onConfirm}
        limitationText={cart.length > 0 ? disabledText : ''}
        isDisabled={isDisabled}
        certificates={certificates}
        selectedOnlinePayment={selectedOnlinePayment}
        isCheckout={isCheckout}
      />
      <SellersInfo count={sellers.length} />
    </>
  );
};

TotalContainer.propTypes = {
  isCheckout: PropTypes.bool,
};

TotalContainer.defaultProps = {
  isCheckout: false,
};

export default TotalContainer;
