import actionTypes from '../constants/action-types';

const defaultState = {};
const amount = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_AMOUNT:
      return payload;
    case actionTypes.CLEAR_AMOUNT:
      return defaultState;
    default:
      return state;
  }
};

export default amount;
