import serviceClientAPI from '../../../core/service-client';
import structureToCamel from '../../../core/helpers/structure-to-camel';

const addProduct = (userId, body) => {
  const client = serviceClientAPI.get();
  return client.cart
    .add(userId, body)
    .then((data) => data.json())
    .then(({ data }) => structureToCamel(data));
};

export default addProduct;
