import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchCart from '../../cart/operations/fetch-cart';
import discounts from '../../discounts';
import configurationSelectors from '../../../core/configuration/selectors';
import userSelectors from '../../../core/user/selectors';
import userVerifiers from '../../../core/user/verifiers';
import checkout from '../../checkout';
import cartActions from '../../cart/actions';
import DiscountCard from '../../../core/errors/discount-card';
import location from '../../location';
import analytic from '../../analytic';
import experiments from '../../experiments';
// import configuration from '../../../core/configuration';
import favorites from '../../favorites';
import setAppStatusBusy from '../../common/actions/set-app-status-busy';
import setAppStatusIdle from '../../common/actions/set-app-status-idle';

const Loader = () => {
  const dispatch = useDispatch();
  // const emptyCartRedirectUrl = useSelector(configuration.selectors.emptyCartRedirect);
  const { serviceReady } = useSelector(configurationSelectors.statuses);
  const { bxSmSaleId } = useSelector(userSelectors.getUser);
  useEffect(() => {
    experiments.resolveExperiments();
    if (serviceReady && bxSmSaleId) {
      dispatch(setAppStatusBusy());
      fetchCart(bxSmSaleId)
        .then((data) => {
          dispatch(setAppStatusIdle());
          // if (!data.length) {
          //   window.location.href = emptyCartRedirectUrl;

          //   return;
          // }
          const analyticData = analytic.handlers.cart(data);
          const analyticDataMyTarget = analytic.handlers.myTargetCart(data);
          const analyticDateCriteo = analytic.handlers.criteoCart(data);

          analytic.dataLayer.push(analyticData);
          analytic.myTarget.trackCart(...analyticDataMyTarget);
          analytic.criteo.trackCart(analyticDateCriteo);

          dispatch(cartActions.setCart(data));

          favorites.operations.fetchFavorites().then(({ data: favoritesData }) => {
            const favoritesAction = favorites.actions.setFavorites(favoritesData);
            dispatch(favoritesAction);
          });

          if (!userVerifiers.isUserAuthentificated()) return;
          location.operations
            .fetchCountries()
            .then((coutries) => dispatch(location.actions.setCoutries(coutries)));
          discounts.operations
            .getDiscountCards()
            .then((cards) => {
              if (!Array.isArray(cards)) throw new DiscountCard();

              dispatch(discounts.actions.setCards(cards));
            })
            .catch(() => {
              dispatch(discounts.actions.clearCards());
            });
          checkout.operations
            .getSavedLocations()
            .then((locations) => dispatch(checkout.actions.setSavedLocations(locations)));
        })
        .catch(() => {
          dispatch(setAppStatusIdle());
        });
    }
  }, [serviceReady, bxSmSaleId]);
  return null;
};

export default Loader;
