import actionTypes from '../constants/action-types';

const configServiceClient = (config) => ({
  type: actionTypes.SET_SERVICE_CONFIGURATION,
  payload: {
    ...config,
  },
});

export default configServiceClient;
