import actionTypes from '../constants/action-types';

const promocode = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CLEAR_PROMOCODE:
      return {};
    case actionTypes.SET_PROMOCODE:
      return payload;
    default:
      return state;
  }
};

export default promocode;
