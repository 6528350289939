import constants from '../../delivery/constants';

const validateDelivery = (delivery, deliveryPayload) => {
  const errors = {};
  if (!delivery) {
    errors.common = 'Выберите службу доставки';
    return errors;
  }
  const { id, options } = delivery;
  const deliveryKeys = deliveryPayload ? Object.keys(deliveryPayload) : [];

  if (!id) errors.common = 'Выберите службу доставки';

  switch (id) {
    // case constants.PICK_POINT_DELIVERY_ID: {
    //   if (!deliveryKeys.length) {
    //     errors[id] = 'Выберите постамат';
    //   }
    //   break;
    // }
    case constants.SELF_DELIVERY_ID_PREFIX: {
      const { deliveryDate, variationId } = deliveryPayload;
      if (!deliveryDate || !variationId) {
        errors[id] = 'Заполните параметры доставки';
      }
      break;
    }
    default: {
      if (!options) {
        break;
      }

      if (options.deliverySlots && !deliveryKeys.length)
        errors[id] = 'Заполните параметры доставки';
    }
  }

  const keys = Object.keys(errors);
  return keys.length ? errors : null;
};

export default validateDelivery;
