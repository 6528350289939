const menu = [
  {
    label: 'Женское',
    subMenu: [
      {
        label: 'Одежда',
        url: '/catalog/woman/odezhda/',
      },
      {
        label: 'Обувь',
        url: '/catalog/woman/obuv/',
      },
      {
        label: 'Сумки',
        url: '/catalog/woman/sumki/',
      },
      {
        label: 'Аксессуары',
        url: '/catalog/woman/aksessuary/',
      },
      {
        label: 'Аксессуары из кожи',
        url: '/catalog/woman/aksessuary_iz_kozhi_1/',
      },
      {
        label: 'Парфюмерия и косметика',
        url: '/catalog/woman/kosmetika/',
      },
      {
        label: 'Спортивные товары',
        url: '/catalog/woman/sport-zhenshchiny/',
      },
      {
        label: 'Свадьба',
        url: '/catalog/woman/svadba/',
      },
      {
        label: 'Часы',
        url: '/catalog/woman/chasy_10/',
      },
      {
        label: 'Ювелирные изделия',
        url: '/catalog/woman/ukrasheniya_5/',
      },
      {
        label: 'Велосипеды',
        url: '/catalog/woman/velosipedy_2/',
      },
    ],
  },
  {
    label: 'Мужское',
    subMenu: [
      {
        label: 'Одежда',
        url: '/catalog/men/odezhda/',
      },
      {
        label: 'Обувь',
        url: '/catalog/men/obuv/',
      },
      {
        label: 'Сумки',
        url: '/catalog/men/sumki/',
      },
      {
        label: 'Аксессуары',
        url: '/catalog/men/aksessuary/',
      },
      {
        label: 'Аксессуары из кожи',
        url: '/catalog/men/aksessuary_iz_kozhi/',
      },
      {
        label: 'Парфюмерия и косметика',
        url: '/catalog/men/kosmetika/',
      },
      {
        label: 'Спортивные товары',
        url: '/catalog/men/sport-muzhchiny/',
      },
      {
        label: 'Часы',
        url: '/catalog/men/chasy_8/',
      },
      {
        label: 'Ювелирные изделия',
        url: '/catalog/men/ukrasheniya_4/',
      },
      {
        label: 'Велосипеды',
        url: '/catalog/men/velosipedy/',
      },
    ],
  },
  {
    label: 'Детское',
    subMenu: [
      {
        label: 'Для мальчиков',
        url: '/catalog/detskoe/malchiki/',
      },
      {
        label: 'Для девочек',
        url: '/catalog/detskoe/devochki/',
      },
      {
        label: 'Новорожденные',
        url: '/catalog/detskoe/grudnichki/',
      },
      {
        label: 'Велосипеды',
        url: '/catalog/detskoe/velosipedy_1/',
      },
      {
        label: 'Игрушки',
        url: '/catalog/detskoe/igrushki_1/',
      },
    ],
  },
  {
    label: 'Персональный стилист',
    url: '/anketa/',
  },
  {
    label: 'Способы оплаты',
    url: '/payment.html',
  },
  {
    label: 'Доставка',
    url: '/delivery.html',
  },
  {
    label: 'Возврат товара',
    url: '/vozvrat.html',
  },
];

export default menu;
