import React from 'react';
import PropTypes from 'prop-types';
import { Error } from '@internet-boutique-engineering/site-ui';
import { createUseStyles } from 'react-jss';
import DeliveryRow from './delivery-row';
import SelfDeliveryRow from './self-delivery-row';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Delivery = ({
  deliveries,
  onDelivery,
  onDeliveryDetails,
  locationId,
  locationName,
  deliveryId,
  deliveryCost,
  deliveryDetails,
  deliveryError,
  // minFreeDeliveryPrice,
  // isFreeDeliveryAvailable,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.deliveryGroup} delivery="">
      <div className={classes.deliveryWrapper}>
        <div className={classes.label}>Доставка</div>
        <div className={classes.rowGroup}>
          {deliveries.map((item) => (
            (item.id.toString().toLowerCase().indexOf('pickpoint') < 0)
              && <React.Fragment key={`${item.id}_${locationId}`}>
                 {item.isSelf ? (
                   <SelfDeliveryRow
                     key={`${item.id}_${locationId}`}
                     item={item}
                     onDelivery={onDelivery}
                     onDeliveryDetails={onDeliveryDetails}
                     locationId={onDeliveryDetails}
                     locationName={locationName}
                     deliveryId={deliveryId}
                     deliveryCost={deliveryCost}
                     deliveryDetails={deliveryDetails}
                     deliveryError={deliveryError}
                   />
                 ) : (
                   <DeliveryRow
                     key={`${item.id}_${locationId}`}
                     item={item}
                     onDelivery={onDelivery}
                     onDeliveryDetails={onDeliveryDetails}
                     locationId={onDeliveryDetails}
                     locationName={locationName}
                     deliveryId={deliveryId}
                     deliveryCost={deliveryCost}
                     deliveryDetails={deliveryDetails}
                     deliveryError={deliveryError}
                   />
                 )}
               </React.Fragment>

          ))}
        </div>
      </div>
      {deliveryError && deliveryError.common && (
        <div className={classes.errorWrapper}>
          <Error>{deliveryError.common}</Error>
        </div>
      )}
    </div>
  );
};

const slots = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
});

const delivery = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  extraConditions: PropTypes.string,
  cost: PropTypes.number.isRequired,
  options: PropTypes.shape({
    callBefore: PropTypes.bool,
    deliverySlots: PropTypes.arrayOf(slots),
  }),
  link: PropTypes.string,
});

Delivery.propTypes = {
  deliveryId: PropTypes.string,
  deliveryCost: PropTypes.number,
  deliveryDetails: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
    deliverySlots: PropTypes.array,
    callBefore: PropTypes.bool,
  }),
  deliveries: PropTypes.arrayOf(delivery),
  onDelivery: PropTypes.func,
  onDeliveryDetails: PropTypes.func,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationName: PropTypes.string,
  deliveryError: PropTypes.string,
  minFreeDeliveryPrice: PropTypes.number,
  isFreeDeliveryAvailable: PropTypes.bool,
};
Delivery.defaultValues = {
  deliveries: [],
  onDelivery: Function.prototype,
  onDeliveryDetails: Function.prototype,
  locationName: null,
  deliveryId: null,
  deliveryCost: null,
  deliveryDetails: null,
  deliveryError: null,
  minFreeDeliveryPrice: 0,
  isFreeDeliveryAvailable: false,
};

export default Delivery;
