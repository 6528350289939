import { BoscoNewModalConfirm } from '@internet-boutique-engineering/site-ui';
import Confirm from '../core/components/confirm';
import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';

const getModalConfirm = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.BOSCO_NEW_SITE_NAME:
      return BoscoNewModalConfirm;
    default:
      break;
  }

  return Confirm;
};

export default getModalConfirm;
