import mixpanelCore from '../../../core/mixpanel';

mixpanelCore.init(process.env.MIXPANEL_TOKEN);
const mixpanel = mixpanelCore.get();

export { default as eventTypes } from './constants/event-types';

export default {
  identify: (userId, traits) => {
    try {
      if (userId) {
        mixpanel.people.set(traits);
        mixpanel.identify(userId);
      } else {
        mixpanel.identify();
      }
    } catch (error) {
      console.info('identify call', userId, traits);
    }
  },
  track: (eventName, properties, options = {}) =>
    new Promise((resolve) => {
      const timeout = setTimeout(resolve, 2000);
      try {
        mixpanel.track(eventName, properties, options, (data) => {
          clearTimeout(timeout);
          resolve(data);
        });
      } catch (error) {
        clearTimeout(timeout);
        resolve(null);
        console.info('track call', eventName, properties);
      }
    }),
};
