import React, { useCallback } from 'react';
import {
  InputField,
  TwoStateControl,
  PhoneNumber,
  Error,
} from '@internet-boutique-engineering/site-ui';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles';
import getMainButton from '../../../../themes/get-main-button';

const useStyles = createUseStyles(styles);

const UserInfo = ({
  user: { email, name, lastname, phone },
  onEmailChanged,
  onPhoneChanged,
  onNameChanged,
  onLastnameChanged,
  onSubmit,
  isChecked,
  onCheckboxUpdated,
  errors,
}) => {
  const classes = useStyles();
  const emailRow = classNames(classes.rowCenter, classes.emailRow);
  const phoneRow = classNames(classes.rowCenter, classes.phoneRow);
  const checkboxRow = classNames(classes.row, classes.checkboxRow);

  const onEmailChangedCallback = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onEmailChanged(value);
  });
  const onPhoneChangedCallback = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onPhoneChanged(value);
  });
  const onNameChangedCallback = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onNameChanged(value);
  });
  const onLastnameChangedCallback = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onLastnameChanged(value);
  });

  const emailError = errors && errors.email;
  const nameError = errors && errors.name;
  const lastnameError = errors && errors.lastname;
  const phoneError = errors && errors.phone;
  const conditionsError = errors && errors.conditionsError;

  const MainButton = getMainButton();
  return (
    <React.Fragment>
      <div className={emailRow}>
        <div className={classes.subRow}>
          <label className={classes.label}>E-mail</label>
          <div className={classes.control}>
            <InputField
              hasError={emailError}
              defaultValue={email}
              onBlur={onEmailChangedCallback}
            />
            <div className={classes.error}>
              {emailError && <Error>{emailError}</Error>}
            </div>
          </div>
        </div>
        <div className={classes.subRow}>
          <label className={classes.label}>&nbsp;</label>
          <MainButton onClick={onSubmit} size="small">
            OK
          </MainButton>
        </div>
      </div>
      <div className={classes.separator}></div>
      <div className={classes.row}>
        <div className={classes.subRow}>
          <label className={classes.label}>Фамилия</label>
          <div className={classes.control}>
            <InputField
              hasError={lastnameError}
              defaultValue={lastname}
              onBlur={onLastnameChangedCallback}
            />
            <div className={classes.error}>
              {lastnameError && <Error>{lastnameError}</Error>}
            </div>
          </div>
        </div>
        <div className={classes.subRow}>
          <label className={classes.label}>Имя</label>
          <div className={classes.control}>
            <InputField
              hasError={nameError}
              defaultValue={name}
              onBlur={onNameChangedCallback}
            />
            <div className={classes.error}>{nameError && <Error>{nameError}</Error>}</div>
          </div>
        </div>
      </div>
      <div className={phoneRow}>
        <div className={classes.subRow}>
          <label className={classes.label}>Телефон</label>
          <div className={classes.control}>
            <PhoneNumber
              country="ru"
              hasError={phoneError}
              value={phone}
              onBlur={onPhoneChangedCallback}
            />
            <div className={classes.error}>
              {phoneError && <Error>{phoneError}</Error>}
            </div>
          </div>
        </div>
        <div className={classes.subRow}>
          <label className={classes.label}>&nbsp;</label>
          <MainButton onClick={onSubmit} inverted size="large">
            Оформить покупку
          </MainButton>
        </div>
      </div>
      <div className={checkboxRow}>
        <TwoStateControl
          type="checkbox"
          checked={isChecked}
          onChange={onCheckboxUpdated}
          name="subscribe"
          value={true}
          className={classes.userAgreement}
          hasError={conditionsError}
        >
          Нажимая на кнопку, Вы даете согласие на обработку своих персональных данных в
          соответствии с{' '}
          <a href="https://boscooutlet.ru/privacy.html" rel="noreferrer" target="_blank">
            условиями соглашения
          </a>
        </TwoStateControl>
        <div className={classes.error}>
          {conditionsError && <Error>{conditionsError}</Error>}
        </div>
      </div>
    </React.Fragment>
  );
};

UserInfo.propTypes = {
  user: PropTypes.objectOf({
    email: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  onNameChanged: PropTypes.func,
  onLastnameChanged: PropTypes.func,
  onEmailChanged: PropTypes.func,
  onPhoneChanged: PropTypes.func,
  onSubmit: PropTypes.func,
  onCheckboxUpdated: PropTypes.func,
  isChecked: PropTypes.bool,
  errors: PropTypes.objectOf({
    email: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    phone: PropTypes.string,
    conditions: PropTypes.string,
  }),
};

UserInfo.defaulProps = {
  user: {},
  onNameChanged: Function.prototype,
  onLastnameChanged: Function.prototype,
  onEmailChanged: Function.prototype,
  onPhoneChanged: Function.prototype,
  onSubmit: Function.prototype,
  onCheckboxUpdated: Function.prototype,
  isChecked: false,
  errors: null,
};

export default UserInfo;
