import connectedStore from '../store/connected-store';
import configuration from '../core/configuration';
import constants from '../core/constants';
import CommonBuyCartBlock from '../core/components/buy-cart-block/common';
import ArticoliBuyCartBlock from '../core/components/buy-cart-block/articoli';

const getBuyCartBlock = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const siteId = configuration.selectors.getSiteId(state);

  switch (siteId) {
    case constants.ARTICOLI_SITE_NAME:
      return ArticoliBuyCartBlock;
    default:
      break;
  }
  return CommonBuyCartBlock;
};

export default getBuyCartBlock;
