import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
// import { Icon } from '@internet-boutique-engineering/site-ui';
import styles from './styles';
// import liveChat from '../../modules/livechat';

const useStyles = createUseStyles(styles);
const Layout = ({ children }) => {
  const classes = useStyles();

  // const openChat = () => {
  //   const chat = liveChat.instance.get();
  //   chat.openChat();
  // };

  return (
    <section className={classes.layout}>
      {children}
      {/* <button onClick={openChat} className={classes.liveChat}>
        <Icon type="phone" className={classes.phoneIcon} />
        Задать вопрос
      </button> */}
    </section>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Layout;
