import camelcase from 'lodash.camelcase';

const structureToCamel = (obj) => {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.map((item) => structureToCamel(item));
  }
  if (typeof obj === 'object') {
    const keys = Object.keys(obj);
    return keys.reduce(
      (newObject, key) => ({
        ...newObject,
        [`${camelcase(key)}`]: structureToCamel(obj[key]),
      }),
      {}
    );
  }

  return obj;
};

export default structureToCamel;
