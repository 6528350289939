import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Button } from '@internet-boutique-engineering/site-ui';
import user from '../../../../core/user';
import favoritesActions from '../../../favorites/actions';
import errorAction from '../../../../core/errors/actions';
import favoritesOperations from '../../../favorites/operations';
import CustomError from '../../../../core/errors/custom-error';
import modals from '../../../../core/modals';
import compilePice from '../../../../core/helpers/price';
import cartSelectors from '../../selectors';
import cartOperations from '../../operations';
import cartActions from '../../actions';
import groupBeSellers from '../../helpers/group-by-sellers';
import segment, { eventTypes } from '../../../analytic/segment';
import analytic from '../../../analytic';
import getProductCard from '../../../../themes/get-product-card';
import getProductCardHeader from '../../../../themes/get-product-card-headers';
import getProductCardGroup from '../../../../themes/get-product-card-group';
import configuration from '../../../../core/configuration';

import styles from './styles';

const useStyles = createUseStyles(styles);

const ItemsCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector(cartSelectors.getCart);

  const { bxSmSaleId } = useSelector(user.selectors.getUser);

  const { isAuthentificated } = user.hooks.useUser();

  const createError = (message) => {
    throw new CustomError(message, {
      label: 'Закрыть',
      handler: () => {
        dispatch(errorAction.clearError());
      },
    });
  };

  const fetchAddProduct = (id, sizeId) => {
    cartOperations
      .addProduct(bxSmSaleId, { id: sizeId, quantity: 1 })
      .then((data) => {
        dispatch(cartActions.setCart(data));
      })
      .catch(() => {
        dispatch(cartActions.toggleSizeProduct(id, sizeId));
        createError('Уппс! Ошибка при добавлении товара.');
      });
  };

  const fetchRemoveProduct = (id) => {
    cartOperations
      .removeProduct(bxSmSaleId, id)
      .then((data) => {
        dispatch(cartActions.setCart(data));
      })
      .catch(() => {
        dispatch(cartActions.toggleRemoveProduct(id));
        createError('Уппс! Ошибка при удалении товара.');
      });
  };

  const fetchAddFavorite = (id, parentId) => {
    favoritesOperations
      .addFavorites({ item_id: parentId })
      .then(({ data }) => {
        dispatch(favoritesActions.setFavorites(data));
        fetchRemoveProduct(id);
      })
      .catch(() => {
        dispatch(cartActions.toggleRemoveProduct(id));
        createError('Уппс! Ошибка при добавлении товара в избраное.');
      });
  };

  const openModalFavorites = (id, parentId) => {
    modals.operations.showModal(modals.constants.modalTypes.CONFIRM, {
      message:
        'Внимание! Данный товар единственный, который остался у нас на складе. Рекомендуем оформить покупку прямо сейчас!',
      actionSuccess: {
        label: 'В корзину',
        handler: () => {
          modals.operations.hideModal();
        },
      },
      actionCancel: {
        label: 'В избранное',
        handler: () => {
          fetchAddFavorite(id, parentId);
          dispatch(cartActions.toggleRemoveProduct(id));
          modals.operations.hideModal();
        },
      },
    });
  };

  const getProduct = (id) => cart.find((it) => it.id === id);
  const getSize = (id, size) => getProduct(id).sizes.find((it) => it.label === size);

  const onFavorite = useCallback(
    (id) => {
      const product = getProduct(id);

      if (product.isLastItem) {
        openModalFavorites(id, product.parentId);
      } else {
        fetchAddFavorite(id, product.parentId);
        dispatch(cartActions.toggleRemoveProduct(id));
        segment.track(eventTypes.ADD_TO_FAVORITE_FROM_CART_INITIATED, { id });

        analytic.dataLayer.push(analytic.handlers.remove(product));
      }
    },
    [cart]
  );

  const onDelete = useCallback((id) => {
    fetchRemoveProduct(id);
    segment.track(eventTypes.DELETE_ITEM_FROM_CART_INITIATED, { id });

    const product = getProduct(id);
    if (product) {
      analytic.dataLayer.push(analytic.handlers.remove(product));
    }
  });

  const onDecrease = useCallback(async (id) => {
    const data = await cartOperations.decreaseQuantity(bxSmSaleId, id);

    const { id: itemId, ...restData } = data;
    dispatch(cartActions.updateItem(id, restData));
    segment.track(eventTypes.DECREASE_QUANTITY_FROM_CART_INITIATED, { id, restData });

    const product = getProduct(id);
    if (product) {
      analytic.dataLayer.push(analytic.handlers.remove(product));
    }
  });

  const onIncrease = useCallback(async (id) => {
    const data = await cartOperations.increaseQuantity(bxSmSaleId, id);

    const { id: itemId, ...restData } = data;
    dispatch(cartActions.updateItem(id, restData));
    segment.track(eventTypes.INCREASE_QUANTITY_FROM_CART_INITIATED, { id, restData });

    const product = getProduct(id);
    if (product) {
      analytic.dataLayer.push(analytic.handlers.add(product));
    }
  });

  const onSizeChange = useCallback(
    (id, size) => {
      const { offerId, selected } = getSize(id, size);

      if (!selected) {
        dispatch(cartActions.toggleSizeProduct(id, offerId));
        fetchAddProduct(id, offerId);
        segment.track(eventTypes.SET_SIZE_FOR_ITEM_FROM_CART_INITIATED, { id, offerId });

        const product = getProduct(id);
        if (product) {
          analytic.dataLayer.push(analytic.handlers.add(product));
        }
      }
    },
    [cart]
  );

  const ProductCard = getProductCard();
  const compileItem = ({
    id,
    code,
    title,
    quantity,
    canChangeQuantity,
    brand,
    price,
    discount,
    photo,
    detailLink,
    brandLink,
    size,
    sizes,
    seller,
    isGiftCard,
  }) => {
    const lines = [];
    const isShowSeparately = configuration.accessors.getCartItemsShowConfig();
    if (isShowSeparately && !isGiftCard) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < quantity; i++) {
        lines.push(
          <li key={`${id}:${i}`} className={classes.item}>
            <ProductCard
              id={id}
              productCode={code}
              title={isGiftCard ? title : `${title} - ${seller}`}
              brandName={brand}
              price={compilePice(price + (discount || 0))}
              discountedPrice={discount ? compilePice(price) : null}
              totalPrice={compilePice(price + (discount || 0))}
              totalDiscountedPrice={discount ? compilePice(price) : null}
              imageUrl={photo}
              linkTitle={detailLink}
              linkBrand={brandLink}
              currentSize={size}
              itemsSize={sizes}
              onDelete={onDelete}
              onDecrease={quantity === 1 ? onDelete : onDecrease}
              onFavorite={onFavorite}
              onSizeChange={onSizeChange}
              allowFavorites={isAuthentificated}
              seller={seller}
              isGiftCard={isGiftCard}
            />
          </li>
        );
      }
    } else {
      lines.push(
        <li key={`${id}`} className={classes.item}>
          <ProductCard
            id={id}
            productCode={code}
            title={isGiftCard ? title : `${title} - ${seller}`}
            brandName={brand}
            price={compilePice(price + (discount || 0))}
            discountedPrice={discount ? compilePice(price) : null}
            totalPrice={compilePice(price * quantity + (discount * quantity || 0))}
            totalDiscountedPrice={discount ? compilePice(price * quantity) : null}
            imageUrl={photo}
            linkTitle={detailLink}
            linkBrand={brandLink}
            currentSize={size}
            itemsSize={sizes}
            quantity={quantity}
            canChangeQuantity={canChangeQuantity || isGiftCard}
            onIncrease={onIncrease}
            onDecrease={quantity === 1 ? onDelete : onDecrease}
            onDelete={onDelete}
            onFavorite={onFavorite}
            onSizeChange={onSizeChange}
            allowFavorites={isAuthentificated}
            seller={seller}
            isGiftCard={isGiftCard}
          />
        </li>
      );
    }

    return lines;
  };

  const groupedCart = groupBeSellers(cart);
  const shouldSellerNameBeShown = groupedCart.length > 1;

  const ProductCardHeader = getProductCardHeader();
  const ItemsGroup = getProductCardGroup();

  return (
    <>
      {cart.length > 0 ? (
        <div>
          {groupedCart.map((group) => (
            <ItemsGroup
              key={group.seller}
              title={group.seller}
              shouldSellerNameBeShown={shouldSellerNameBeShown}
            >
              <ProductCardHeader className={classes.listHeader} />
              <ul className={classes.list}>
                {group.items.map((item) => compileItem(item))}
              </ul>
            </ItemsGroup>
          ))}
        </div>
      ) : (
        <div className={classes.emptyCartBlock}>
          <div className={classes.emptyCartText}>Ваша корзина пуста</div>{' '}
          <Button href={'/'} customWidth={'30%'} className={classes.button}>
            К покупкам
          </Button>
        </div>
      )}
    </>
  );
};

export default ItemsCard;
